import {CSpinner} from "@coreui/react";
import React, {useEffect, useState} from "react";
import firebase from "../../service/firebase";
import BulkEdit from "./BulkEdit";

const BulkEditWrapper = ({profiles, onClose}) => {
  const [fetchingSubscriptionsPermissions, setFetchingSubscriptionsPermissions] = useState(true);
  const [subscriptionsPermissions, setSubscriptionsPermissions] = useState({});

  const fetchSubscriptionsPermissions = async () => {
    const {success, data: permissions} = await firebase.getAllSubscriptionsPermissions();
    if (success) {
      setSubscriptionsPermissions(permissions);
      setFetchingSubscriptionsPermissions(false);
    }
  };

  useEffect(() => {
    (async () => await fetchSubscriptionsPermissions())();
  }, []);

  if (fetchingSubscriptionsPermissions)
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <CSpinner color="primary" />
      </div>
    );

  return (
    <BulkEdit
      profiles={profiles}
      onClose={onClose}
      subscriptionsPermissions={subscriptionsPermissions}
    />
  );
};

export default BulkEditWrapper;
