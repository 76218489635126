import {CToast, CToastBody, CToastClose, CToaster} from "@coreui/react";
import React, {useCallback, useEffect, useState} from "react";
import {matchPath, Route, Switch, useLocation} from "react-router-dom";
import {useToasterContext} from "../../context/ToasterContext/ToasterContext";
import useAppRoutes from "../../hooks/useAppRoutes";
import NavBar from "./NavBar/NavBar";
import TopBar from "./TopBar";

const STYLE = {
  root: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
  loader: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
};

const Admin = () => {
  const location = useLocation();

  const {toasts, removeToast} = useToasterContext();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState("");

  // TODO: We'd be better off using context or redux. Right now it's kind of prop drilling.
  const onMobileClose = useCallback(() => setMobileNavOpen(false), []);
  const onMobileNavOpen = useCallback(() => setMobileNavOpen(true), []);

  const {routes: adminRoutes} = useAppRoutes();

  useEffect(() => {
    const title =
      adminRoutes.find((route) => matchPath(location.pathname, {path: route.path}))?.title || "";
    setPageTitle(title);
  }, []);

  const topBar = (
    <TopBar title={pageTitle} className="sticky-top" onMobileNavOpen={onMobileNavOpen} />
  );

  const switchElement = (
    <Switch>
      {adminRoutes.map(
        ({component, path}, idx) =>
          component && <Route exact key={idx} path={path} component={component} />
      )}
    </Switch>
  );

  const content = (
    <div style={STYLE.content}>
      {topBar}
      {switchElement}
    </div>
  );

  const navBarEl = (
    <NavBar
      onMobileClose={onMobileClose}
      openMobile={isMobileNavOpen}
      setPageTitle={setPageTitle}
    />
  );

  const contentEl = (
    <div style={STYLE.wrapper}>
      <div style={{marginLeft: 256}} className="d-none d-md-block" />
      <div style={STYLE.contentContainer}>{content}</div>
    </div>
  );

  const toasterEl = (
    <CToaster placement="bottom-center">
      {toasts.map(({id, message, color = "primary"}) => {
        const dismiss = () => removeToast(id);

        return (
          <React.Fragment key={id}>
            <CToast visible onClose={dismiss} color={color}>
              <div className="d-flex text-white">
                <CToastBody>{message}</CToastBody>
                <CToastClose className="me-2 m-auto" title="Dismiss" white />
              </div>
            </CToast>
          </React.Fragment>
        );
      })}
    </CToaster>
  );

  return (
    <div style={STYLE.root}>
      {navBarEl}
      {contentEl}
      {toasterEl}
    </div>
  );
};
export default Admin;
