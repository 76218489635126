import {cilArrowLeft, cilPeople} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {CButton, CSpinner} from "@coreui/react";
import React, {useState} from "react";
import CenteredSpinner from "../../../components/CenteredSpinner/CenteredSpinner";
import Page from "../../../components/Page/Page";
import ButtonTable from "../../../components/Table/ButtonTable";
import LinkTable from "../../../components/Table/LinkTable";
import useProfiles from "../../../hooks/useProfiles";
import firebase from "../../../service/firebase";

const Managers = ({onMobileNavOpen}) => {
  const {profiles, fetching} = useProfiles();

  const [viewingManagerUsers, setViewingManagerUsers] = useState(false);
  const [manager, setManager] = useState({});
  const [managerUsers, setManagerUsers] = useState([]);
  const [managerUsersLoading, setManagerUsersLoading] = useState(false);

  if (!profiles || fetching) return <CenteredSpinner />;

  const managers = profiles.filter(({isManager}) => isManager);

  const headCells = [
    {id: "email", label: "Email"},
    {id: "fullName", label: "Full Name"},
    {id: "lastAccess", label: "Last Access"},
    {id: "signUpDate", label: "Register Date"},
    {id: "assignsList", label: "Nr. of Assignments"},
    {id: "industryType", label: "Industry Type"},
    {id: "id", label: "UID"},
  ];

  const dataCells = [
    {dataKey: "email", noPadding: true},
    {dataKey: "fullName"},
    {dataKey: "lastAccess", type: "date"},
    {dataKey: "signUpDate", type: "date"},
    {dataKey: "assignsList", type: "amount"},
    {dataKey: "industryType"},
    {dataKey: "id"},
  ];

  const makePath = (row) => (row.id ? `/admin/profiles/${row.id}` : "#");

  const showManagerUsers = async (manager) => {
    setManagerUsersLoading(true);
    const res = await firebase.getManagerUsers(manager.id);
    const users = res.data;
    setManagerUsers(users);
    setManager(manager);
    setManagerUsersLoading(false);
    setViewingManagerUsers(true);
  };

  const returnToManagers = () => {
    setViewingManagerUsers(false);
    setManager({});
    setManagerUsers([]);
  };

  const spinner = (
    <div className="d-flex justify-content-center align-items-center h-100">
      <CSpinner color="primary" />
    </div>
  );

  return (
    <>
      {managerUsersLoading ? (
        spinner
      ) : (
        <Page title="Managers" onMobileNavOpen={onMobileNavOpen}>
          {viewingManagerUsers ? (
            <div>
              <div className="d-flex align-items-center mb-2 text-break">
                <CButton color="primary" onClick={returnToManagers}>
                  <CIcon icon={cilArrowLeft} />
                </CButton>
                <div className="mx-2" />
                <div>
                  <h4>
                    <a
                      className="text-reset text-decoration-none text-break"
                      href={`/admin/profiles/${manager.id}`}
                    >
                      Manager: {manager.fullName}
                    </a>
                  </h4>
                  <div>{manager.email}</div>
                  <div>UID: {manager.id}</div>
                </div>
              </div>
              <LinkTable
                linkLabel={"Visit Profile"}
                data={managerUsers}
                headCells={headCells}
                dataCells={dataCells}
                makePath={makePath}
              />
            </div>
          ) : (
            <div>
              <ButtonTable
                linkLabel="View Users"
                data={managers}
                headCells={headCells}
                dataCells={dataCells}
                rowOnClick={showManagerUsers}
                buttonIcon={cilPeople}
              />
            </div>
          )}
        </Page>
      )}
    </>
  );
};

export default Managers;
