import React from "react";
import {
  CButton,
  CButtonGroup,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import PropTypes from "prop-types";
import CIcon from "@coreui/icons-react";
import {cilChevronLeft, cilChevronRight} from "@coreui/icons";

function TablePaginationBar(props) {
  const {
    className,
    count,
    page,
    rowsPerPage,
    rowsPerPageLabel,
    rowsPerPageOptions,
    setPage,
    setRowsPerPage,
  } = props;

  const firstRow = Math.max(0, page * rowsPerPage + 1);
  const lastRow = Math.min(count, firstRow + rowsPerPage - 1);
  const numPages = Math.ceil(count / rowsPerPage);

  const canNavToPreviousPage = page > 0;
  const canNavToNextPage = page < numPages - 1;

  const navToPreviousPage = () => {
    if (canNavToPreviousPage) setPage(page - 1);
  };

  const navToNextPage = () => {
    if (canNavToNextPage) setPage(page + 1);
  };

  return (
    <div className={className}>
      <div className="d-flex">
        <div className="flex-grow-1" />
        <div className="d-inline-flex align-items-end">
          <div className="me-4">
            <span>{rowsPerPageLabel} </span>
            <CDropdown>
              <CDropdownToggle variant="ghost" size="sm">
                {rowsPerPage}
              </CDropdownToggle>
              <CDropdownMenu>
                {rowsPerPageOptions.map((option, idx) => (
                  <CDropdownItem
                    key={idx}
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      setPage(0);
                      setRowsPerPage(option);
                    }}
                  >
                    {option}
                  </CDropdownItem>
                ))}
              </CDropdownMenu>
            </CDropdown>
          </div>
          <div className="d-flex justify-content-end">
            <span className="me-4">
              {firstRow}-{lastRow} of {count}
            </span>
            <CButtonGroup size="sm">
              <CButton variant="ghost" disabled={!canNavToPreviousPage} onClick={navToPreviousPage}>
                <CIcon icon={cilChevronLeft} />
              </CButton>
              <CButton variant="ghost" disabled={!canNavToNextPage} onClick={navToNextPage}>
                <CIcon icon={cilChevronRight} />
              </CButton>
            </CButtonGroup>
          </div>
        </div>
      </div>
    </div>
  );
}

TablePaginationBar.propTypes = {
  rowsPerPageOptions: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func,
  setRowsPerPage: PropTypes.func,
  className: PropTypes.string,
  rowsPerPageLabel: PropTypes.string,
};

TablePaginationBar.defaultProps = {
  rowsPerPageLabel: "Rows per page:",
};

export default TablePaginationBar;

