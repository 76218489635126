/**
 * Returns a class name if the condition is true, otherwise returns an empty string.
 * @param condition {boolean} The condition to check.
 * @param className {string} The class name to return if the condition is true.
 * @returns {string} The class name if the condition is true, otherwise an empty string.
 */
export const conditionalClass = (condition, className) => {
  return condition ? className : "";
};

const concatArrays = (...args) =>
  args.reduce((result, arg) => {
    if (Array.isArray(arg)) return result.concat(arg);

    result.push(arg);
    return result;
  }, []);

export const strJoin = (...args) => concatArrays(args).join(" ");
