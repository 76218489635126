import {createSlice} from "@reduxjs/toolkit";

export const needsAttentionSlice = createSlice({
  name: "needsAttention",
  initialState: {
    profiles: null,
    fetchingProfiles: false,
  },
  reducers: {
    setNeedsAttentionProfiles: (state, {payload: {profiles}}) => {
      state.profiles = profiles;
      state.fetchingProfiles = false;
    },
    startFetchingNeedsAttentionProfiles: (state) => {
      state.fetchingProfiles = true;
    }
  },
});

export const {setNeedsAttentionProfiles, startFetchingNeedsAttentionProfiles} = needsAttentionSlice.actions;

export default needsAttentionSlice.reducer;
