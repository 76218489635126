import {cilCheckCircle, cilXCircle} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {CButton, CContainer, CRow, CSpinner} from "@coreui/react";
import moment from "moment";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {PERMISSIONS_INFO} from "../../../constants/permissions";
import {BOUGHT_ON_OPTIONS, SUBSCRIPTION_OPTIONS} from "../../../constants/subscriptions";
import useProfiles from "../../../hooks/useProfiles";
import firebase from "../../../service/firebase";
import {withSeparator} from "../../../utils/jsx-utils";
import {
  buildBulkPermissionUpdates,
  buildBulkSubscriptionUpdates,
} from "../../../utils/subscription-utils";
import ProfilesReviewTable from "../../ProfilesReviewTable/ProfilesReviewTable";
import {
  FIELD_BOUGHT_ON,
  FIELD_EXPIRATION_DATE,
  FIELD_IS_ACTIVE,
  FIELD_LABELS,
  FIELD_PRODUCT_ID,
} from "../BulkEdit";
import BulkEditSection from "../BulkEditSection/BulkEditSection";
import classes from "./BulkEditReview.module.css";
import BulkEditReviewChange from "./BulkEditReviewChange/BulkEditReviewChange";

const getHumanReadableSubscriptionDetailsValue = (fieldId, value) => {
  if (!value) return "";

  switch (fieldId) {
    case FIELD_IS_ACTIVE.id:
      return value === "true" ? "Active" : "Inactive";
    case FIELD_PRODUCT_ID.id:
      return SUBSCRIPTION_OPTIONS.find((option) => option.value === value).label;
    case FIELD_BOUGHT_ON.id:
      return BOUGHT_ON_OPTIONS.find((option) => option.value === value).label;
    case FIELD_EXPIRATION_DATE.id:
      return moment.unix(value / 1e3).format("MM/DD/YYYY");
    default:
      return value;
  }
};

const getHumanReadablePermissionValue = (permissionId, value) => {
  if ([-1, true].includes(value)) return "On";
  const noun = PERMISSIONS_INFO[permissionId]?.nouns?.[value === 1 ? "singular" : "plural"] || "";
  if (typeof value === "number") return `Off - limited to ${value} ${noun}`;
  return "Off";
};

const BulkEditReview = ({
  profiles,
  subscriptionForm,
  permissionUpdates,
  toggleShowReviewPage,
  closeBulkEdit,
}) => {
  const {fetchProfiles} = useProfiles();
  const [isApplyingChanges, setIsApplyingChanges] = useState(false);
  const [changesSucceeded, setChangesSucceeded] = useState(null);
  const [isBackdropVisible, setIsBackdropVisible] = useState(false);
  const [updateMessage, setUpdateMessage] = useState("");

  const numProfiles = profiles.length;

  const profilesTable = (
    <div className="overflow-auto">
      <ProfilesReviewTable profiles={profiles} />
    </div>
  );

  const usersSection = (
    <BulkEditSection title={`Selected Users (${numProfiles})`}>{profilesTable}</BulkEditSection>
  );

  const subscriptionChanges = Object.entries(subscriptionForm).reduce(
    (acc, [fieldId, {active, value}]) => {
      if (!active) return acc;

      const label = FIELD_LABELS[fieldId];
      const displayedValue = getHumanReadableSubscriptionDetailsValue(fieldId, value);

      acc.push(
        <BulkEditReviewChange label={label} displayedValue={displayedValue} key={fieldId} />
      );

      return acc;
    },
    []
  );

  const permissionChanges = Object.entries(permissionUpdates).map(([permissionId, value]) => {
    const label = PERMISSIONS_INFO[permissionId]?.name || permissionId;
    const displayedValue = getHumanReadablePermissionValue(permissionId, value);

    return (
      <BulkEditReviewChange label={label} displayedValue={displayedValue} key={permissionId} />
    );
  }, []);

  const editChangesButton = (
    <CButton onClick={toggleShowReviewPage} variant="outline">
      Edit Changes
    </CButton>
  );

  const changesMadeTitle = (
    <div className="d-flex justify-content-between">Changes made {editChangesButton}</div>
  );

  const changesMadeSection = (
    <BulkEditSection title={changesMadeTitle}>
      <div className="d-flex flex-column gap-3">
        <div>
          <CRow className={`${classes.categoryBar} mb-2 mt-4 py-1 ps-2`}>SUBSCRIPTION DETAILS</CRow>
          <CContainer className="my-3">{withSeparator(subscriptionChanges)}</CContainer>
        </div>
        <div>
          <CRow className={`${classes.categoryBar} mb-2 mt-4 py-1 ps-2`}>PERMISSIONS *</CRow>
          <CContainer className="my-3">{withSeparator(permissionChanges)}</CContainer>
        </div>
      </div>
      <CRow className={classes.description}>
        * Permissions that have been changed that don't follow the default plan permissions.
      </CRow>
    </BulkEditSection>
  );

  const userIds = profiles.map(({id}) => id);

  const buildSubscriptionUpdates = () => buildBulkSubscriptionUpdates(userIds, subscriptionForm);

  const buildPermissionUpdates = () => buildBulkPermissionUpdates(userIds, permissionUpdates);

  const applyChanges = async () => {
    setIsApplyingChanges(true);
    setIsBackdropVisible(true);

    const subscriptionUpdates = buildSubscriptionUpdates();
    const permissionUpdates = buildPermissionUpdates();
    const updates = {...subscriptionUpdates, ...permissionUpdates};

    const {success, message} = await firebase.update(updates);

    setChangesSucceeded(success);

    const updateMessageEnding = `${numProfiles} profile${numProfiles > 1 ? "s" : ""}.`;

    if (success) {
      setUpdateMessage(`Successfully updated ${updateMessageEnding}`);
      void fetchProfiles();
    } else {
      setUpdateMessage(`Failed to update ${updateMessageEnding}`);
      console.error(message);
    }

    setIsApplyingChanges(false);
  };

  const formOnSubmit = async (event) => {
    event.preventDefault();
    await applyChanges();
  };

  const bottomButtons = (
    <form onSubmit={formOnSubmit} onReset={toggleShowReviewPage}>
      <div className="d-flex justify-content-end gap-3">
        <CButton type="reset" variant="outline">
          Cancel
        </CButton>
        <CButton type="submit">Confirm Changes</CButton>
      </div>
    </form>
  );

  const backdrop = isBackdropVisible
    ? (() => {
        const content = isApplyingChanges ? (
          <>
            <div className="d-flex justify-content-center align-items-center">
              <CSpinner height={36} />
            </div>
            <h5>Applying Changes...</h5>
          </>
        ) : (
          (() => {
            const icon = changesSucceeded ? cilCheckCircle : cilXCircle;
            const cIcon = <CIcon icon={icon} height={36} />;

            const closeButton = <CButton onClick={closeBulkEdit}>Close</CButton>;

            return (
              <>
                {cIcon}
                <h5>{updateMessage}</h5>
                <div className="d-flex justify-content-end w-100">{closeButton}</div>
              </>
            );
          })()
        );

        return (
          <div className={classes.blurredBackdrop}>
            <div className={classes.backdropContent}>{content}</div>
          </div>
        );
      })()
    : null;

  return (
    <div className="d-flex flex-column gap-3">
      {usersSection}
      {changesMadeSection}
      {bottomButtons}
      {backdrop}
    </div>
  );
};

BulkEditReview.propTypes = {
  profiles: PropTypes.array.isRequired,
  subscriptionForm: PropTypes.object.isRequired,
  permissionUpdates: PropTypes.object.isRequired,
  toggleShowReviewPage: PropTypes.func.isRequired,
  closeBulkEdit: PropTypes.func.isRequired,
};

export default BulkEditReview;
