import {cilCloudUpload} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {CCard, CCardBody, CContainer, CForm, CFormSwitch} from "@coreui/react";
import moment from "moment/moment";
import React from "react";
import noImgPlaceholder from "../../assets/no-img-placeholder.png";
import {ENV} from "../../env";
import {useUserContext} from "../../hooks/useUserContext";
import {strJoin} from "../../utils/css-utils";
import {DataInfo, PROFILE_STYLES} from "../../views/Admin/Profile/Profile";
import classes from "./ProfileAssignmentCard.module.css";

const ProfileAssignmentCard = ({
  assignmentId,
  assignmentName,
  contactDate,
  creationDate,
  cloudOnly,
  address,
  image,
  isOwner,
  toggleVisibility,
}) => {
  const {
    user: {id: profileId},
  } = useUserContext();

  const creationDateFormatted = moment.unix(creationDate / 1000).format("MM/DD/YYYY h:mm a");
  const sharedAssignmentUrl = `${ENV.WEB_APP_URL}/assignments/file-manager/?profileId=${profileId}&assignmentId=${assignmentId}`;

  const imageEl = (
    <div>
      <div
        className={classes.assignmentImageDiv}
        style={{backgroundImage: `url(${image || noImgPlaceholder})`}}
      />
    </div>
  );

  const [ownershipBadgeText, ownershipBadgeClass] = isOwner
    ? ["Owner", classes.ownerBadge]
    : ["Shared", classes.sharedBadge];

  const ownershipBadgeEl = (
    <div className={strJoin(classes.ownershipBadge, ownershipBadgeClass)}>{ownershipBadgeText}</div>
  );

  const bodyEl = (
    <CContainer>
      <div className="d-flex flex-wrap align-items-center position-relative">
        {ownershipBadgeEl}
        <CCardBody className="text-start position-relative">
          <strong className="assignment-card-title">{assignmentName}</strong>
          <div className="my-3" />
          <DataInfo name="Address" data={address} />
          <DataInfo name="Inspection Date" data={contactDate} />
          <DataInfo name="Creation Date" data={creationDateFormatted} />
          <a
            className="stretched-link"
            target="_blank"
            rel="noopener noreferrer"
            href={sharedAssignmentUrl}
          />
        </CCardBody>
        <div className="d-flex justify-content-center align-items-center m-3">
          <CForm>
            <CFormSwitch
              style={PROFILE_STYLES.switch}
              label={<CIcon icon={cilCloudUpload} size="lg" />}
              checked={cloudOnly}
              onChange={toggleVisibility}
            />
          </CForm>
        </div>
      </div>
    </CContainer>
  );

  return (
    <div className="w-100">
      <CCard className="assignment-card">
        <div className="d-flex">
          {imageEl}
          {bodyEl}
        </div>
      </CCard>
    </div>
  );
};

export default ProfileAssignmentCard;
