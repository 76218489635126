import {CSpinner} from "@coreui/react";
import PropTypes from "prop-types";
import React from "react";

export const CenteredSpinner = ({spinner}) => {
  return <div className="d-flex justify-content-center align-items-center h-100">{spinner}</div>;
};

CenteredSpinner.propTypes = {
  spinner: PropTypes.node,
};

CenteredSpinner.defaultProps = {
  spinner: <CSpinner color="primary" />,
};

export default CenteredSpinner;
