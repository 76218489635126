import {cilArrowRight} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {CButton, CFormCheck} from "@coreui/react";
import React from "react";
import {Link} from "react-router-dom";
import {conditionalClass, strJoin} from "../../../../utils/css-utils";
import {getInitials} from "../../../../utils/string-utils";
import OptionalAvatar from "../../../Avatar/OptionalAvatar";
import classes from "./GridProfile.module.css";

const AVATAR_STYLE = {"--cui-secondary-rgb": "250, 250, 250"};

const LINK_BUTTON_STYLE = {"--cui-btn-color": "#263238"};

const SUBSCRIPTION_BADGES = {
  "Free Trial": classes.freeTrialBadge,
  "Pro Lite": classes.proLiteBadge,
  Pro: classes.proBadge,
  None: classes.noneBadge,
};

const GridProfile = (props) => {
  const {
    avatar,
    deselect,
    email,
    expirationDateFormatted,
    id,
    industry,
    lastAccessFormatted,
    name,
    numAssignments,
    phoneNumber,
    select,
    selected,
    subscriptionActive,
    subscriptionExpired,
    subscriptionName,
  } = props;

  const toggleSelected = () => (selected ? deselect() : select());

  const handleCheckboxChange = (event) => (event.target.checked ? select : deselect)();

  const checkbox = (
    <CFormCheck style={{cursor: "pointer"}} checked={selected} onChange={handleCheckboxChange} />
  );

  const initials = getInitials(name || (email && email.split("@")[0]) || "");

  const avatarElement = (
    <OptionalAvatar
      src={avatar}
      className={classes.avatar}
      avatarSize="3xl"
      initials={initials}
      initialsClassName={classes.avatarInitials}
      style={AVATAR_STYLE}
    />
  );

  const subscriptionClasses = [
    classes.subscriptionBadge,
    SUBSCRIPTION_BADGES[subscriptionName] || classes.freeTrialBadge,
  ].join(" ");

  const subscriptionBadge = <span className={subscriptionClasses}>{subscriptionName}</span>;

  const subscriptionInactiveClasses = strJoin(classes.subscriptionBadge, classes.inactiveBadge);

  const subscriptionInactiveBadge = subscriptionActive ? null : (
    <span className={subscriptionInactiveClasses}>Inactive</span>
  );

  const subscriptionExpiredClasses = strJoin(classes.subscriptionBadge, classes.expiredBadge);

  const expiredBadge = subscriptionExpired ? (
    <span className={subscriptionExpiredClasses}>Expired</span>
  ) : null;

  const personalInfo = (
    <div className="overflow-hidden">
      <div className={classes.name}>{name}</div>
      <div className={classes.email}>{email}</div>
      <div className={classes.phoneNumber}>{phoneNumber}</div>
      <div className={classes.badges}>
        {subscriptionBadge}
        {subscriptionInactiveBadge}
        {expiredBadge}
      </div>
    </div>
  );

  const infoFields = (
    <div className="d-flex flex-column gap-2">
      <div>
        <div className={classes.fieldLabel}>Industry</div>
        <div className={classes.fieldValue}>{industry}</div>
      </div>

      <div className="d-flex justify-content-between">
        <div>
          <div className={classes.fieldLabel}>Last Access</div>
          <div className={classes.fieldValue}>{lastAccessFormatted}</div>
        </div>
        {expirationDateFormatted ? (
          <div>
            <div className={classes.fieldLabel}>Expires On</div>
            <div className={classes.fieldValue}>{expirationDateFormatted}</div>
          </div>
        ) : null}
      </div>

      <div>
        <div className={classes.fieldLabel}>UUID</div>
        <div className={classes.fieldValue}>{id}</div>
      </div>
    </div>
  );

  const navLink = (
    <Link to={`/admin/profiles/${id}`}>
      <CButton variant="ghost" style={LINK_BUTTON_STYLE}>
        <CIcon icon={cilArrowRight} />
      </CButton>
    </Link>
  );

  const footer = (
    <div className={classes.footer}>
      <div>
        <span className={classes.numAssignments}>{numAssignments}</span>
        <span className={classes.fieldLabel}>Assignments</span>
      </div>
      {navLink}
    </div>
  );

  const gridProfileClassName = [
    classes.gridProfile,
    conditionalClass(selected, classes.gridProfileSelected),
  ].join(" ");

  return (
    <div className={gridProfileClassName}>
      <button onClick={toggleSelected} className={classes.checkboxRow}>
        {checkbox}
      </button>
      <div className="d-flex align-items-center gap-2">
        {avatarElement}
        {personalInfo}
      </div>
      <div className="flex-grow-1" />
      <div>
        {infoFields}
        <hr className={classes.horizontalSeparator} />
        {footer}
      </div>
    </div>
  );
};

export default GridProfile;
