import {CContainer, CRow} from "@coreui/react";
import download from "downloadjs";
import React, {useState} from "react";
import Page from "../../../components/Page/Page";
import ReportCard from "./ReportCard";
import {ENV} from "../../../env";

const Reports = ({onMobileNavOpen}) => {
  const [loaders, setLoaders] = useState({allProfiles: false, paidProfiles: false});

  const getAllProfiles = async () => {
    setLoaders({...loaders, allProfiles: true});
    try {
      const res = await fetch(`${ENV.API_URL}/getProfilesCSV`);
      const blob = await res.blob();

      if (!(blob instanceof Blob)) return;

      setLoaders({...loaders, allProfiles: false});
      download(blob, "all-profiles.csv");
    } catch (error) {
      console.log(error);
    }
  };

  const getAllPaidProfiles = async () => {
    setLoaders({...loaders, paidProfiles: true});
    try {
      const res = await fetch(`${ENV.API_URL}/getPaidProfilesCSV`);
      const blob = await res.blob();

      if (!(blob instanceof Blob)) return;

      setLoaders({...loaders, paidProfiles: false});
      download(blob, "subscription-profiles.csv");
    } catch (error) {
      console.log(error);
    }
  };

  const getAllFreeProfiles = async () => {
    setLoaders({...loaders, freeProfiles: true});
    try {
      const res = await fetch(`${ENV.API_URL}/getFreeProfilesCSV`);
      const blob = await res.blob();

      if (!(blob instanceof Blob)) return;

      setLoaders({...loaders, freeProfiles: false});
      download(blob, "free-profiles.csv");
    } catch (error) {
      console.log(error);
    }
  };

  const reports = [
    {title: "All profiles report", action: () => getAllProfiles(), loading: loaders.allProfiles},
    {
      title: "All paid profiles report",
      action: () => getAllPaidProfiles(),
      loading: loaders.paidProfiles,
    },
    {
      title: "All free profiles report",
      action: () => getAllFreeProfiles(),
      loading: loaders.freeProfiles,
    },
  ];

  return (
    <Page title="Reports" onMobileNavOpen={onMobileNavOpen}>
      <CContainer className="d-flex justify-content-center mb-4">
        <p className="h2">Choose your report to download below:</p>
      </CContainer>
      <CContainer>
        <CRow className="gy-3">
          {reports.map((report, idx) => (
            <ReportCard key={idx} {...report} />
          ))}
        </CRow>
      </CContainer>
    </Page>
  );
};

export default Reports;
