import {ENV} from "../../env";
import {
  getHttpHeaders,
  getHttpHeadersExcel,
  httpPostRequest,
  httpPostRequestExcel,
} from "../../utils/api-utils";
import { FB_TOKEN_KEY } from "../Login/OAuth";

export async function submitExcel(data) {
  return await httpPostRequestExcel(submitExcelInfo.bind(this, data));
}

async function submitExcelInfo(data) {
  let init = getHttpHeadersExcel("POST");
  //init.headers['Content-type'] = data.type;
  const body = new FormData();
  body.append("file", data);
  init.body = body;
  return await fetch(`${ENV.API_URL}/createUserByFile`, init);
}

export async function submitUserApiCall(data) {
  return await httpPostRequest(submitUserApiCallInfo.bind(this, data));
}

async function submitUserApiCallInfo(data) {
  const options = getHttpHeaders("POST");

  const token = localStorage.getItem(FB_TOKEN_KEY);

  if (token) options.headers.fbtoken = token;

  options.body = JSON.stringify(data);
  return await fetch(`${ENV.API_URL}/createUser`, options);
}
