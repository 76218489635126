/**
 * Paginate an array
 * @param array Array to paginate
 * @param page Page number
 * @param rowsPerPage Number of rows per page
 * @returns {array}
 */
export const paginated = (array, page, rowsPerPage) => {
  const start = page * rowsPerPage;
  const end = start + rowsPerPage;
  return array.slice(start, end);
};
