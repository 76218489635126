import {createSlice} from "@reduxjs/toolkit";

// Slice for static data that is not expected to change
export const staticSlice = createSlice({
  name: "static",
  initialState: {
    industryTypeOptions: null,
    fetchingIndustryTypeOptions: false,
  },
  reducers: {
    setIndustryTypeOptions: (state, {payload: {industryTypeOptions}}) => {
      state.industryTypeOptions = industryTypeOptions;
      state.fetchingIndustryTypeOptions = false;
    },
    startFetchingIndustryTypeOptions: (state) => {
      state.fetchingIndustryTypeOptions = true;
    },
  },
});

export const {setIndustryTypeOptions, startFetchingIndustryTypeOptions} = staticSlice.actions;

export default staticSlice.reducer;
