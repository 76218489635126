import React from "react";
import {Link as RouterLink} from "react-router-dom";
import Logo from "../../assets/logo.svg";
import {CButton, CContainer, CNavbar} from "@coreui/react";
import {cilMenu} from "@coreui/icons";
import CIcon from "@coreui/icons-react";

const style = {
  root: {
    backgroundColor: "#6e00ff",
    color: "white",
  },
  logo: {
    height: "30px",
    width: "30px",
    marginRight: "16px",
  },
};

const TopBar = ({className, onMobileNavOpen, title, ...rest}) => {
  return (
    <CNavbar className={className} {...rest} style={style.root}>
      <CContainer fluid className="py-md-2">
        <div className="d-block d-md-none">
          <RouterLink to="/admin/profiles">
            <img src={Logo} style={style.logo} alt="logo" />
          </RouterLink>
        </div>
        <span>
          <strong>{title}</strong>
        </span>
        <div className="flex-grow-1" />
        <div className="d-block d-md-none">
          <CButton variant="ghost" onClick={onMobileNavOpen} className="text-white">
            <CIcon icon={cilMenu} />
          </CButton>
        </div>
      </CContainer>
    </CNavbar>
  );
};

export default TopBar;
