/**
 * Returns a copy of a set, with the element added.
 * @param set Original set
 * @param element Element to add
 * @returns {Set<any>} New set
 */
export function setWith(set, element) {
  return new Set(set).add(element);
}

/**
 * Returns a copy of a set, without the element removed.
 * @param set Original set
 * @param element Element to remove
 * @returns {Set<any>} New set
 */
export function setWithout(set, element) {
  const newSet = new Set(set);
  newSet.delete(element);
  return newSet;
}