import {CCard, CCardBody, CCardTitle, CContainer, CRow} from "@coreui/react";
import PropTypes from "prop-types";
import React from "react";
import classes from "./BulkEditSection.module.css";

const BulkEditSection = ({title, children, className}) => {
  return (
    <CRow className={className}>
      <CCard>
        <CCardBody>
          <CCardTitle className={classes.cardTitle}>{title}</CCardTitle>
          <CContainer>{children}</CContainer>
        </CCardBody>
      </CCard>
    </CRow>
  );
};

BulkEditSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  className: PropTypes.string,
};

BulkEditSection.defaultProps = {
  title: "",
  className: "",
};

export default BulkEditSection;
