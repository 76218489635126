import React from "react";
import CIcon from "@coreui/icons-react";
import {cilArrowBottom, cilArrowTop} from "@coreui/icons";
import "./TableHeaderSortLabel.css";

function TableHeaderSortLabel(props) {
  const {active, direction, onClick, label} = props;

  const styles = {
    header: {
      fontSize: ".9rem",
    },
  };

  return (
    <div className="header-label" style={styles.header} onClick={onClick}>
      <div className="d-flex align-items-center">
        <div>{label}</div>
        <div className="ms-2">
          {active ? (
            direction === "asc" ? (
              <CIcon icon={cilArrowTop} />
            ) : (
              <CIcon icon={cilArrowBottom} />
            )
          ) : (
            <CIcon icon={cilArrowTop} className="hover-icon" />
          )}
        </div>
      </div>
    </div>
  );
}

export default TableHeaderSortLabel;
