import React from "react";
import PropTypes from "prop-types";
import {CContainer, CTableHead, CTableHeaderCell, CTableRow} from "@coreui/react";
import TableHeaderSortLabel from "./TableHeaderSortLabel";

function LinkTableHead(props) {
  const {order, orderBy, onRequestSort, headCells, linkLabel} = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <CTableHead>
      <CTableRow align="middle">
        {headCells.map((headCell, idx) => (
          <CTableHeaderCell key={idx}>
            <TableHeaderSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              label={headCell.label}
            />
          </CTableHeaderCell>
        ))}
        <CTableHeaderCell className="py-4">
          <CContainer className="d-flex justify-content-center">
            <div>{linkLabel}</div>
          </CContainer>
        </CTableHeaderCell>
      </CTableRow>
    </CTableHead>
  );
}

export default LinkTableHead;

LinkTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired,
  linkLabel: PropTypes.string,
};
