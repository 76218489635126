import {toBooleanIfApplicable} from "./string-utils";

export const buildBulkSubscriptionUpdates = (userIds, subscriptionForm) => {
  const updates = Object.entries(subscriptionForm).reduce((acc, [fieldId, {active, value}]) => {
    if (active) acc[fieldId] = toBooleanIfApplicable(value);
    return acc;
  }, {});

  if (!Object.keys(updates).length) return {};

  return userIds.reduce((acc, userId) => {
    const subscriptionPath = `uscope/subscriptions/users/${userId}`;

    for (const [fieldId, value] of Object.entries(updates)) {
      const path = `${subscriptionPath}/${fieldId}`;
      acc[path] = value;
    }

    return acc;
  }, {});
};

export const buildBulkPermissionUpdates = (userIds, permissionUpdates) =>
  userIds.reduce((acc, userId) => {
    acc[`uscope/profiles/${userId}/overwritePermissions`] = permissionUpdates;
    return acc;
  }, {});
