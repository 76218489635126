import React from "react";
import CenteredSpinner from "../../../components/CenteredSpinner/CenteredSpinner";
import Page from "../../../components/Page/Page";
import SimpleTable from "../../../components/Table/SimpleTable";
import useNeedsAttentionProfiles from "../../../hooks/useNeedsAttentionProfiles";

const HEAD_CELLS = [
  {id: "message", label: "Message"},
  {id: "createdAt", label: "Created At"},
  {id: "id", label: "UID"},
];

const DATA_CELLS = [
  {dataKey: "message"},
  {dataKey: "createdAt", type: "datetime"},
  {dataKey: "id"},
];

const NeedsAttentionProfiles = ({onMobileNavOpen}) => {
  const {profiles, fetching} = useNeedsAttentionProfiles();

  if (!profiles || fetching) return <CenteredSpinner />;

  return (
    <Page title="User Issues" onMobileNavOpen={onMobileNavOpen}>
      <SimpleTable data={profiles} dataCells={DATA_CELLS} headCells={HEAD_CELLS} />
    </Page>
  );
};

export default NeedsAttentionProfiles;
