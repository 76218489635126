import PropTypes from "prop-types";
import React, {forwardRef} from "react";
import {Helmet} from "react-helmet";
import {APP_NAMES} from "../../constants/app-name";

const Page = forwardRef(({children, title = "", onMobileNavOpen, ...rest}, ref) => (
  <div>
    <div ref={ref} {...rest} className="w-100 h-100 p-md-5 p-sm-3 p-1">
      <Helmet>
        <title>
          {APP_NAMES.SHORT} - {title}
        </title>
      </Helmet>
      {children}
    </div>
  </div>
));

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
