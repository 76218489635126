import PropTypes from "prop-types";
import React from "react";
import {getInitials} from "../../../../../utils/string-utils";
import OptionalAvatar from "../../../../Avatar/OptionalAvatar";
import classes from "./ProfilesTableRowUser.module.css";

const AVATAR_STYLE = {"--cui-secondary-rgb": "250, 250, 250"};

const ProfilesTableRowUser = ({avatar, name, email, phoneNumber}) => {
  const initials = getInitials(name || (email && email.split("@")[0]) || "");

  const avatarElement = (
    <OptionalAvatar
      src={avatar}
      className={classes.avatar}
      style={AVATAR_STYLE}
      avatarSize="sm"
      initials={initials}
    />
  );

  const info = (
    <div className={classes.info}>
      <div className={classes.infoName}>{name}</div>
      <div className={classes.infoEmail}>{email}</div>
      <div className={classes.infoPhone}>{phoneNumber}</div>
    </div>
  );

  return (
    <div className="d-flex gap-2 align-items-center">
      {avatarElement}
      {info}
    </div>
  );
};

ProfilesTableRowUser.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  avatarClassName: PropTypes.string,
};

ProfilesTableRowUser.defaultProps = {
  avatar: "",
  name: "",
  email: "",
  avatarClassName: "",
};

export default ProfilesTableRowUser;
