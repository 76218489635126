import {FB_TOKEN_KEY, renewToken, TOKEN_KEY} from "../Controllers/Login/OAuth";

export function getHttpHeaders(method) {
  const options = {
    method,
    headers: {
      Accept: "*/*",
      "Content-type": "application/json",
    },
  };

  const token = localStorage.getItem(TOKEN_KEY);
  if (token) options.headers.Authorization = `Bearer ${token}`;

  return options;
}

export function getHttpHeadersExcel(method) {
  const init = {
    method: method,
    headers: {
      Accept: "*/*",
    },
  };
  const token = localStorage.getItem(FB_TOKEN_KEY);
  if (token) init.headers.fbtoken = token;
  return init;
}
export async function httpPostRequest(callbackMethod) {
  let response = await callbackMethod();
  if (response.ok) {
    return await response.json();
  } else if (response.status === 400) {
    await renewToken();
    response = await callbackMethod();
    if (response.ok) {
      return await response.json();
    }
    return await response.json();
  }
}

export async function httpPostRequestExcel(callbackMethod) {
  let response = await callbackMethod();
  if (response.ok) {
    return await response.json();
  } else if (response.status === 400) {
    await renewToken();
    response = await callbackMethod();
    if (response.ok) {
      return await response.json();
    }
    return await response.json();
  }
}
