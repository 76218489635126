import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import {FB_TOKEN_KEY, getLogin, REFRESH_TOKEN_KEY, TOKEN_KEY} from "../Controllers/Login/OAuth";
import {ENV} from "../env";
import {getNumImagesInAssignment} from "../utils/assignment-utils";
import {getRefPath} from "../utils/database-utils";
import {asyncForEach} from "../utils/utils";

class Firebase {
  constructor() {
    firebase.initializeApp({
      apiKey: ENV.FIREBASE_API_KEY,
      authDomain: ENV.FIREBASE_AUTH_DOMAIN,
      databaseURL: ENV.FIREBASE_DATABASE_URL,
      projectId: ENV.FIREBASE_PROJECT_ID,
      storageBucket: ENV.FIREBASE_STORAGE_BUCKET,
      messagingSenderId: ENV.FIREBASE_MESSAGING_SENDER_ID,
    });

    this.auth = firebase.auth();
    this.systemToken = null;
    this.uscope = firebase.database().ref("uscope");
  }

  async customLogin(email, password) {
    let finalResult = null;
    await getLogin({email, password}).then(async (response) => {
      if (response.success) {
        localStorage.setItem(TOKEN_KEY, response.token);
        localStorage.setItem(FB_TOKEN_KEY, response.fbtoken);
        localStorage.setItem(REFRESH_TOKEN_KEY, response.refreshToken);

        await this.auth.signInWithEmailAndPassword(email, password);

        if (finalResult === null) {
          const userId = this.auth.currentUser.uid;
          finalResult = await this.getProfile(userId);
        }
      } else {
        finalResult = {success: false, data: null, message: response.message};
      }
    });
    return finalResult;
  }

  logout = async () => {
    localStorage.clear();
    await this.auth.signOut();
  };

  async getProfile(profileId) {
    const profileRef = firebase.database().ref("uscope/profiles").child(profileId);
    try {
      const profile = (await profileRef.once("value")).val();

      if (!profile) return {success: false, data: null, message: "Profile not found"};
      if (profile.deleted) return {success: false, data: null, message: "Profile deleted"};

      return {success: true, data: profile, message: null};
    } catch ({message}) {
      return {success: false, data: null, message};
    }
  }

  async getProfiles() {
    try {
      const profilesRef = this.uscope.child("profiles");
      const profilesObj = (await profilesRef.once("value")).val();

      // Filter out problematic profile objects (no ID, or the ID and the key don't match)
      // As well as profiles marked as deleted
      for (const [key, {id, deleted}] of Object.entries(profilesObj))
        if (!id || id !== key || deleted) delete profilesObj[key];

      const usersSubscriptionsRef = this.uscope.child("subscriptions").child("users");
      const subscriptions = (await usersSubscriptionsRef.once("value")).val();

      // Add the subscription objects to each profile
      for (const [profileId, subscription] of Object.entries(subscriptions)) {
        const profile = profilesObj[profileId];
        if (profile) profile.subscription = subscription;
      }

      const profiles = Object.values(profilesObj);

      return {success: true, data: profiles, message: null};
    } catch (error) {
      return {success: false, data: null, message: error.message};
    }
  }

  /**
   * Get an assignment by ID as the user with the given profile ID, so we can check if this user has
   * backed up the assignment to the cloud.
   * @param profileId The ID of the user's profile
   * @param assignmentId The ID of the assignment
   * @returns {Promise<{[p: string]: *}|null>} The assignment object, or null if it doesn't exist
   */
  getAssignmentByIdAsUser = async (profileId, assignmentId) => {
    if (!assignmentId) return null;

    const assignmentRef = this.uscope.child("assignments").child(assignmentId);

    const assignment = (await assignmentRef.once("value")).val();

    if (!assignment) {
      const message = `Assignment ${assignmentId} from profile ${profileId} not found.`;
      console.warn(message);
      await this.addAssignmentToNeedsAttention(assignmentId, message);
      return null;
    }

    if (!assignment.structures) {
      await this.addAssignmentToNeedsAttention(
        assignmentId,
        `Assignment ${assignmentId} has no structures.`
      );
      return null;
    }

    const numImages = getNumImagesInAssignment(assignment);

    const cloudOnlyRef = this.uscope
      .child("profiles")
      .child(profileId)
      .child("assignsList")
      .child(assignmentId)
      .child("deleted");

    const cloudOnly = (await cloudOnlyRef.once("value")).val() || false;

    return {
      ...assignment,
      picturesAmount: numImages,
      cloudOnly,
    };
  };

  getAssignments = async (profileId, assignsList) => {
    const getAssignmentById = async (assignmentId) =>
      await this.getAssignmentByIdAsUser(profileId, assignmentId);

    const assignments = (await Promise.all(assignsList.map(getAssignmentById))).filter(Boolean);

    if (!assignments.length) return {success: false, data: null, message: "No assignments found"};

    return {success: true, data: assignments, message: null};
  };

  async getSubscriptionInfo(id) {
    let finalResult = {success: false, data: null, message: null};

    await firebase
      .database()
      .ref("uscope/subscriptions/users")
      .child(id)
      .once("value", (snapshot) => {
        const info = snapshot.val();
        if (info) {
          finalResult = {...finalResult, success: true, data: info};
        } else {
          finalResult = {
            ...finalResult,
            message: "No subscription found for this user",
          };
        }
      })
      .catch((e) => (finalResult = {...finalResult, message: e.message}));

    return finalResult;
  }

  async getManagerUsers(managerId) {
    const profilesRef = firebase.database().ref("uscope/profiles");
    const managedProfilesRef = profilesRef.child(managerId).child("managementProfiles");

    let users = [];
    let usersIds = [];

    try {
      const managedProfiles = (await managedProfilesRef.once("value")).val();

      if (!managedProfiles)
        return {success: false, data: null, message: "This user does not manage any other users."};

      usersIds = Object.keys(managedProfiles);
    } catch ({message}) {
      return {success: false, data: null, message};
    }

    await Promise.all(
      usersIds.map(async (profileId) => {
        const ref = profilesRef.child(profileId);
        const profile = (await ref.once("value")).val();
        if (profile && !profile.deleted) users.push(profile);
      })
    );

    return {success: true, data: users, message: null};
  }

  async getUserManagers(userId) {
    const profilesRef = await firebase.database().ref("uscope/profiles");
    let res;

    await profilesRef
      .once("value", (snapshot) => {
        let managers = [];
        snapshot.forEach((childSnapshot) => {
          if (childSnapshot.hasChild("managementProfiles")) {
            const isUsersManager = childSnapshot.child("managementProfiles").hasChild(userId);
            if (isUsersManager) {
              managers.push(childSnapshot.val());
            }
          }
        });
        res = {success: true, data: managers, message: null};
      })
      .catch((e) => {
        res = {success: false, data: null, message: e};
      });

    return res;
  }

  async getNeedsAttentionProfiles() {
    let res;
    const ref = await firebase.database().ref("uscope/needsAttention/profiles");

    await ref
      .once("value", (snapshot) => {
        const profiles = Object.values(snapshot.val()).map((profile) => ({
          ...profile, // Fix timestamps in microseconds (should be in milliseconds)
          createdAt: profile.createdAt / (profile.createdAt.toString().length === 16 ? 1000 : 1),
        }));
        res = {success: true, data: profiles, message: null};
      })
      .catch((e) => (res = {success: false, data: null, message: e}));

    return res;
  }

  async updateProfile(profile, fields, imgFile = null) {
    let finalResult = {success: false, data: null, message: null};
    let data = [];

    await asyncForEach(fields, async (field) => {
      if (field === "avatar" && imgFile) {
        const storageRef = firebase.storage();
        const profileImageRef = storageRef.ref("/profileImages/").child(profile.id);

        await profileImageRef
          .put(imgFile)
          .then(async (snapshot) => {
            const avatar = await snapshot.ref.getDownloadURL();
            await firebase
              .database()
              .ref("uscope/profiles")
              .child(profile.id)
              .child(field)
              .set(avatar);
            data.push(avatar);
            finalResult = {success: true, data: data};
          })
          .catch((e) => {
            finalResult = {
              ...finalResult,
              message: `Image upload failed: ${e}`,
            };
          });
      } else {
        await firebase
          .database()
          .ref("uscope/profiles")
          .child(profile.id)
          .child(field)
          .set(profile[field]);
        data.push(profile[field]);
        finalResult = {success: true, data: data};
      }
    }).catch(() => {
      finalResult = {...finalResult, message: "catch for each"};
    });

    return finalResult;
  }

  getProfileByEmail = async (email) => {
    const profilesRef = this.uscope.child("profiles");
    const profilesWithEmailRef = profilesRef.orderByChild("email").equalTo(email);
    const profilesWithEmailObj = (await profilesWithEmailRef.once("value")).val();
    const profilesWithEmail = Object.values(profilesWithEmailObj || {});

    if (!profilesWithEmail.length)
      return {success: false, data: null, message: `No profile found for email "${email}"`};

    if (profilesWithEmail.length > 1)
      console.error(
        `Multiple profiles with the same email: ${email}. Profiles:`,
        profilesWithEmail
      );

    const profile = profilesWithEmail[0];

    return {success: true, data: {profile}, message: null};
  };

  getUserIdByEmail = async (email) => {
    const {success, data, message} = await this.getProfileByEmail(email);

    if (!success) return {success, data, message};

    const {
      profile: {id},
    } = data;

    return {success, data: {userId: id}, message};
  };

  getProfileRef = (profileId) => this.uscope.child("profiles").child(profileId);

  assignManagerToUser = async (subordinateId, managerId) => {
    if (subordinateId === managerId)
      return {
        success: false,
        data: null,
        message: "A user cannot be assigned as their own manager",
      };

    const subordinatesRef = this.getProfileRef(managerId).child("managementProfiles");

    const subordinateEntryRef = subordinatesRef.child(subordinateId);
    const alreadyAssigned = !!(await subordinateEntryRef.once("value")).val();

    if (alreadyAssigned)
      return {success: false, data: null, message: "This manager is already assigned to this user"};

    await subordinateEntryRef.set(subordinateId);

    return {
      success: true,
      data: {subordinateId, managerId},
      message: "Successfully assigned manager to user",
    };
  };

  assignManagerToUserByEmail = async (subordinateId, managerEmail) => {
    const {success, data, message} = await this.getUserIdByEmail(managerEmail);

    if (!success) return {success, data, message};

    const {userId: managerId} = data;

    return await this.assignManagerToUser(subordinateId, managerId);
  };

  unassignManagerFromUser = async (userId, managerId) => {
    const managerUsersRef = await firebase
      .database()
      .ref("uscope/profiles")
      .child(managerId)
      .child("managementProfiles");
    let res;

    await managerUsersRef.once("value", (snapshot) => {
      if (snapshot.hasChild(userId)) {
        managerUsersRef
          .child(userId)
          .remove()
          .then(() => (res = {success: true, data: snapshot.val(), message: null}))
          .catch((e) => (res = {success: false, data: null, message: e}));
      }
    });

    return res;
  };

  async updateAssignmentVisibility(profileId, assignmentId, cloudOnly) {
    // "deleted" means "backed up to the cloud", unfortunate naming

    const profileRef = this.uscope.child("profiles").child(profileId);
    const cloudOnlyRef = profileRef.child("assignsList").child(assignmentId).child("deleted");

    try {
      await cloudOnlyRef.set(cloudOnly);
      return {success: true, data: cloudOnly, message: null};
    } catch (error) {
      return {success: false, data: null, message: error};
    }
  }

  async getSubscriptions() {
    const response = await fetch(`${ENV.API_URL}/getSubscriptions`);
    return await response.json();
  }

  async addProfileToNeedsAttention(profileId, message) {
    let res;
    const ref = await firebase.database().ref("uscope/needsAttention/profiles");

    await ref
      .child(profileId)
      .set({
        createdAt: Date.now(),
        id: profileId,
        message: message,
      })
      .then(() => ref.child(profileId).once("value"))
      .then((snapshot) => (res = {success: true, data: snapshot.val(), message: null}))
      .catch((e) => (res = {success: false, data: null, message: e}));

    return res;
  }

  async addAssignmentToNeedsAttention(assignmentId, message) {
    const ref = firebase.database().ref("uscope/needsAttention/assignments");

    try {
      await ref.child(assignmentId).set({
        createdAt: Date.now(),
        id: assignmentId,
        message: message,
      });
      const snapshot = await ref.child(assignmentId).once("value");
      return {success: true, data: snapshot.val(), message: null};
    } catch (error) {
      return {success: false, data: null, message: error};
    }
  }

  async getSystemApiToken() {
    if (this.systemToken) return this.systemToken;

    const ref = firebase.database().ref("uscope/apiTokens/system");
    this.systemToken = (await ref.once("value")).val();
    return this.systemToken;
  }

  async getUserPermissions(userId) {
    const systemtoken = await this.getSystemApiToken();

    return fetch(`${ENV.API_URL}/permissions/${userId}?toNumerical=true`, {
      headers: {
        "Content-Type": "application/json",
        systemtoken,
      },
    });
  }

  #getOverwritePermissionsRef = (userId) =>
    firebase.database().ref("uscope/profiles").child(userId).child("overwritePermissions");

  async updateUserOverwritePermissions(userId, permissionUpdates) {
    const ref = this.#getOverwritePermissionsRef(userId);

    await ref.update(permissionUpdates, (error) =>
      error
        ? {
            success: false,
            data: null,
            message: error,
          }
        : {success: true, data: null, message: null}
    );
  }

  async clearUserOverwritePermissions(userId) {
    const ref = this.#getOverwritePermissionsRef(userId);

    await ref.remove((error) =>
      error
        ? {success: false, data: null, message: error}
        : {
            success: true,
            data: null,
            message: null,
          }
    );
  }

  getAllSubscriptionsPermissions = async () => {
    // If we did all of them instead of just this array, we'd also be fetching the entire users list's permissions
    const subscriptionNames = ["free", "pro", "proLite", "proPlus"];

    const subscriptionsRef = firebase.database().ref("uscope/subscriptions");

    const subscriptionsPermissions = {};

    try {
      await Promise.all(
        subscriptionNames.map(async (name) => {
          const ref = subscriptionsRef.child(name);
          subscriptionsPermissions[name] = (await ref.once("value")).val();
        })
      );
    } catch (error) {
      return {success: false, data: null, message: error};
    }

    return {success: true, data: subscriptionsPermissions, message: null};
  };

  getIndustryTypeOptions = async () => {
    const ref = this.uscope.child("industryTypes");

    try {
      const industryTypeObj = (await ref.once("value")).val();
      const industryTypeOptions = Object.entries(industryTypeObj).map(([key, value]) => ({
        value: key,
        label: value,
      }));
      return {success: true, industryTypeOptions};
    } catch (error) {
      return {success: false, message: error};
    }
  };

  async markUsersAsDeletedAndDisableSubscription(profileIds) {
    const baseProfilePath = getRefPath(this.uscope.child("profiles"));
    const baseUserSubscriptionPath = getRefPath(this.uscope.child("subscriptions").child("users"));

    const profileUpdates = profileIds.reduce((acc, id) => {
      acc[`${baseProfilePath}/${id}/deleted`] = true;
      return acc;
    }, {});

    const userSubscriptionUpdates = profileIds.reduce((acc, id) => {
      acc[`${baseUserSubscriptionPath}/${id}/isActive`] = false;
      return acc;
    }, {});

    const updates = {...profileUpdates, ...userSubscriptionUpdates};

    return await this.update(updates);
  }

  async update(updates) {
    try {
      await firebase.database().ref().update(updates);
      return {success: true, message: null};
    } catch (error) {
      return {success: false, message: error};
    }
  }

  updateUserSubscription = async (userId, subscription) => {
    const userSubscriptionRef = this.uscope.child(`subscriptions/users/${userId}`);
    await userSubscriptionRef.update(subscription);
  };

  fixProfilesById = async (profileIds) => {
    const url = `${ENV.API_URL}/admin/fixProfilesSchema`;
    const fbToken = localStorage.getItem(FB_TOKEN_KEY);
    const headers = {token: fbToken, "Content-Type": "application/json"};
    const body = JSON.stringify({profileIds});

    const pluralSuffix = profileIds.length === 1 ? "" : "s";

    try {
      const {ok: success} = await fetch(url, {method: "POST", headers, body});

      const message = success
        ? `Profile${pluralSuffix} fixed successfully`
        : `Failed to fix profile${pluralSuffix}`;

      return {success, message};
    } catch (error) {
      return {success: false, message: error.message};
    }
  };

  assignManagerToUsers = async (managerId, subordinateIds) => {
    const updates = subordinateIds.reduce((acc, subordinateId) => {
      acc[`uscope/profiles/${managerId}/managementProfiles/${subordinateId}`] = subordinateId;
      return acc;
    }, {});

    try {
      await this.update(updates);
    } catch (error) {
      return {success: false, message: error.message};
    }

    return {success: true, message: null};
  };

  assignManagerToUsersByEmail = async (managerEmail, userIds) => {
    try {
      const {
        success: getUserSuccess,
        message: getUserMessage,
        data,
      } = await this.getUserIdByEmail(managerEmail);

      if (!getUserSuccess) return {success: false, message: getUserMessage};

      const managerId = data.userId;

      return await this.assignManagerToUsers(managerId, userIds);
    } catch (error) {
      return {success: false, message: error.message};
    }
  };
}

export default new Firebase();
