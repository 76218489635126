import {cilExitToApp} from "@coreui/icons";
import CIcon from "@coreui/icons-react";

import {
  CButton,
  CNav,
  CNavItem,
  CNavLink,
  COffcanvas,
  COffcanvasBody,
  CSidebar,
  CSidebarNav,
} from "@coreui/react";
import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import logo from "../../../assets/logo.svg";
import useAppRoutes from "../../../hooks/useAppRoutes";
import firebase from "../../../service/firebase";
import {APP_NAMES} from "../../../constants/app-name";

const STYLES = {
  sidebar: {
    "--cui-sidebar-bg": "#212121",
    "--cui-sidebar-color": "#546e7a",
    "--cui-sidebar-nav-link-color": "#546e7a",
    "--cui-sidebar-nav-link-active-color": "white",
    "--cui-sidebar-nav-link-active-icon-color": "white",
  },
  offcanvas: {
    "--cui-offcanvas-width": "256px",
    "--cui-offcanvas-bg": "#212121",
    "--cui-offcanvas-color": "#546e7a",
    "--cui-offcanvas-padding-x": 0,
    nav: {
      "--cui-nav-link-color": "#546e7a",
      "--cui-nav-link-hover-color": "white",
      "--cui-nav-pills-border-radius": 0,
      "--cui-nav-pills-link-active-bg": "#2c2c2c",
    },
  },
  navlink: {
    cursor: "pointer",
  },
};

const NavBar = ({onMobileClose, openMobile, setPageTitle}) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      console.log(location.pathname);
      onMobileClose();
    }
  }, [location.pathname]);

  const mdBreakpoint = 768; // Bootstrap md breakpoint, in pixels, default: 768px

  // Automatically close the mobile off-canvas when screen size becomes >= md
  useEffect(() => {
    window.matchMedia(`(min-width: ${mdBreakpoint}px)`).addEventListener("change", (e) => {
      if (e.matches) onMobileClose();
    });
  }, [onMobileClose]);

  const logout = async () => {
    await firebase.logout();
    history.replace("/signIn");
  };

  // Hack to fix the desktop sidebar not reappearing
  const fixDesktopBarVisibility = () => {
    document.getElementById("sidebar-desktop").classList.remove("hide");
  };

  const {routes: adminRoutes} = useAppRoutes();

  function goToItemPath(item) {
    setPageTitle(item.title);
    history.push(item.path);
  }

  function genNavItem(item, active, idx, mobile = false) {
    const content = mobile ? (
      <div className="d-flex align-items-center py-2">
        <CIcon icon={item.icon} className="mx-2" />
        {active ? <b>{item.title}</b> : <span>{item.title}</span>}
      </div>
    ) : (
      <>
        <CIcon icon={item.icon} className="mx-2" />
        {active ? <b>{item.title}</b> : <span>{item.title}</span>}
      </>
    );

    return (
      <CNavItem active={active} key={idx}>
        {item.path ? (
          <CNavLink onClick={() => goToItemPath(item)} active={active} style={STYLES.navlink}>
            {content}
          </CNavLink>
        ) : (
          <CNavLink href={item.href} active={active} style={STYLES.navlink}>
            {content}
          </CNavLink>
        )}
      </CNavItem>
    );
  }

  const genNavItems = (mobile = false) =>
    adminRoutes.map((item, idx) => {
      const match = !!matchPath(location.pathname, {path: item.path});
      return item.shownInNav ? genNavItem(item, match, idx, mobile) : null;
    });

  const navHeaderContent = (
    <>
      <div className="me-2">
        <img src={logo} width={50} height={50} alt="logo" />
      </div>
      <div className="h6" style={{color: "#fff"}}>
        {APP_NAMES.SHORT}
      </div>
    </>
  );

  const logoutButton = (
    <div className="p-2">
      <CButton onClick={logout} variant="ghost" color="secondary" className="w-100 py-2">
        <div className="w-100 d-flex justify-content-start align-items-center">
          <span>
            <CIcon icon={cilExitToApp} size="sm" className="me-2" />
            Log out
          </span>
        </div>
      </CButton>
    </div>
  );

  // Mobile off-canvas, hidden on >=medium screens
  const mobileNavEl = (
    <div className="d-md-none">
      <COffcanvas
        visible={openMobile}
        onHide={onMobileClose}
        placement="start"
        style={STYLES.offcanvas}
      >
        <div className="d-flex align-items-center py-3 px-4">{navHeaderContent}</div>
        <hr className="p-0 m-0" />
        <COffcanvasBody>
          <CNav className="flex-column" variant="pills" style={STYLES.offcanvas.nav}>
            {genNavItems(true)}
          </CNav>
        </COffcanvasBody>
        <div className="flex-grow-1" />
        <hr className="p-0 m-0" />
        {logoutButton}
      </COffcanvas>
    </div>
  );

  // Desktop permanent sidebar, hidden on <=small screens
  const desktopNavEl = (
    <div className="d-none d-md-block">
      <CSidebar
        className="sidebar-custom"
        position="fixed"
        onVisibleChange={fixDesktopBarVisibility}
        id="sidebar-desktop"
        style={STYLES.sidebar}
      >
        <div className="d-flex align-items-center py-3 px-4">{navHeaderContent}</div>
        <hr className="p-0 m-0" />
        <CSidebarNav>{genNavItems()}</CSidebarNav>
        <hr className="p-0 m-0" />
        {logoutButton}
      </CSidebar>
    </div>
  );

  return (
    <>
      {mobileNavEl}
      {desktopNavEl}
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  setPageTitle: PropTypes.func,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
