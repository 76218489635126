import {createSlice} from "@reduxjs/toolkit";

export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState: {
    subscriptions: null,
    fetchingSubscriptions: false,
  },
  reducers: {
    setSubscriptions: (state, {payload: {subscriptions}}) => {
      state.subscriptions = subscriptions;
      state.fetchingSubscriptions = false;
    },
    startFetchingSubscriptions: (state) => {
      state.fetchingSubscriptions = true;
    },
  },
});

export const {setSubscriptions, startFetchingSubscriptions} = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
