import useProfiles from "./useProfiles";
import {useUserContext} from "./useUserContext";

const useMyUsers = () => {
  const {user} = useUserContext();
  const {profiles, fetchProfiles, fetching: fetchingProfiles} = useProfiles();

  const myUsers = (() => {
    if (!(user?.id && profiles)) return null;

    const managedProfiles = Object.values(user.managementProfiles || {});
    if (!managedProfiles.length) return [];

    return profiles.filter(({id}) => managedProfiles.includes(id));
  })();

  return {
    fetchProfiles,
    myUsers,
    fetching: fetchingProfiles || !user?.id,
  };
};

export default useMyUsers;
