import {cilArrowBottom, cilArrowTop} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {CTableHeaderCell} from "@coreui/react";
import PropTypes from "prop-types";
import React from "react";
import {ASC, DESC} from "../../../constants/sorting";
import {conditionalClass} from "../../../utils/css-utils";
import classes from "../ProfilesView.module.css";

const SortableHeaderCell = ({
  orderBy,
  setOrderBy,
  order,
  setOrder,
  sortValue,
  textIsInvisible,
  children,
  Component,
}) => {
  const isSortedBy = orderBy === sortValue;

  const icon = (() => {
    if (isSortedBy) return order === ASC ? cilArrowTop : cilArrowBottom;
    return cilArrowTop;
  })();

  const arrow = <CIcon icon={icon} size="sm" />;

  const handleHeaderClick = textIsInvisible
    ? () => {}
    : () => {
        const newOrder = isSortedBy ? (order === ASC ? DESC : ASC) : ASC;
        setOrder(newOrder);
        setOrderBy(sortValue);
      };

  const cellClassName = [conditionalClass(textIsInvisible, classes.invisibleText), "fw-bold"].join(
    " "
  );

  const buttonClassName = [
    classes.headerCellButton,
    conditionalClass(isSortedBy, classes.headerCellButtonActiveSort),
    conditionalClass(textIsInvisible, classes.cursorDefault),
  ].join(" ");

  return (
    <Component className={cellClassName}>
      <button onClick={handleHeaderClick} className={buttonClassName}>
        {children} {arrow}
      </button>
    </Component>
  );
};

SortableHeaderCell.propTypes = {
  orderBy: PropTypes.string.isRequired,
  setOrderBy: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  setOrder: PropTypes.func.isRequired,
  sortValue: PropTypes.string.isRequired,
  textIsInvisible: PropTypes.bool,
  children: PropTypes.node.isRequired,
  component: PropTypes.elementType,
};

SortableHeaderCell.defaultProps = {
  textIsInvisible: false,
  Component: CTableHeaderCell,
};

export default SortableHeaderCell;
