import {CCol, CRow} from "@coreui/react";
import PropTypes from "prop-types";
import React from "react";
import {conditionalClass} from "../../../utils/css-utils";
import viewClasses from "../ProfilesView.module.css";
import SortableHeaderCell from "../SortableHeaderCell/SortableHeaderCell";
import GridProfile from "./GridProfile/GridProfile";
import classes from "./ProfilesViewGrid.module.css";

const SORT_OPTIONS = [
  {name: "Name", sortValue: "fullName"},
  {name: "Subscription", sortValue: "subscriptionName"},
  {name: "Expires on", sortValue: "expirationDate"},
  {name: "Last Access", sortValue: "lastAccess"},
  {name: "Nr. of Assignments", sortValue: "numAssignments"},
  {name: "Industry Type", sortValue: "industryType"},
  {name: "UUID", sortValue: "id"},
];

const ProfilesViewGrid = ({
  actionsButton,
  deselectProfile,
  headerCheckbox,
  headerCheckboxChecked,
  order,
  orderBy,
  paginatedProfiles,
  selectProfile,
  selectedProfiles,
  setOrder,
  setOrderBy,
}) => {
  const profileElements = paginatedProfiles.map((profile) => {
    const selected = selectedProfiles.has(profile);

    const select = () => selectProfile(profile);

    const deselect = () => deselectProfile(profile);

    return (
      <CCol xl={3} lg={4} md={6} sm={6} xs={12} key={profile.id}>
        <GridProfile
          avatar={profile.avatar}
          deselect={deselect}
          email={profile.email}
          expirationDate={profile.subscription?.expirationDate}
          expirationDateFormatted={profile.expirationDateFormatted}
          id={profile.id}
          industry={profile.industryType}
          lastAccess={profile.lastAccess}
          lastAccessFormatted={profile.lastAccessFormatted}
          name={profile.fullName}
          numAssignments={profile.numAssignments}
          phoneNumber={profile.phone}
          select={select}
          selected={selected}
          subscriptionActive={profile.subscriptionActive}
          subscriptionExpired={profile.subscriptionExpired}
          subscriptionName={profile.subscriptionName}
        />
      </CCol>
    );
  });

  const headClasses = [
    viewClasses.header,
    conditionalClass(headerCheckboxChecked, viewClasses.headerActive),
    "d-flex",
    "justify-content-between",
    "align-items-center",
    "px-3",
    "pt-2",
    "pb-3",
  ].join(" ");

  const headerSecondElements = headerCheckboxChecked
    ? null
    : SORT_OPTIONS.map(({name, sortValue}) => (
        <SortableHeaderCell
          setOrder={setOrder}
          sortValue={sortValue}
          setOrderBy={setOrderBy}
          orderBy={orderBy}
          order={order}
          key={sortValue}
          Component="span"
        >
          {name}
        </SortableHeaderCell>
      ));

  return (
    <div>
      {/* marginBottom is a band-aid fix. I couldn't figure out why the CRow was overlapping this div */}
      <div className={headClasses} style={{marginBottom: 10}}>
        <div className="d-flex gap-4">
          {headerCheckbox}
          {headerCheckboxChecked ? <b>{selectedProfiles.size} selected</b> : null}
        </div>
        {headerSecondElements}
        {actionsButton}
      </div>
      <CRow className={classes.gridView} xs={{gutter: 2}}>
        {profileElements}
      </CRow>
    </div>
  );
};

ProfilesViewGrid.propTypes = {
  actionsButton: PropTypes.element.isRequired,
  deselectProfile: PropTypes.func.isRequired,
  headerCheckbox: PropTypes.element.isRequired,
  headerCheckboxChecked: PropTypes.bool.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  paginatedProfiles: PropTypes.array.isRequired,
  selectProfile: PropTypes.func.isRequired,
  selectedProfiles: PropTypes.object.isRequired,
  setOrder: PropTypes.func.isRequired,
  setOrderBy: PropTypes.func.isRequired,
};

export default ProfilesViewGrid;
