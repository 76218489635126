import {startCase} from "lodash/string";

const PREFIX = "photoid";
const SEP = "."; // Separator

const VALUE_FREE = "free";
const LABEL_FREE = "Free Trial";

const VALUE_PRO_LITE = "advanced";
const LABEL_PRO_LITE = "Pro Lite";

const VALUE_PRO = "premium";
const LABEL_PRO = "Pro";

const WEEKLY = "weekly";
const MONTHLY = "monthly";
const YEARLY = "yearly";

const productOption = (type, timeOption) => {
  const {value, label} = type;
  return {
    value: `${PREFIX}${SEP}${value}${SEP}${timeOption}`,
    label: `${label} ${startCase(timeOption)}`,
  };
};

const FREE_TYPE = {
  value: VALUE_FREE,
  label: LABEL_FREE,
};

const PAID_TYPES = [
  {
    value: VALUE_PRO_LITE,
    label: LABEL_PRO_LITE,
  },
  {
    value: VALUE_PRO,
    label: LABEL_PRO,
  },
];

const FREE_TIME_OPTIONS = [WEEKLY];
const PAID_TIME_OPTIONS = [MONTHLY, YEARLY];

const freeOptions = FREE_TIME_OPTIONS.map((timeOption) => productOption(FREE_TYPE, timeOption));

const paidOptions = PAID_TYPES.reduce((acc, type) => {
  acc.push(...PAID_TIME_OPTIONS.map((timeOption) => productOption(type, timeOption)));
  return acc;
}, []);

export const SUBSCRIPTION_OPTIONS = freeOptions.concat(paidOptions);

export const BOUGHT_ON_OPTIONS = [
  {
    value: "web-admin",
    label: "Web Admin",
  },
  {
    value: "web-app",
    label: "Web App",
  },
  {
    value: "android",
    label: "Android",
  },
  {
    value: "ios",
    label: "iOS",
  },
  {
    value: "wordpress",
    label: "Wordpress",
  },
  {
    value: "api",
    label: "API",
  },
];

export const SUBSCRIPTION_VALUE_MAPPINGS = {
  basic: "proLite",
  advanced: "proLite",
  premium: "pro",
  free: "free",
};

export const SUBSCRIPTION_LABEL_MAPPINGS = {
  free: LABEL_FREE,
  proLite: LABEL_PRO_LITE,
  pro: LABEL_PRO,
  // Old subscriptions
  basic: LABEL_PRO_LITE,
  advanced: LABEL_PRO_LITE,
  premium: LABEL_PRO,

  proPlus: "Pro Plus",
  premiumPlus: "Pro Plus",
};