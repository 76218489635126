import {cilArrowRight} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {CButton, CFormCheck, CTableDataCell, CTableRow} from "@coreui/react";
import React from "react";
import {Link} from "react-router-dom";
import classes from "./ProfilesTableRow.module.css";
import ProfilesTableRowUser from "./ProfilesTableRowUser/ProfilesTableRowUser";

const linkButtonStyle = {
  "--cui-btn-color": "#263238",
};

const ProfilesTableRow = ({
  subscriptionActive,
  subscriptionExpired,
  avatar,
  deselect,
  email,
  expirationDateFormatted,
  id,
  industry,
  lastAccessFormatted,
  name,
  numAssignments,
  phoneNumber,
  select,
  selected,
  subscriptionName,
}) => {
  const handleCheckboxCellClick = selected ? deselect : select;

  const handleCheckboxChange = (event) => (event.target.checked ? select : deselect)();

  const checkbox = (
    <CFormCheck style={{cursor: "pointer"}} checked={selected} onChange={handleCheckboxChange} />
  );

  const navLink = (
    <Link to={`/admin/profiles/${id}`}>
      <CButton variant="ghost" style={linkButtonStyle}>
        <CIcon icon={cilArrowRight} />
      </CButton>
    </Link>
  );

  const rowClasses = [classes.row, selected ? classes.selectedRow : ""].join(" ");

  const inactiveText = subscriptionActive ? "" : "Inactive";

  const expiredText = subscriptionExpired ? "Expired" : "";

  const subscriptionText = (() => {
    let text = subscriptionName;
    const labels = [inactiveText, expiredText].filter((label) => label);

    if (!labels.length) return text;

    const joinedLabels = labels.join(", ");

    return `${text} (${joinedLabels})`;
  })();

  return (
    <CTableRow align="middle" className={rowClasses}>
      <CTableDataCell
        className="px-3"
        style={{cursor: "pointer"}}
        onClick={handleCheckboxCellClick}
      >
        {checkbox}
      </CTableDataCell>
      <CTableDataCell>
        <ProfilesTableRowUser avatar={avatar} name={name} email={email} phoneNumber={phoneNumber} />
      </CTableDataCell>
      <CTableDataCell>{subscriptionText}</CTableDataCell>
      <CTableDataCell>{expirationDateFormatted}</CTableDataCell>
      <CTableDataCell>{lastAccessFormatted}</CTableDataCell>
      <CTableDataCell>{numAssignments}</CTableDataCell>
      <CTableDataCell>{industry}</CTableDataCell>
      <CTableDataCell className={classes.idCell}>{id}</CTableDataCell>
      <CTableDataCell>{navLink}</CTableDataCell>
    </CTableRow>
  );
};

ProfilesTableRow.defaultProps = {
  avatar: "",
  subscriptionName: "",
  lastAccessTimestamp: "",
  numAssignments: 0,
  industry: "",
  selected: false,
};

export default ProfilesTableRow;
