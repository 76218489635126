import React from "react";
import CenteredSpinner from "../../../components/CenteredSpinner/CenteredSpinner";
import Page from "../../../components/Page/Page";
import ProfilesView from "../../../components/ProfilesView/ProfilesView";
import useProfiles from "../../../hooks/useProfiles";

export const FILTERS = [
  {label: "Status", value: "subscriptionStatus"},
  {label: "Subscription", value: "subscriptionName"},
  {label: "Industry", value: "industryType"},
];

export const FILTER_LABELS = FILTERS.reduce((acc, {label, value}) => {
  acc[value] = label;
  return acc;
}, {});

const Profiles = ({onMobileNavOpen}) => {
  const {profiles, fetching, fetchProfiles} = useProfiles();

  if (!profiles || fetching) return <CenteredSpinner />;

  const filterOptionsSets = FILTERS.reduce((acc, {label, value}) => {
    acc[value] = new Set([label]);
    return acc;
  }, {});

  for (const profile of profiles)
    for (const {value} of FILTERS) filterOptionsSets[value].add(profile[value]);

  const filterOptions = Object.fromEntries(
    Object.entries(filterOptionsSets).map(([key, set]) => [
      key,
      Array.from(set)
        .filter((value) => value !== undefined)
        .sort((a, b) => {
          if (a === FILTER_LABELS[key]) return -1;
          return a.localeCompare(b);
        }),
    ])
  );

  return (
    <Page title="Profiles" onMobileNavOpen={onMobileNavOpen}>
      <ProfilesView
        profiles={profiles}
        fetchProfiles={fetchProfiles}
        filterOptions={filterOptions}
      />
    </Page>
  );
};

export default Profiles;
