import {
  cilArrowThickToBottom,
  cilBurn,
  cilCheckCircle,
  cilDelete,
  cilInfo,
  cilWarning,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CAvatar,
  CButton,
  CCard,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CRow,
  CSpinner,
  CToast,
  CToastBody,
  CToastClose,
} from "@coreui/react";
import moment from "moment";
import React, {useEffect, useState} from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import Flatpickr from "react-flatpickr";
import Page from "../../../components/Page/Page";
import {BOUGHT_ON_OPTIONS, SUBSCRIPTION_OPTIONS} from "../../../constants/subscriptions";
import {useToasterContext} from "../../../context/ToasterContext/ToasterContext";
import {submitExcel, submitUserApiCall} from "../../../Controllers/ImportUsers/ImportUsersApi";
import useIndustryTypeOptions from "../../../hooks/useIndustryTypeOptions";
import useProfiles from "../../../hooks/useProfiles";
import {conditionalClass} from "../../../utils/css-utils";
import classes from "./ImportUsers.module.css";

const ALLOWED_MIME =
  "text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";

const MAIN_CCARD_STYLE = {
  backgroundColor: "white",
  paddingRight: "3rem",
  paddingLeft: "3rem",
  paddingBottom: "2rem",
  paddingTop: "2rem",
  borderRadius: "5px",
};

const ALERT_ICONS = {
  success: cilCheckCircle,
  warning: cilWarning,
  danger: cilBurn,
  info: cilInfo,
};

const DROPZONE_STYLES = {
  dropzoneReject: {
    borderColor: "red",
    backgroundColor: "#daa",
  },
  dropzone: {
    backgroundColor: "#fafafa",
    border: "1px dashed #9c9c9c",
    height: "425px",
  },
  inputLabel: (files, extra) =>
    extra.reject
      ? {color: "red", fontSize: "16px"}
      : {
          color: "#5b5b5b",
          fontWeight: "normal",
          fontSize: "16px",
        },
};

const EXPIRATION_DATE_PLACEHOLDERS = {
  weekly: "In a week",
  monthly: "In a month",
  yearly: "In a year",
};

const ADD_USER_FORM_DEFAULT = {
  email: "",
  fullName: "",
  industryType: "",
  productId: SUBSCRIPTION_OPTIONS[0].value,
  boughtOn: BOUGHT_ON_OPTIONS[0].value,
  expirationDate: "",
};

const ImportUsers = (props) => {
  const {onMobileNavOpen} = props;

  const {fetchProfiles} = useProfiles();
  const {fetchingIndustryTypeOptions, mappedIndustryTypeOptions} = useIndustryTypeOptions({
    includeEmptyOption: false,
  });

  const {addToast} = useToasterContext();

  useEffect(() => {
    if (!fetchingIndustryTypeOptions)
      setAddUserForm({...addUserForm, industryType: mappedIndustryTypeOptions[0].value});
  }, [fetchingIndustryTypeOptions]);

  const [addUserForm, setAddUserForm] = useState(ADD_USER_FORM_DEFAULT);
  const [errorList, setErrorList] = useState([]);
  const [fieldError, setFieldError] = useState([]);
  const [submittingAddUser, setSubmittingAddUser] = useState(false);

  const sendExcelFile = (files) => {
    setErrorList([]);

    files.map(async (file) => {
      const {error, invalidDate, message, success} = await submitExcel(file.file);

      if (!success) {
        addToast({message: error, color: "danger"});
        file.remove();
        return;
      }

      if (invalidDate.length) {
        addToast({message, color: "danger"});
        setErrorList(invalidDate);
      } else addToast({message, color: "success"});

      file.remove();
    });
  };

  const validateAddUserForm = () => {
    const errors = {};

    const {email, fullName, industryType, productId, boughtOn} = addUserForm;

    if (!email) errors.email = "Email is required";
    if (!fullName) errors.fullName = "Name is required";
    if (!industryType) errors.industryType = "Industry Type is required";
    if (!productId) errors.productId = "Type of Subscription is required";
    if (!boughtOn) errors.boughtOn = "Bought On is required";

    setFieldError(errors);

    return !Object.keys(errors).length;
  };

  const submitUser = async (event) => {
    event.preventDefault();

    if (!validateAddUserForm()) return;

    // The date is sent to the API in the MM/DD/YYYY format ¯\_(ツ)_/¯
    const expirationDateFormatted = moment(addUserForm.expirationDate.toString()).format(
      "MM/DD/YYYY"
    );
    const formToSubmit = {...addUserForm, expirationDate: expirationDateFormatted};

    setSubmittingAddUser(true);

    const {data, error, message, success} = await submitUserApiCall(formToSubmit);

    if (success) {
      void fetchProfiles();
      // API returns false success

      const toast = data.error
        ? {message: data.error.message, color: "danger"}
        : {message: message || "User created.", color: "success"};
      addToast(toast);
    } else addToast({message: message || error, color: "danger"});

    setSubmittingAddUser(false);
  };

  const updateField = (id, value) =>
    setAddUserForm((prevUserForm) => ({...prevUserForm, [id]: value}));

  const handleFieldChange = ({target: {id, value}}) => {
    updateField(id, value);
    if (value && fieldError[id]) setFieldError((prevFieldError) => ({...prevFieldError, [id]: ""}));
  };

  const resetAddUserForm = () => {
    setAddUserForm(ADD_USER_FORM_DEFAULT);
    setFieldError([]);
  };

  const downloadTemplate = async () => {
    window.open(
      `https://uscope.s3.amazonaws.com/prod/PHOTO_ID_create_users_template.xlsx?${Math.random()}`
    );
  };

  const createToast = (severity, message) => {
    const actualSeverity = severity === "error" ? "danger" : severity;

    const icon = ALERT_ICONS[actualSeverity] || ALERT_ICONS.info;

    return (
      <CToast delay={6000} color={actualSeverity} className="align-items-center text-white">
        <div className="d-flex">
          <CToastBody className="d-flex justify-content-center align-items-center">
            <CIcon icon={icon} />
            <div className="mx-1" />
            <span>{message}</span>
          </CToastBody>
          <CToastClose className="me-2 m-auto" white />
        </div>
      </CToast>
    );
  };

  const emailFormElement = (
    <div>
      <CFormInput
        id="email"
        label="Email"
        type="email"
        value={addUserForm.email || ""}
        onChange={handleFieldChange}
        invalid={!!fieldError?.email}
        feedbackInvalid={fieldError.email}
      />
    </div>
  );

  const nameFormElement = (
    <div>
      <CFormInput
        id="fullName"
        label="Name"
        value={addUserForm.fullName || ""}
        onChange={handleFieldChange}
        invalid={!!fieldError?.fullName}
        feedbackInvalid={fieldError.fullName}
      />
    </div>
  );

  const industryTypeFormElement = (
    <div>
      {/*<CFormInput*/}
      {/*  id="industryType"*/}
      {/*  label="Industry Type"*/}
      {/*  value={addUserForm.industryType || ""}*/}
      {/*  onChange={handleFieldChange}*/}
      {/*  invalid={!!fieldError?.industryType}*/}
      {/*  feedbackInvalid={fieldError.industryType}*/}
      {/*/>*/}
      <CFormSelect
        id="industryType"
        label="Industry Type"
        name="industryType"
        value={addUserForm.industryType || ""}
        onChange={handleFieldChange}
        disabled={fetchingIndustryTypeOptions}
        placeholder={fetchingIndustryTypeOptions ? "Loading..." : ""}
        invalid={!!fieldError?.industryType}
        feedbackInvalid={fieldError.industryType}
        options={mappedIndustryTypeOptions}
      />
    </div>
  );

  const subscriptionTypeFormElement = (
    <div>
      <CFormSelect
        id="productId"
        value={addUserForm.productId || ""}
        label="Subscription Type"
        onChange={handleFieldChange}
      >
        {SUBSCRIPTION_OPTIONS.map(({value, label}) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </CFormSelect>
    </div>
  );

  const boughtOnFormElement = (
    <div>
      <CFormSelect
        id="boughtOn"
        value={addUserForm.boughtOn || ""}
        label="Bought On"
        onChange={handleFieldChange}
      >
        {BOUGHT_ON_OPTIONS.map(({value, label}) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </CFormSelect>
    </div>
  );

  const subscriptionDuration = addUserForm.productId?.split(".")[2];
  const expirationDatePlaceholder = EXPIRATION_DATE_PLACEHOLDERS[subscriptionDuration] || "";

  const expirationDateFormElement = (
    <div>
      <Flatpickr
        id="expirationDate"
        options={{dateFormat: "m/d/Y"}}
        value={addUserForm.expirationDate || ""}
        onChange={(date) => updateField("expirationDate", date)}
        render={({value, onChange}, ref) => (
          <div className="position-relative">
            <CFormInput
              label="Expiration Date"
              name="expirationDate"
              ref={ref}
              placeholder={expirationDatePlaceholder}
              text={
                !addUserForm.expirationDate &&
                "As no date is provided, the subscription type's duration will be used."
              }
            />
            <button
              disabled={!value}
              type="button"
              className={classes.clearFieldButton}
              onClick={() => updateField("expirationDate", "")}
            >
              <CIcon icon={cilDelete} size="lg" />
            </button>
          </div>
        )}
      />
    </div>
  );

  const managerFormElement = (
    <div>
      <CFormInput
        id="manager"
        label="Manager (optional)"
        value={addUserForm.manager || ""}
        onChange={handleFieldChange}
      />
    </div>
  );

  const addUserFormSubmitButton = (
    <CButton
      color="primary"
      className={classes.submitButton}
      type="submit"
      disabled={submittingAddUser}
    >
      <div className={conditionalClass(submittingAddUser, classes.noHeight)}>Submit</div>
      <div className={conditionalClass(!submittingAddUser, classes.noHeight)}>
        <CSpinner size="sm" />
      </div>
    </CButton>
  );

  const addUserFormClearButton = (
    <CButton
      color="light"
      className={classes.clearButton}
      type="reset"
      disabled={submittingAddUser}
    >
      Clear
    </CButton>
  );

  const addManuallyColButtonGroup = (
    <div className="d-flex justify-content-center m-4">
      {addUserFormSubmitButton}
      {addUserFormClearButton}
    </div>
  );

  const addManuallyForm = (
    <CForm className="d-flex flex-column gap-3" onSubmit={submitUser} onReset={resetAddUserForm}>
      {emailFormElement}
      {nameFormElement}
      {industryTypeFormElement}
      {subscriptionTypeFormElement}
      {boughtOnFormElement}
      {expirationDateFormElement}
      {managerFormElement}
      {addManuallyColButtonGroup}
    </CForm>
  );

  const addManuallyCol = (
    <CCol xs={12} lg={5}>
      <p className={classes.smallTitle}>Import new users</p>
      <p className={classes.title}>Add users manually</p>
      {addManuallyForm}
    </CCol>
  );

  const uploadFileCol = (
    <CCol xs={12} lg={7}>
      <CCard className={classes.uploadFileCard}>
        <CContainer className="d-flex justify-content-center">
          <CRow>
            <CCol md={6} xs={12}>
              <div className="d-flex justify-content-center">
                <p className={classes.title}>Upload file with multiple users</p>
              </div>
            </CCol>
            <CCol md={6} xs={12}>
              <div className="d-flex justify-content-center">
                <CButton
                  color="primary"
                  style={{
                    backgroundColor: "#212121",
                  }}
                  onClick={downloadTemplate}
                >
                  <span>
                    <CIcon icon={cilArrowThickToBottom} className="me-2" />
                    Download Template
                  </span>
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CContainer>
        <CContainer className="my-2">
          <CRow>
            <CCol xs={12} md={6}>
              <div className="d-flex justify-content-center align-items-center lh-sm">
                <CAvatar
                  color="primary"
                  textColor="white"
                  size="sm"
                  style={{padding: "0px 10px"}}
                  className="m-1"
                >
                  1
                </CAvatar>
                <span>Download the template</span>
              </div>
            </CCol>
            <CCol xs={12} md={6}>
              <div className="d-flex justify-content-center align-items-center lh-sm">
                <CAvatar
                  color="primary"
                  textColor="white"
                  size="sm"
                  style={{padding: "0px 10px"}}
                  className="m-1"
                >
                  2
                </CAvatar>
                <span>Fill the template and import the file</span>
              </div>
            </CCol>
          </CRow>
        </CContainer>
        <CContainer className="my-2">
          <Dropzone
            accept={ALLOWED_MIME}
            maxFiles={1}
            onSubmit={sendExcelFile}
            inputContent={(files, extra) =>
              extra.reject
                ? "CSV and Excel files only"
                : "Drag 'n' drop some files here, or click to select files"
            }
            styles={DROPZONE_STYLES}
          />
        </CContainer>
        {errorList.length ? (
          <CContainer className="mt-4">
            <CContainer className="mb-2 ps-2" mb={2} paddingLeft={2}>
              The following lines contain invalid dates. Please correct them and try again.
            </CContainer>
            <CContainer className="ps-4" paddingLeft={4}>
              <ul>
                {errorList.map((error, idx) => (
                  <>
                    <li key={idx}>{error}</li>
                    <br />
                  </>
                ))}
              </ul>
            </CContainer>
          </CContainer>
        ) : null}
      </CCard>
    </CCol>
  );

  const pageContent = (
    <CCard style={MAIN_CCARD_STYLE}>
      <CContainer className="mt-4">
        <CRow>
          {addManuallyCol}
          {uploadFileCol}
        </CRow>
      </CContainer>
    </CCard>
  );

  return (
    <Page title="Import Users" onMobileNavOpen={onMobileNavOpen}>
      {pageContent}
    </Page>
  );
};
export default ImportUsers;
