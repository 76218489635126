import "flatpickr/dist/themes/airbnb.css";
import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import CenteredSpinner from "./components/CenteredSpinner/CenteredSpinner";
import {ToasterContextProvider} from "./context/ToasterContext/ToasterContext";
import {UserContext} from "./context/userContext/userContext";
import "./index.css";
import Routes from "./Routes";
import firebase from "./service/firebase";
import "./style.css";
import "./style.css.map";
import {getAuthToken, getAuthTokenDecoded} from "./utils/auth-utils";
import {useComponentWillMount} from "./utils/utils";
import {NAV_ROUTES, OTHER_ROUTES} from "./views/Admin/routes";

const App = () => {
  const history = useHistory();
  const location = useLocation();

  const [fetchingUserProfile, setFetchingUserProfile] = useState(false);
  const [user, setUser] = useState({});

  const {
    SIGN_IN: {path: signInPath},
  } = OTHER_ROUTES;

  const redirectToSignInIfNotLoggedIn = () => {
    if (!getAuthToken() && window.location.pathname !== signInPath) history.push(signInPath);
  };

  useEffect(redirectToSignInIfNotLoggedIn, [window.location.pathname]);

  const init = async () => {
    const token = getAuthTokenDecoded();

    if (!token) return;

    setFetchingUserProfile(true);

    const {success, message, data: profile} = await firebase.getProfile(token);

    if (!success) {
      console.error(message);
      await firebase.logout();
      history.push(signInPath);
      return;
    }

    setUser({
      ...profile,
      isAdmin: profile.admin,
    });

    setFetchingUserProfile(false);

    const firstRoutePath = profile.admin ? NAV_ROUTES.PROFILES.path : NAV_ROUTES.MY_USERS.path;

    if (["/", signInPath].includes(location.pathname)) history.push(firstRoutePath);
  };

  useComponentWillMount(init);

  if (fetchingUserProfile) return <CenteredSpinner />;

  const loggedIn = !!user.id;

  return (
    <ToasterContextProvider>
      <UserContext.Provider value={{user, setUser}}>
        <Routes loggedIn={loggedIn} />
      </UserContext.Provider>
    </ToasterContextProvider>
  );
};

export default App;
