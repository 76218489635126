import {configureStore} from "@reduxjs/toolkit";
import needsAttentionReducer from "../slices/needsAttentionSlice";
import profilesReducer from "../slices/profilesSlice";
import subscriptionsReducer from "../slices/subscriptionsSlice";
import staticReducer from "../slices/staticSlice";

const store = configureStore({
  reducer: {
    profiles: profilesReducer,
    subscriptions: subscriptionsReducer,
    needsAttention: needsAttentionReducer,
    static: staticReducer,
  },
});

export default store;
