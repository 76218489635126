const CATEGORIES = {
  ASSIGNMENTS: "assignments",
  REPORTS: "reports",
  CAMERA: "camera",
  OTHERS: "others",
  PAGES: "pages",
};

export const PERMISSIONS_INFO = {
  assignments: {
    name: "Unlimited Assignments",
    description: "Limit number of assignments; unlimited if true",
    numeric: true,
    category: CATEGORIES.ASSIGNMENTS,
    nouns: {
      singular: "assignment",
      plural: "assignments",
    },
  },
  photos: {
    name: "Unlimited Photos",
    description: "Limit number of pictures; unlimited if true",
    numeric: true,
    category: CATEGORIES.ASSIGNMENTS,
    nouns: {
      singular: "photo",
      plural: "photos",
    },
  },
  cloudStorage: {
    name: "Unlimited Cloud Storage",
    description: "Possibility to move assignments to the cloud",
    category: CATEGORIES.ASSIGNMENTS,
    nouns: {
      singular: "assignment",
      plural: "assignments",
    },
  },
  shareableAssignmentLink: {
    name: "Shareable Assignment Link",
    description: "Share the assignment link",
    category: CATEGORIES.ASSIGNMENTS,
  },
  preCategorizedGroupedImages: {
    name: "Pre-categorized Grouped Images",
    description: "",
    category: CATEGORIES.ASSIGNMENTS,
  },
  cloneAssignments: {
    name: "Clone Assignments",
    description: "",
    category: CATEGORIES.ASSIGNMENTS,
  },
  galleryToolkitAccess: {
    name: "Gallery Toolkit Access",
    description: "Gallery Toolkit access",
    category: CATEGORIES.ASSIGNMENTS,
  },
  imageEditorAccess: {
    name: "Image Editor Access",
    description: "Image Editor access",
    category: CATEGORIES.ASSIGNMENTS,
  },
  instructionsAccess: {
    name: "Automated Guidance",
    description: "Automated Guidance access",
    category: CATEGORIES.CAMERA,
  },
  photoReports: {
    name: "Unlimited Photo Reports",
    description: "",
    numeric: true,
    category: CATEGORIES.REPORTS,
    nouns: {
      singular: "photo report",
      plural: "photo reports",
    },
  },
  customCompanyReportHeader: {
    name: "Custom Company Report Header",
    description: "",
    category: CATEGORIES.REPORTS,
  },
  customReportBuilder: {
    name: "Custom Report Builder",
    description: "",
    category: CATEGORIES.REPORTS,
  },
  customInspectionTemplates: {
    name: "Custom Inspection Templates",
    description: "",
    category: CATEGORIES.REPORTS,
  },
  inCameraRoofPitchGauge: {
    name: "In-camera Roof Pitch Gauge",
    description: "Roof Pitch Gauge access",
    category: CATEGORIES.CAMERA,
  },
  inCameraCompass: {
    name: "In-camera Compass",
    description: "Compass access",
    category: CATEGORIES.CAMERA,
  },
  videoRecording: {
    name: "Video Recording",
    description: "Possibility to record videos",
    category: CATEGORIES.CAMERA,
  },
  realtimeImageCaptionLabeling: {
    name: "Real-time Image Caption Labeling",
    description: "Speech to Text feature",
    category: CATEGORIES.CAMERA,
  },
  remoteCapture: {
    name: "Remote Capture (Visual Inspections)",
    description: "Remote Capture inspections",
    category: CATEGORIES.CAMERA,
  },
  securityAccess: {
    name: "Security Access",
    description: "Security page access",
    category: CATEGORIES.PAGES,
  },
  myUsersAccess: {
    name: "My Users Access",
    description: "My Users redirect access",
    category: CATEGORIES.PAGES,
  },
  importAccess: {
    name: "Import Access",
    description: "Import Users page access",
    category: CATEGORIES.PAGES,
  },
  settingsAccess: {
    name: "Settings Access",
    description: "Settings page access",
    category: CATEGORIES.PAGES,
  },
  sketchMeasurementReportAccess: {
    name: "Sketch Measurement Report Access",
    description: "Sketch Measurement Report access",
  },
  webAppAccess: {
    name: "Web App Access",
    description: "Possibility to also access Web App or Mobile App only",
  },
  APIAccess: {
    name: "API Access",
    description: "",
  },
  inspectionTasksReminder: {
    name: "Inspection Tasks Reminder",
    description: "",
  },
  teamCollaboration: {
    name: "Team Collaboration with PRO users",
    description: "",
  },
  onboarding: {
    name: "Onboarding",
    description: "",
  },
  prioritySupport: {
    name: "Priority Support",
    description: "",
  },
  platformCustomizations: {
    name: "Platform Customizations",
    description: "",
  },
};

export const CATEGORY_ORDERS = {
  assignments: 1,
  reports: 2,
  camera: 3,
  pages: 4,
  others: 5,
};

export const getPermissionCategory = (key) => PERMISSIONS_INFO[key]?.category || "others";
