import PropTypes from "prop-types";
import React from "react";
import {conditionalClass} from "../../utils/css-utils";
import classes from "./MultiIconToggle.module.css";

const MultiIconToggle = ({options, optionOnClick, optionIsSelected}) => {
  const optionElements = options.map((option) => {
    const optionClassName = [
      classes.option,
      conditionalClass(optionIsSelected(option), classes.optionSelected),
    ].join(" ");

    const onClick = (event) => {
      event.preventDefault();
      optionOnClick(option);
    };

    return (
      <button className={optionClassName} onClick={onClick} key={option.id}>
        <div className={classes.icon}>{option.icon}</div>
      </button>
    );
  });

  return <div className={classes.toggle}>{optionElements}</div>;
};

MultiIconToggle.propTypes = {
  options: PropTypes.array.isRequired,
  optionOnClick: PropTypes.func.isRequired,
  optionIsSelected: PropTypes.func.isRequired,
};

export default MultiIconToggle;
