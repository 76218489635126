import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  setNeedsAttentionProfiles,
  startFetchingNeedsAttentionProfiles,
} from "../redux/slices/needsAttentionSlice";
import firebase from "../service/firebase";

const useNeedsAttentionProfiles = () => {
  const dispatch = useDispatch();
  const profiles = useSelector((state) => state.needsAttention.profiles);
  const fetchingProfiles = useSelector((state) => state.needsAttention.fetchingProfiles);

  const fetchProfiles = useCallback(async () => {
    dispatch(startFetchingNeedsAttentionProfiles());

    const {success, data: profiles, message} = await firebase.getNeedsAttentionProfiles();

    if (!success) {
      console.error(message);
      return;
    }

    dispatch(setNeedsAttentionProfiles({profiles}));
  }, [dispatch]);

  useEffect(() => {
    if (!profiles) void fetchProfiles();
  }, [profiles, fetchProfiles]);

  return {profiles, fetchProfiles, fetching: fetchingProfiles};
};

export default useNeedsAttentionProfiles;
