import {CButton, CContainer, CForm, CFormInput, CSpinner} from "@coreui/react";
import {yupResolver} from "@hookform/resolvers";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import * as Yup from "yup";
import bglogin from "../../assets/bgLogin.png";
import useAppRoutes from "../../hooks/useAppRoutes";
import {useUserContext} from "../../hooks/useUserContext";
import firebase from "../../service/firebase";

const styles = {
  root: {
    background: "url(" + bglogin + ")",
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
  },
  inputContainer: {
    backgroundColor: "#fff",
    maxWidth: "500px",
    minHeight: "200px",
    width: "95vw",
    padding: "16px",
    borderRadius: "10px",
  },
  backDrop: {
    // zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
};

const schema = Yup.object().shape({
  email: Yup.string().email("Please enter a valid email address.").required(),
  password: Yup.string().required(),
});

const SignIn = () => {
  const history = useHistory();
  const [error, setError] = useState("");
  const {register, handleSubmit, errors} = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });
  const [logging, setLogging] = useState(false);
  const {firstVisibleRoute} = useAppRoutes();
  const {setUser, user} = useUserContext();

  useEffect(() => {
    if (user) history.push(firstVisibleRoute.path);
  }, [user]);

  const handleLogin = async ({email, password}) => {
    setLogging(true);
    try {
      const {
        data: profile,
        message: errorMessage,
        success,
      } = await firebase.customLogin(email, password);
      if (success) {
        const {id, admin: isAdmin} = profile;

        const token = new Buffer(id).toString("base64");
        localStorage.setItem("token", token);

        setUser({...profile, isAdmin});

        // TODO: Find out why firstVisibleRoute is returning "My Users"
        // firstVisibleRoute is not considering the context change (useUserContext hook)

        // history.push(firstVisibleRoute.path);
      } else {
        const message =
          errorMessage === "NOT OK"
            ? "The username or password you entered is incorrect."
            : errorMessage;
        setError(message);
      }
    } catch (err) {
      console.log(err);
    }
    setLogging(false);
  };

  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center flex-column"
        style={styles.root}
      >
        <CForm onSubmit={handleSubmit(handleLogin)}>
          <CContainer
            className="d-flex justify-content-center flex-column"
            style={styles.inputContainer}
          >
            <CFormInput
              type="email"
              name="email"
              label="Email"
              invalid={!!errors.email}
              feedbackInvalid={errors.email && errors.email.message}
              ref={register}
            />
            <CFormInput
              type="password"
              name="password"
              label="Password"
              invalid={!!errors.password}
              feedbackInvalid={errors.password && errors.password.message}
              ref={register}
            />
            {error && (
              <p className="text-danger" style={{marginTop: "10px"}}>
                {error}
              </p>
            )}
          </CContainer>
          <CContainer className="d-flex justify-content-center m-2">
            <CButton type="submit" color="secondary">
              Sign In
            </CButton>
          </CContainer>
        </CForm>
      </div>
      {logging && (
        <>
          <div className="modal-backdrop opacity-50" style={styles.backDrop}></div>
          <div className="position-absolute top-50 start-50 translate-middle">
            <CSpinner color="primary" />
          </div>
        </>
      )}
    </>
  );
};

export default SignIn;
