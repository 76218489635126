import React from "react";
import {
  CAlert,
  CButton,
  CFormInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import useBulkManagerAssignmentForm from "../../hooks/useBulkManagerAssignmentForm";
import useToaster from "../../hooks/useToaster";
import firebase from "../../service/firebase";
import ProfilesReviewTable from "../ProfilesReviewTable/ProfilesReviewTable";

const profileFields = new Set(["profileInfo"]);

/**
 * @param visible {boolean}
 * @param onCancel {function}
 * @param profiles {object[]}
 * @returns {JSX.Element}
 * @constructor
 */
const BulkManagerAssignment = ({visible, onCancel, profiles}) => {
  const {addToast} = useToaster();

  const {
    handleSubmit,
    register,
    setError,
    formState: {errors, isSubmitting, isSubmitSuccessful},
  } = useBulkManagerAssignmentForm();

  const onSubmit = async ({managerEmail}) => {
    let {success, message} = await firebase.assignManagerToUsersByEmail(
      managerEmail,
      profiles.map(({id}) => id)
    );

    if (!success) setError("managerEmail", {message});
    else if (!message) message = "Successfully assigned manager to users.";

    addToast({message, color: success ? "success" : "danger"});
  };

  const managerEmailError = errors.managerEmail?.message;

  const managerEmailInputEl = (
    <CFormInput
      type="text"
      name="managerEmail"
      ref={register}
      label="Manager email address"
      invalid={!!managerEmailError}
      text={managerEmailError}
      autoFocus
    />
  );

  const successAlertEl = isSubmitSuccessful && (
    <CAlert color="success" style={{textAlign: "justify"}}>
      Successfully assigned manager to users. You may assign additional managers or close this
      dialog.
    </CAlert>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CModal visible={visible} alignment="center" onClose={onCancel}>
        <CModalHeader>
          <CModalTitle>Bulk Manager Assignment</CModalTitle>
        </CModalHeader>
        <CModalBody className="vstack gap-2">
          <div>You are assigning the manager to the following users:</div>
          <div className="overflow-auto">
            <ProfilesReviewTable profiles={profiles} fields={profileFields} />
          </div>
          {managerEmailInputEl}
          <div className="mt-1">{successAlertEl}</div>
        </CModalBody>
        <CModalFooter>
          <CButton onClick={onCancel} variant="outline" disabled={isSubmitting}>
            Cancel
          </CButton>
          <CButton type="submit" onClick={handleSubmit(onSubmit)} disabled={isSubmitting}>
            Submit
          </CButton>
        </CModalFooter>
      </CModal>
    </form>
  );
};

export default BulkManagerAssignment;
