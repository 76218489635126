import {CTable, CTableBody} from "@coreui/react";
import React from "react";
import BulkEditReviewProfilesTableRow from "../BulkEdit/BulkEditReview/ BulkEditReviewProfilesTableRow/BulkEditReviewProfilesTableRow";
import classes from "./ProfilesReviewTable.module.css";

/**
 * Renders a table of profiles to be reviewed before applying bulk changes.
 * @returns {JSX.Element}
 */
const ProfilesReviewTable = ({profiles, fields}) => {
  const profilesTableBody = (
    <CTableBody>
      {profiles.map((profile) => (
        <BulkEditReviewProfilesTableRow
          fields={fields}
          avatar={profile.avatar}
          fullName={profile.fullName}
          email={profile.email}
          phoneNumber={profile.phone}
          subscriptionName={profile.subscriptionName}
          expirationDateFormatted={profile.expirationDateFormatted}
          lastAccessFormatted={profile.lastAccessFormatted}
          numAssignments={profile.numAssignments}
          industry={profile.industryType}
          id={profile.id}
          key={profile.id}
        />
      ))}
    </CTableBody>
  );

  return (
    <div className={classes.profilesTable}>
      <CTable>{profilesTableBody}</CTable>
    </div>
  );
};

export default ProfilesReviewTable;
