import React from "react";
import CenteredSpinner from "../../../components/CenteredSpinner/CenteredSpinner";
import Page from "../../../components/Page/Page";
import LinkTable from "../../../components/Table/LinkTable";
import useSubscriptions from "../../../hooks/useSubscriptions";

const Subscriptions = ({onMobileNavOpen}) => {
  const {subscriptions, fetching} = useSubscriptions();

  if (!subscriptions || fetching) return <CenteredSpinner />;

  const headCells = [
    {id: "email", label: "Email"},
    {id: "productId", label: "Type"},
    {id: "boughtOn", label: "Bought On"},
    {id: "expirationDate", label: "Expiration Date"},
    {id: "id", label: "Profile ID"},
  ];

  const dataCells = [
    {dataKey: "email", noPadding: true},
    {dataKey: "productId"},
    {dataKey: "boughtOn"},
    {dataKey: "expirationDate", type: "date"},
    {dataKey: "id"},
  ];

  const makePath = (row) => (row.id ? `/admin/profiles/${row.id}` : "#");

  return (
    <Page title="Subscriptions" onMobileNavOpen={onMobileNavOpen}>
      <LinkTable
        linkLabel="Visit Profile"
        data={subscriptions}
        headCells={headCells}
        dataCells={dataCells}
        makePath={makePath}
      />
    </Page>
  );
};

export default Subscriptions;
