import React from "react";
import PropTypes from "prop-types";
import {CForm, CFormSelect, CFormText} from "@coreui/react";

function Select(props) {
  const {label, value, name, options, handleChange, error, emptyOption, ...rest} = props;

  return (
    <CForm>
      <CFormSelect label={label} name={name} value={value} onChange={handleChange} {...rest}>
        {emptyOption && <option />}
        {options.map((item, idx) => (
          <option key={idx} value={item.value}>
            {item.label}
          </option>
        ))}
      </CFormSelect>
      <CFormText>{error}</CFormText>
    </CForm>
  );
}

export default Select;

Select.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.object,
  emptyOption: PropTypes.bool,
};

Select.defaultProps = {
  emptyOption: false,
};
