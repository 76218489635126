import {CButton, CContainer} from "@coreui/react";
import React, {useState} from "react";
import AddUser from "../../../components/AddUser/AddUser";
import CenteredSpinner from "../../../components/CenteredSpinner/CenteredSpinner";
import Page from "../../../components/Page/Page";
import LinkTable from "../../../components/Table/LinkTable";
import useMyUsers from "../../../hooks/useMyUsers";

const HEAD_CELLS = [
  {id: "email", label: "Email"},
  {id: "fullName", label: "Full Name"},
  {id: "lastAccess", label: "Last Access"},
  {id: "signUpDate", label: "Register Date"},
  {id: "assignsList", label: "Nr. of Assignments"},
  {id: "industryType", label: "Industry Type"},
  {id: "id", label: "UID"},
];

const DATA_CELLS = [
  {dataKey: "email", noPadding: true},
  {dataKey: "fullName"},
  {dataKey: "lastAccess", type: "date"},
  {dataKey: "signUpDate", type: "date"},
  {dataKey: "assignsList", type: "amount"},
  {dataKey: "industryType"},
  {dataKey: "id"},
];

const MyUsers = ({onMobileNavOpen}) => {
  const {myUsers, fetching} = useMyUsers();

  const [openAddUser, setOpenAddUser] = useState(false);

  if (!myUsers || fetching) return <CenteredSpinner />;

  const makePath = ({id}) => (id ? `/admin/profiles/${id}` : "#");

  return (
    <Page title="My Users" onMobileNavOpen={onMobileNavOpen}>
      <CContainer className="d-flex justify-content-end mb-2">
        <CButton color="primary" onClick={() => setOpenAddUser(true)}>
          Add User
        </CButton>
      </CContainer>
      <LinkTable
        linkLabel={"Visit Profile"}
        data={myUsers}
        dataCells={DATA_CELLS}
        headCells={HEAD_CELLS}
        makePath={makePath}
      />
      <AddUser open={openAddUser} handleClose={() => setOpenAddUser(false)} />
    </Page>
  );
};

export default MyUsers;
