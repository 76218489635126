import {
  cilAddressBook,
  cilBellExclamation,
  cilCog,
  cilCreditCard,
  cilFile,
  cilPeople,
  cilScreenDesktop,
  cilShieldAlt,
  cilUserPlus,
} from "@coreui/icons";
import {ENV} from "../../env";
import SignIn from "../SignIn/SignIn";
import Admin from "./Admin";
import ImportUsers from "./ImportUsers/ImportUsers";
import Managers from "./Managers/Managers";
import MyUsers from "./MyUsers/MyUsers";
import NeedsAttentionProfiles from "./NeedsAttentionProfiles/NeedsAttentionProfiles";
import Profiles from "./Profiles/Profiles";
import RepairProfile from "./RepairProfile/RepairProfile";
import Reports from "./Reports/Reports";
import Subscriptions from "./Subscriptions/Subscriptions";

export const OTHER_ROUTES = {
  SIGN_IN: {
    name: "signIn",
    path: "/signIn",
    component: SignIn,
  },
  ADMIN: {
    name: "/admin",
    path: "/admin",
    component: Admin,
  },
};

export const NAV_ROUTES = {
  PROFILES: {
    name: "profiles",
    path: "/admin/profiles",
    icon: cilPeople,
    title: "Profiles",
    adminOnly: true,
    shownInNav: true,
    component: Profiles,
  },
  SUBSCRIPTIONS: {
    name: "subscriptions",
    path: "/admin/subscriptions",
    icon: cilCreditCard,
    title: "Subscriptions",
    adminOnly: true,
    shownInNav: true,
    component: Subscriptions,
  },
  MY_USERS: {
    name: "myUsers",
    path: "/admin/myUsers",
    icon: cilAddressBook,
    title: "My Users",
    adminOnly: false,
    shownInNav: true,
    component: MyUsers,
  },
  MANAGERS: {
    name: "managers",
    path: "/admin/managers",
    icon: cilShieldAlt,
    title: "Managers",
    adminOnly: true,
    shownInNav: true,
    component: Managers,
  },
  IMPORT_USERS: {
    name: "importUsers",
    path: "/admin/importUsers",
    icon: cilUserPlus,
    title: "Import Users",
    adminOnly: true,
    shownInNav: true,
    component: ImportUsers,
  },
  NEEDS_ATTENTION_PROFILES: {
    name: "needsAttentionProfiles",
    path: "/admin/needsAttentionProfiles",
    icon: cilBellExclamation,
    title: "User Issues",
    adminOnly: true,
    shownInNav: true,
    component: NeedsAttentionProfiles,
  },
  REPORTS: {
    name: "reports",
    path: "/admin/reports",
    icon: cilFile,
    title: "Reports",
    adminOnly: true,
    shownInNav: true,
    component: Reports,
  },
  REPAIR_PROFILE: {
    name: "repairProfile",
    path: "/admin/repairProfiles",
    icon: cilCog,
    title: "Repair Profile",
    shownInNav: true,
    component: RepairProfile,
  },
  PROFILE: {
    name: "profile",
    path: "/admin/profiles/:profileId",
    shownInNav: false,
    title: "Profiles",
  },
  WEB_APP: {
    name: "webApp",
    href: ENV.WEB_APP_URL,
    icon: cilScreenDesktop,
    shownInNav: true,
    title: "My WebApp",
  },
};
