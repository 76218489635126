import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import {ENV, validateEnvVars} from "./env";
import store from "./redux/store/store";
import * as serviceWorker from "./serviceWorker";

export const dbEnv = (() => {
  switch (ENV.SERVER_URL) {
    case "https://api-dev.photoidapp.net":
      return "dev";
    case "https://api.photoidapp.net":
      return "prod";
    default:
      return `? (${ENV.SERVER_URL})`;
  }
})();

export const authEnv = (() => {
  switch (ENV.FIREBASE_AUTH_DOMAIN) {
    case "uscope-photoid-dev.firebaseapp.com":
      return "dev";
    case "u-scope-photoid.firebaseapp.com":
      return "prod";
    default:
      return `? (${ENV.FIREBASE_AUTH_DOMAIN})`;
  }
})();

if (ENV.NODE_ENV === "development") validateEnvVars();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
