import {cilUser} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {CAvatar} from "@coreui/react";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {strJoin} from "../../utils/css-utils";
import classes from "./OptionalAvatar.module.css";

const OptionalAvatar = ({icon, avatarSize, src, className, style, initials, initialsClassName}) => {
  const [forceInitials, setForceInitials] = useState(false);

  const useImg = src && !forceInitials;

  const imgOnError = () => setForceInitials(true);

  if (useImg)
    return (
      <img
        className={strJoin(classes.image, className)}
        src={src}
        style={style}
        alt="Avatar"
        onError={imgOnError}
      />
    );

  const avatarContent = initials ? (
    <span className={initialsClassName}>{initials}</span>
  ) : (
    <CIcon icon={icon} size={avatarSize} />
  );

  return (
    <CAvatar color="secondary" className={className} style={style}>
      {avatarContent}
    </CAvatar>
  );
};

export default OptionalAvatar;

OptionalAvatar.propTypes = {
  icon: PropTypes.any,
  src: PropTypes.string,
  className: PropTypes.string,
  initials: PropTypes.string,
};

OptionalAvatar.defaultProps = {
  icon: cilUser,
  avatarSize: "3xl",
  className: "",
  initials: "",
  initialsClassName: "",
};
