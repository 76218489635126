/**
 * Based on the address components, create the complete address.
 * @param address1 {string} Street
 * @param address2 {string} Number
 * @param city {string} City
 * @param state {string} State
 * @param zipCode {string} ZIP code
 * @returns {string} Complete address
 */
export const getFullAssignmentAddress = ({
  claimInsuredAddress1: address1,
  claimInsuredAddress2: address2,
  claimInsuredCity: city,
  claimInsuredState: state,
  claimInsuredZipCode: zipCode,
}) => {
  const firstPart = [address1, address2].filter(Boolean).join(" ");

  if (state) {
    const stateAndZipCode = [state, zipCode].filter(Boolean).join(" ");
    return [firstPart, city || "", stateAndZipCode].filter(Boolean).join(", ");
  }

  // If the address doesn't have a state, the ZIP code should be after the city.
  const cityAndZipCode = [city, zipCode].filter(Boolean).join(" ");
  return [firstPart, cityAndZipCode].filter(Boolean).join(", ");
};

export const getNumImagesInCategory = (category) => {
  const {images, categories} = category;

  const numCategoryImages = images?.length || 0;

  const childCategories = Object.values(categories || {});

  const numChildCategoryImages = childCategories.reduce(
    (acc, cur) => acc + getNumImagesInCategory(cur),
    0
  );

  return numCategoryImages + numChildCategoryImages;
};

/**
 * Get the number of images in an assignment.
 * @param assignment {object} Assignment object
 */
export const getNumImagesInAssignment = (assignment) => {
  const {structures} = assignment;
  return getNumImagesInCategory(structures || {});
};
