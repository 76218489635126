import {cilGrid, cilList, cilOptions, cilPencil, cilSearch, cilTrash} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CForm,
  CFormCheck,
  CFormInput,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CPopover,
} from "@coreui/react";
import _ from "lodash";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {DESC} from "../../constants/sorting";
import firebase from "../../service/firebase";
import {paginated} from "../../utils/pagination-utils";
import {setWith, setWithout} from "../../utils/set-utils";
import {FILTER_LABELS, FILTERS} from "../../views/Admin/Profiles/Profiles";
import BulkEditWrapper from "../BulkEdit/BulkEditWrapper";
import MultiIconToggle from "../MultiIconToggle/MultiIconToggle";
import TablePaginationBar from "../Table/TablePaginationBar";
import classes from "./ProfilesView.module.css";
import ProfilesViewGrid from "./ProfilesViewGrid/ProfilesViewGrid";
import ProfilesViewList from "./ProfilesViewList/ProfilesViewList";
import BulkManagerAssignment from "../BulkManagerAssignment/BulkManagerAssignment";

const ROWS_PER_PAGE_OPTIONS = [25, 50, 100, 250];

const VIEW_MODES = {
  LIST: "list",
  GRID: "grid",
};

const VIEW_MODE_ROWS_PER_PAGE_LABELS = {
  [VIEW_MODES.LIST]: "Rows per page:",
  [VIEW_MODES.GRID]: "Profiles per page:",
};

const VIEW_MODE_COMPONENTS = {
  [VIEW_MODES.LIST]: ProfilesViewList,
  [VIEW_MODES.GRID]: ProfilesViewGrid,
};

const ProfilesView = ({profiles, fetchProfiles, filterOptions}) => {
  const [bulkEditOpen, setBulkEditOpen] = useState(false);
  const [bulkManagerAssignmentOpen, setBulkManagerAssignmentOpen] = useState(false);
  const [viewMode, setViewMode] = useState(VIEW_MODES.GRID);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]);

  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState(
    Object.fromEntries(FILTERS.map(({value, label}) => [value, label]))
  );

  const [order, setOrder] = useState(DESC);
  const [orderBy, setOrderBy] = useState("lastAccess");

  const [selectedProfiles, setSelectedProfiles] = useState(new Set());

  const [bulkDeleteModalOpen, setBulkDeleteModalOpen] = useState(false);

  const [bulkActionsPopoverOpen, setBulkActionsPopoverOpen] = useState(false);

  const handleBulkActionsPopoverOpen = () => setBulkActionsPopoverOpen(true);
  const handleBulkActionsPopoverClose = () => setBulkActionsPopoverOpen(false);

  const handleBulkEditOpen = () => setBulkEditOpen(true);
  const handleBulkEditClose = () => setBulkEditOpen(false);

  const handleBulkManagerAssignmentOpen = () => {
    handleBulkActionsPopoverClose();
    setBulkManagerAssignmentOpen(true);
  };
  const handleBulkManagerAssignmentClose = () => setBulkManagerAssignmentOpen(false);

  const handleChangeQuery = (event) => setQuery(event.target.value);

  const filteredProfiles = profiles.filter((profile) => {
    if (!profile.searchString.includes(query.toLowerCase())) return false;

    return Object.entries(filters).every(([key, value]) => {
      const noFilter = value === FILTER_LABELS[key];
      return noFilter || profile[key] === value;
    });
  });

  const sortedProfiles = _.orderBy(filteredProfiles, orderBy, order);

  const paginatedProfiles = paginated(sortedProfiles, page, rowsPerPage);

  const selectProfile = (profile) => setSelectedProfiles((prev) => setWith(prev, profile));

  const deselectProfile = (profile) => setSelectedProfiles((prev) => setWithout(prev, profile));

  const selectAllProfiles = () => setSelectedProfiles(new Set(sortedProfiles));

  const deselectAllProfiles = () => setSelectedProfiles(new Set());

  const numSelectedProfiles = selectedProfiles.size;

  const headerCheckboxChecked = numSelectedProfiles > 0;
  const headerCheckboxIndeterminate =
    headerCheckboxChecked && numSelectedProfiles !== sortedProfiles.length;

  const handleHeaderCheckboxChange = (event) => {
    const checked = event.target.checked;
    (checked ? selectAllProfiles : deselectAllProfiles)();
  };

  const headerCheckbox = (
    <CFormCheck
      style={{cursor: "pointer"}}
      checked={headerCheckboxChecked}
      indeterminate={headerCheckboxIndeterminate}
      onChange={handleHeaderCheckboxChange}
    />
  );

  const handleBulkDeleteOpen = () => {
    handleBulkActionsPopoverClose();
    setBulkDeleteModalOpen(true);
  };
  const handleBulkDeleteClose = () => setBulkDeleteModalOpen(false);

  const bulkDelete = async () => {
    const profileIds = Array.from(selectedProfiles).map(({id}) => id);
    await firebase.markUsersAsDeletedAndDisableSubscription(profileIds);
    await fetchProfiles();
  };

  const bulkDeleteModal = bulkDeleteModalOpen ? (
    <CModal visible={bulkDeleteModalOpen} onClose={handleBulkDeleteClose} alignment="center">
      <CModalHeader>
        <CModalTitle>Bulk Deletion</CModalTitle>
      </CModalHeader>
      <CModalBody className="d-flex flex-column gap-2">
        <div>
          You are about to delete {numSelectedProfiles} profile
          {numSelectedProfiles === 1 ? "" : "s"}.
        </div>
        <div>Are you sure you wish to continue?</div>
      </CModalBody>
      <CModalFooter>
        <CButton onClick={handleBulkDeleteClose} color="secondary">
          Cancel
        </CButton>
        <CButton onClick={bulkDelete} color="primary">
          Yes, I'm sure
        </CButton>
      </CModalFooter>
    </CModal>
  ) : null;

  const bulkManagerAssignmentModal = bulkManagerAssignmentOpen && (
    <BulkManagerAssignment
      visible={bulkManagerAssignmentOpen}
      onCancel={handleBulkManagerAssignmentClose}
      profiles={Array.from(selectedProfiles)}
    />
  );

  const bulkActions = [
    {
      id: "edit",
      label: "Bulk Edit",
      icon: cilPencil,
      onClick: handleBulkEditOpen,
    },
    {
      id: "manager-assignment",
      label: "Bulk Manager Assignment",
      icon: cilPencil,
      onClick: handleBulkManagerAssignmentOpen,
    },
    {
      id: "delete",
      label: "Bulk Delete",
      icon: cilTrash,
      onClick: handleBulkDeleteOpen,
      extraClasses: [classes.actionRed],
    },
  ];

  const bulkActionsElement = (
    <div className="vstack gap-3">
      {bulkActions.map(({id, label, icon, onClick, extraClasses}) => {
        const className = [classes.actionsButton].concat(extraClasses || []).join(" ");
        return (
          <button onClick={onClick} className={className} key={id}>
            <CIcon icon={icon} /> {label}
          </button>
        );
      })}
    </div>
  );

  const actionsButton = (
    <div
      className={`d-flex justify-content-end me-2 ${
        headerCheckboxChecked ? "" : classes.invisibleButton
      }`}
    >
      {/* CoreUI doesn't respect the cursor property defined through className */}
      <CPopover
        content={bulkActionsElement}
        placement="left"
        visible={bulkActionsPopoverOpen}
        onShow={handleBulkActionsPopoverOpen}
        onHide={handleBulkActionsPopoverClose}
      >
        <CButton
          variant="ghost"
          onClick={null}
          className="text-white"
          style={headerCheckboxChecked ? {} : {cursor: "default"}}
        >
          <CIcon icon={cilOptions} />
        </CButton>
      </CPopover>
    </div>
  );

  const searchBar = (
    <div className={classes.searchBar}>
      <div className="position-relative">
        <CFormInput
          size="sm"
          value={query}
          onChange={handleChangeQuery}
          style={{paddingLeft: 35}}
          placeholder="Search..."
        />
        <div
          className="position-absolute d-flex align-items-center justify-content-center h-100"
          style={{
            left: 10,
            top: 0,
          }}
        >
          <CIcon icon={cilSearch} />
        </div>
      </div>
    </div>
  );

  const VIEW_MODE_OPTIONS = [
    {icon: <CIcon icon={cilList} />, id: VIEW_MODES.LIST},
    {icon: <CIcon icon={cilGrid} />, id: VIEW_MODES.GRID},
  ];

  const viewModeOptionOnClick = ({id}) => setViewMode(id);

  const viewModeOptionIsSelected = ({id}) => viewMode === id;

  const viewModeToggle = (
    <MultiIconToggle
      options={VIEW_MODE_OPTIONS}
      optionOnClick={viewModeOptionOnClick}
      optionIsSelected={viewModeOptionIsSelected}
    />
  );

  const filterSelects = FILTERS.map(({value}) => (
    <div className={classes.filterSelect} key={value}>
      <CFormSelect
        size="sm"
        value={filters[value]}
        onChange={(event) => setFilters({...filters, [value]: event.target.value})}
        options={filterOptions[value]}
      />
    </div>
  ));

  const searchAndFilters = (
    <div>
      <CForm>
        <div className={classes.toolbar}>
          {searchBar}
          {filterSelects}
          <div className="flex-grow-1" />
          {viewModeToggle}
        </div>
      </CForm>
    </div>
  );

  const paginationBar = (
    <TablePaginationBar
      className="mx-2 mb-2"
      rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      rowsPerPage={rowsPerPage}
      count={sortedProfiles.length}
      page={page}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      rowsPerPageLabel={VIEW_MODE_ROWS_PER_PAGE_LABELS[viewMode]}
    />
  );

  if (bulkEditOpen)
    return (
      <BulkEditWrapper profiles={Array.from(selectedProfiles)} onClose={handleBulkEditClose} />
    );

  const ViewComponent = VIEW_MODE_COMPONENTS[viewMode];
  const view = ViewComponent ? (
    <ViewComponent
      actionsButton={actionsButton}
      deselectProfile={deselectProfile}
      headerCheckbox={headerCheckbox}
      headerCheckboxChecked={headerCheckboxChecked}
      order={order}
      orderBy={orderBy}
      paginatedProfiles={paginatedProfiles}
      selectProfile={selectProfile}
      selectedProfiles={selectedProfiles}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
    />
  ) : (
    <div>View "{viewMode}" not found.</div>
  );

  const modalsEl = (
    <>
      {bulkDeleteModal}
      {bulkManagerAssignmentModal}
    </>
  );

  const contentEl = (
    <div className="d-flex flex-column gap-2">
      {searchAndFilters}
      <div className="border rounded">
        <div className="overflow-auto">{view}</div>
        {paginationBar}
      </div>
    </div>
  );

  return (
    <>
      {modalsEl}
      {contentEl}
    </>
  );
};

ProfilesView.propTypes = {
  fetchProfiles: PropTypes.func.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  query: PropTypes.string,
};

ProfilesView.defaultProps = {
  query: "",
};

export default ProfilesView;
