import {CButton, CCard, CCardBody, CCardTitle, CForm, CFormInput} from "@coreui/react";
import React, {useState} from "react";
import {useToasterContext} from "../../../context/ToasterContext/ToasterContext";
import firebase from "../../../service/firebase";
import classes from "./RepairProfile.module.css";

const RepairProfile = () => {
  const {addToast} = useToasterContext();

  const [profileId, setProfileId] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleProfileIdChange = (event) => setProfileId(event.target.value);

  const clearForm = () => setProfileId("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    setSubmitting(true);

    const {success, message} = await firebase.fixProfilesById([profileId]);

    addToast({message, color: success ? "success" : "danger"});

    setSubmitting(false);

    clearForm();
  };

  const inputEnabled = !submitting;

  const submitButtonEnabled = !submitting && !!profileId?.length;

  const profileIdFormEl = (
    <CForm onSubmit={handleSubmit} onReset={clearForm} className="vstack gap-3">
      <CFormInput
        type="text"
        placeholder="Enter profile ID"
        value={profileId}
        onChange={handleProfileIdChange}
        multiple
        disabled={!inputEnabled}
      />
      <div style={{flexGrow: 1, display: "flex", justifyContent: "flex-end", gap: ".5rem"}}>
        <CButton type="reset" disabled={!submitButtonEnabled} color="secondary">
          Clear
        </CButton>
        <CButton type="submit" disabled={!submitButtonEnabled}>
          {submitting ? "Submitting..." : "Submit"}
        </CButton>
      </div>
    </CForm>
  );

  const cardEl = (
    <CCard className={classes.repairProfileCard}>
      <CCardBody>
        <CCardTitle>Repair profile by ID</CCardTitle>
        <div style={{height: "1rem"}} />
        {profileIdFormEl}
      </CCardBody>
    </CCard>
  );

  return <div className={classes.content}>{cardEl}</div>;
};

export default RepairProfile;
