import {CCol, CRow} from "@coreui/react";
import React from "react";
import classes from "../BulkEditReview.module.css";

const BulkEditReviewChange = ({label, displayedValue}) => {
  return (
    <CRow>
      <CCol xs={12} md={6} className={classes.changeLabel}>
        {label}
      </CCol>
      <CCol xs={12} md={6} className={classes.changeValue}>
        {displayedValue}
      </CCol>
    </CRow>
  );
};

export default BulkEditReviewChange;