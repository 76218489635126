import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setSubscriptions, startFetchingSubscriptions} from "../redux/slices/subscriptionsSlice";
import firebase from "../service/firebase";

const useSubscriptions = () => {
  const dispatch = useDispatch();
  const subscriptions = useSelector((state) => state.subscriptions.subscriptions);
  const fetchingSubscriptions = useSelector((state) => state.subscriptions.fetchingSubscriptions);

  const fetchSubscriptions = useCallback(async () => {
    dispatch(startFetchingSubscriptions());

    const {success, message, data: subscriptions} = await firebase.getSubscriptions();

    if (!success) {
      console.error(message);
      return;
    }

    dispatch(setSubscriptions({subscriptions}));
  }, [dispatch]);

  useEffect(() => {
    if (!subscriptions) void fetchSubscriptions();
  }, [subscriptions, fetchSubscriptions]);

  return {subscriptions, fetchSubscriptions, fetching: fetchingSubscriptions};
};

export default useSubscriptions;
