import {useState} from "react";

const useToaster = () => {
  const [toasts, setToasts] = useState([]);

  const addToast = (toast) => {
    const previousToast = toasts[toasts.length - 1];
    const previousToastId = previousToast?.id || -1;
    const toastId = previousToastId + 1;

    const toastWithId = {...toast, id: toastId};

    setToasts([...toasts, toastWithId]);
  };

  const removeToast = (id) => setToasts(toasts.filter((toast) => toast.id !== id));

  return {addToast, removeToast, toasts};
};

export default useToaster;
