import ImportUsers from "../views/Admin/ImportUsers/ImportUsers";
import Managers from "../views/Admin/Managers/Managers";
import MyUsers from "../views/Admin/MyUsers/MyUsers";
import NeedsAttentionProfiles from "../views/Admin/NeedsAttentionProfiles/NeedsAttentionProfiles";
import NoAccess from "../views/Admin/NoAccess/NoAccess";
import Profile from "../views/Admin/Profile/Profile";
import Profiles from "../views/Admin/Profiles/Profiles";
import RepairProfile from "../views/Admin/RepairProfile/RepairProfile";
import Reports from "../views/Admin/Reports/Reports";
import {NAV_ROUTES} from "../views/Admin/routes";
import Subscriptions from "../views/Admin/Subscriptions/Subscriptions";
import {useUserContext} from "./useUserContext";

const useAppRoutes = () => {
  const {
    user: {isAdmin},
  } = useUserContext();

  const adminComponent = (component) => (isAdmin ? component : NoAccess);

  const routes = [
    {
      ...NAV_ROUTES.PROFILES,
      component: adminComponent(Profiles),
    },
    {
      ...NAV_ROUTES.SUBSCRIPTIONS,
      component: adminComponent(Subscriptions),
    },
    {
      ...NAV_ROUTES.MY_USERS,
      component: MyUsers,
    },
    {
      ...NAV_ROUTES.MANAGERS,
      component: adminComponent(Managers),
    },

    {
      ...NAV_ROUTES.REPORTS,
      component: adminComponent(Reports),
    },
    {
      ...NAV_ROUTES.PROFILE,
      component: Profile,
    },
    {
      ...NAV_ROUTES.IMPORT_USERS,
      component: adminComponent(ImportUsers),
    },
    {
      ...NAV_ROUTES.NEEDS_ATTENTION_PROFILES,
      component: adminComponent(NeedsAttentionProfiles),
    },
    {
      ...NAV_ROUTES.REPAIR_PROFILE,
      component: adminComponent(RepairProfile),
    },
    // Only For Navbar
    {
      ...NAV_ROUTES.WEB_APP,
      component: null,
    },
  ];

  const allowedRoutes = isAdmin ? routes : routes.filter(({adminOnly}) => !adminOnly);

  const firstVisibleRoute = allowedRoutes.find(({shownInNav}) => shownInNav);

  return {routes: allowedRoutes, firstVisibleRoute};
};

export default useAppRoutes;
