import React from "react";
import {createContext, useContext} from "react";
import useToaster from "../../hooks/useToaster";

const ToasterContext = createContext({});

export const ToasterContextProvider = ({children}) => {
  const {toasts, addToast, removeToast} = useToaster();

  return (
    <ToasterContext.Provider value={{toasts, addToast, removeToast}}>
      {children}
    </ToasterContext.Provider>
  );
};

export const useToasterContext = () => useContext(ToasterContext);
