import React from "react";
import {CButton, CCard, CCardBody, CCardTitle, CCol, CContainer, CSpinner} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilArrowThickToBottom} from "@coreui/icons";

function ReportCard(props) {
  const {title, action, loading} = props;

  return (
    <CCol lg={6} md={6} xs={12}>
      <CCard>
        <CCardBody>
          <CCardTitle className="text-center">{title}</CCardTitle>

          <div className="flex-grow-1 mb-2" />
          <hr className="p-0 m-0" />

          <CContainer className="d-flex justify-content-center p-2">
            <CButton onClick={action} className="text-uppercase">
              <CIcon icon={cilArrowThickToBottom}></CIcon> Download
              {loading ? (
                <CSpinner className="ms-2" component="span" size="sm" style={{color: "#FFF"}} />
              ) : null}
            </CButton>
          </CContainer>
        </CCardBody>
      </CCard>
    </CCol>
  );
}

export default ReportCard;
