import React from "react";
import PropTypes from "prop-types";
import {CForm, CFormInput} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilSearch} from "@coreui/icons";

const searchStyles = {
  toolbar: {
    backgroundColor: "#fafafa",
    border: "none",
    boxShadow: "none",
  },
};

const SearchToolbar = (props) => {
  const {search, setSearch, headCells} = props;

  const labels = headCells.map((data) => data.label.toLowerCase());

  const placeholder = `Search by ${labels.join(", ")}`;

  return (
    <CForm className="d-flex py-2 table-search-wrapper">
      <div className="d-flex align-items-center mx-3">
        <CIcon icon={cilSearch} />
      </div>
      <CFormInput
        style={searchStyles.toolbar}
        type="search"
        placeholder={placeholder}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </CForm>
  );
};

export default SearchToolbar;

SearchToolbar.propTypes = {
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
};
