import React from "react";
import PropTypes from "prop-types";
import {CTableHead, CTableHeaderCell, CTableRow} from "@coreui/react";
import TableHeaderSortLabel from "./TableHeaderSortLabel";

function SimpleTableHead(props) {
  const {order, orderBy, onRequestSort, headCells} = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <CTableHead>
      <CTableRow align="middle">
        {headCells.map((headCell, idx) => (
          <CTableHeaderCell key={idx}>
            <TableHeaderSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              label={headCell.label}
            />
          </CTableHeaderCell>
        ))}
      </CTableRow>
    </CTableHead>
  );
}

export default SimpleTableHead;

SimpleTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired,
};
