import {useMemo} from "react";

export const useComponentWillMount = (func) => {
  useMemo(func, []);
};

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const sleep = async (ms) => await new Promise((resolve) => setTimeout(resolve, ms));