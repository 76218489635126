import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  setIndustryTypeOptions,
  startFetchingIndustryTypeOptions,
} from "../redux/slices/staticSlice";
import firebase from "../service/firebase";

const DEFAULT_OPTIONS = {
  profileIndustryType: null,
  includeEmptyOption: false,
};

const useIndustryTypeOptions = (options = DEFAULT_OPTIONS) => {
  const {profileIndustryType, includeEmptyOption} = {...DEFAULT_OPTIONS, ...options};

  const dispatch = useDispatch();
  const industryTypeOptions = useSelector((state) => state.static.industryTypeOptions);
  const fetchingIndustryTypeOptions = useSelector(
    (state) => state.static.fetchingIndustryTypeOptions
  );

  const fetchIndustryTypeOptions = useCallback(async () => {
    dispatch(startFetchingIndustryTypeOptions());
    const {success, industryTypeOptions, message} = await firebase.getIndustryTypeOptions();

    if (!success) {
      console.error(message);
      return;
    }

    dispatch(setIndustryTypeOptions({industryTypeOptions}));
  }, [dispatch]);

  useEffect(() => {
    if (!industryTypeOptions) void fetchIndustryTypeOptions();
  }, [industryTypeOptions, fetchIndustryTypeOptions]);

  /**
   * Used because in the user's profile, the label is saved instead of the value, and because
   * we need to add the empty option in case the user's industry type isn't in the list.
   * @type {null|{label: *, value: *}[]}
   */
  const mappedIndustryTypeOptions = (() => {
    if (!industryTypeOptions) return null;

    const options = [];

    if (profileIndustryType) {
      const profileIndustryTypeValidOption = industryTypeOptions.find(
        ({label}) => label === profileIndustryType
      );

      if (!profileIndustryTypeValidOption)
        options.push({value: profileIndustryType, label: profileIndustryType, invalid: true});
    }

    if (includeEmptyOption) options.push({value: "", label: "None"});

    return options
      .concat(industryTypeOptions)
      .sort((a, b) => {
        // Empty value first, then alphabetically, then value === "other" last
        if (a.value === "") return -1;
        if (b.value === "") return 1;
        if (a.value === "other") return 1;
        if (b.value === "other") return -1;

        return a.label.localeCompare(b.label);
      })
      .map(({value, label, invalid}) => ({
        label,
        value: value === "" ? value : label,
        invalid: !!invalid,
      }));
  })();

  return {
    fetchIndustryTypeOptions,
    industryTypeOptions,
    fetchingIndustryTypeOptions: fetchingIndustryTypeOptions || !mappedIndustryTypeOptions,
    mappedIndustryTypeOptions,
  };
};

export default useIndustryTypeOptions;