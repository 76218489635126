import React from "react";
import {
  CButton,
  CFormInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import PropTypes from "prop-types";
import {useForm} from "react-hook-form";
import firebase from "../../service/firebase";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers";

const defaultValues = {
  managerEmail: "",
};

const AssignManagerModal = ({visible, subordinateId, onClose, onSuccess = () => {}}) => {
  const schema = yup
    .object({
      managerEmail: yup.string().email("Invalid email.").required("Manager email is required."),
    })
    .required();
  const resolver = yupResolver(schema);

  const {register, handleSubmit, reset, formState} = useForm({resolver, defaultValues});
  const {errors} = formState;

  const onCancel = () => {
    reset(defaultValues);
    onClose();
  };

  const onSubmit = async ({managerEmail}) => {
    const {success, message} = await firebase.assignManagerToUserByEmail(
      subordinateId,
      managerEmail
    );

    alert(message);

    if (success) await onSuccess();

    onCancel();
  };

  const managerEmailError = errors.managerEmail?.message;

  return (
    <CModal visible={visible} alignment="center">
      <CModalHeader>
        <CModalTitle>Assign Manager</CModalTitle>
      </CModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CModalBody>
          <CFormInput
            type="text"
            name="managerEmail"
            ref={register}
            label="Manager email"
            autoFocus
            invalid={!!managerEmailError}
            text={managerEmailError}
          />
        </CModalBody>
        <CModalFooter>
          <CButton onClick={onCancel} variant="outline">
            Cancel
          </CButton>
          <CButton type="submit">Submit</CButton>
        </CModalFooter>
      </form>
    </CModal>
  );
};

AssignManagerModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  subordinateId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

export default AssignManagerModal;
