import {cilArrowLeft} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {CButton, CCol, CFormCheck, CFormInput, CFormSelect, CFormSwitch, CRow} from "@coreui/react";
import PropTypes from "prop-types";
import React, {useCallback, useEffect, useState} from "react";
import Flatpickr from "react-flatpickr";
import undoGray from "../../assets/icons/undo-gray.svg";
import undoOrange from "../../assets/icons/undo-orange.svg";
import {
  CATEGORY_ORDERS,
  getPermissionCategory,
  PERMISSIONS_INFO,
} from "../../constants/permissions";
import {
  BOUGHT_ON_OPTIONS,
  SUBSCRIPTION_OPTIONS,
  SUBSCRIPTION_VALUE_MAPPINGS,
} from "../../constants/subscriptions";
import {strToIntIfNeeded} from "../../utils/int-utils";
import classes from "./BulkEdit.module.css";
import BulkEditReview from "./BulkEditReview/BulkEditReview";
import BulkEditSection from "./BulkEditSection/BulkEditSection";

const styles = {
  avatar: {height: 100, width: 100},
  switch: {width: "2.5em", height: "1.5em", marginRight: "14px", marginTop: 0, cursor: "pointer"},
  accordion: {
    "--cui-accordion-bg": "white",
  },
};

// The ID of the fields should match the database field names

export const FIELD_IS_ACTIVE = {
  id: "isActive",
  label: "Account Status",
};

export const FIELD_PRODUCT_ID = {
  id: "productId",
  label: "Subscription Type",
};

export const FIELD_BOUGHT_ON = {
  id: "boughtOn",
  label: "Bought on",
};

export const FIELD_EXPIRATION_DATE = {
  id: "expirationDate",
  label: "Expiration Date",
};

export const FIELDS = [FIELD_IS_ACTIVE, FIELD_PRODUCT_ID, FIELD_BOUGHT_ON, FIELD_EXPIRATION_DATE];

export const FIELD_LABELS = FIELDS.reduce((acc, {id, label}) => {
  acc[id] = label;
  return acc;
}, {});

const BulkEdit = ({profiles, onClose, subscriptionsPermissions}) => {
  const numProfiles = profiles.length;

  const [showReviewPage, setShowReviewPage] = useState(false);

  const [subscriptionName, setSubscriptionName] = useState("");

  const FORM_SECTIONS = [
    {
      section: "Account Details",
      fields: [
        {
          ...FIELD_IS_ACTIVE,
          type: "select",
          options: [
            {value: "", label: "Status"},
            {value: "true", label: "Active"},
            {value: "false", label: "Inactive"},
          ],
        },
      ],
    },
    {
      section: "Subscription Details",
      fields: [
        {
          ...FIELD_PRODUCT_ID,
          type: "select",
          options: [{value: "", label: "Choose a plan"}].concat(SUBSCRIPTION_OPTIONS),
          valueAction: (productId) => {
            const subscriptionName = productId.split(".")[1];
            setSubscriptionName(SUBSCRIPTION_VALUE_MAPPINGS[subscriptionName]);
          },
        },
        {
          ...FIELD_BOUGHT_ON,
          type: "select",
          options: [{value: "", label: "Bought on"}].concat(BOUGHT_ON_OPTIONS),
        },
        {
          ...FIELD_EXPIRATION_DATE,
          type: "date",
        },
      ],
    },
  ];

  const [subscriptionForm, setSubscriptionForm] = useState(
    FORM_SECTIONS.reduce((acc, {fields}) => {
      for (const {id, type, options} of fields) {
        const obj = {active: false, value: ""};
        // For select fields, the first option should be the label one, with value ""
        if (type === "select") obj.value = options.length ? options[0].value : "";
        acc[id] = obj;
      }
      return acc;
    }, {})
  );

  const subscriptionPermissions = subscriptionName
    ? subscriptionsPermissions[subscriptionName]
    : null;

  const getBaseCategorizedPermissions = useCallback(
    () =>
      Object.keys(PERMISSIONS_INFO).reduce((acc, key) => {
        const category = getPermissionCategory(key);
        if (!acc[category]) acc[category] = {};
        acc[category][key] = subscriptionPermissions ? subscriptionPermissions[key] : null;
        return acc;
      }, {}),
    [subscriptionName, subscriptionPermissions]
  );

  const [baseCategorizedPermissions, setBaseCategorizedPermissions] = useState(
    getBaseCategorizedPermissions()
  );

  useEffect(() => {
    const basePermissions = getBaseCategorizedPermissions();
    setBaseCategorizedPermissions(basePermissions);
    setCategorizedPermissions(basePermissions);
    setPermissionUpdates({});
  }, [subscriptionName, getBaseCategorizedPermissions]);

  const [permissionUpdates, setPermissionUpdates] = useState({});
  const [categorizedPermissions, setCategorizedPermissions] = useState(baseCategorizedPermissions);
  const toggleShowReviewPage = () => setShowReviewPage((prev) => !prev);

  const sections = FORM_SECTIONS.map(({section, fields}) => (
    <BulkEditSection title={section} key={section}>
      <div className="d-flex flex-column gap-3">
        {fields.map(({id, label, type, options, valueAction}) => {
          const {active, value} = subscriptionForm[id];

          const checkboxOnChange = (event) => {
            const active = event.target.checked;
            setSubscriptionForm((prev) => ({...prev, [id]: {...prev[id], active}}));
          };

          const updateValue = (value) => {
            setSubscriptionForm((prev) => ({...prev, [id]: {...prev[id], value}}));
            if (valueAction) valueAction(value);
          };

          const checkbox = (
            <CFormCheck
              id={`${id}-checkbox`}
              checked={active}
              onChange={checkboxOnChange}
              label={label}
            />
          );

          const input = (() => {
            switch (type) {
              case "select":
                const selectOnChange = (event) => updateValue(event.target.value);

                return (
                  <CFormSelect value={value} onChange={selectOnChange} disabled={!active}>
                    {options.map(({value, label}) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </CFormSelect>
                );
              case "date":
                const dateOnChange = ([date]) => updateValue(date.getTime());

                const flatpickrRender = ({value, onChange}, ref) => (
                  <CFormInput name={`${id}-flatpickr-cforminput`} ref={ref} disabled={!active} />
                );

                return (
                  <Flatpickr
                    options={{dateFormat: "m/d/Y"}}
                    value={value || Date.now()}
                    onChange={dateOnChange}
                    render={flatpickrRender}
                  />
                );
              default:
                return null;
            }
          })();

          return (
            <CRow className="align-items-center" key={id}>
              <CCol xs={12} sm={4} lg={3}>
                {checkbox}
              </CCol>
              <CCol xs={12} sm={8} lg={9}>
                {input}
              </CCol>
            </CRow>
          );
        })}
      </div>
    </BulkEditSection>
  ));

  const closeAction = showReviewPage ? toggleShowReviewPage : onClose;

  const closeButton = (
    <button className={classes.closeButton}>
      <CIcon icon={cilArrowLeft} onClick={closeAction} />
    </button>
  );

  const header = (
    <CRow className={classes.header}>
      <h6>{closeButton} Bulk Edit</h6>
    </CRow>
  );

  const chooseFieldsInstructions = (
    <CRow className={classes.description}>
      <h6>
        Please choose the fields you want to change for the <b>{numProfiles} selected</b> profile
        {numProfiles !== 1 && "s"}.
      </h6>
    </CRow>
  );

  // Permissions

  const categoryOrder = (category) =>
    CATEGORY_ORDERS[category] || Object.keys(CATEGORY_ORDERS).length + 1;

  const updatePermissionsAndUpdates = (key, value) => {
    setPermissionUpdates((prev) => ({...prev, [key]: value}));
    const category = getPermissionCategory(key);
    setCategorizedPermissions((prev) => ({...prev, [category]: {...prev[category], [key]: value}}));
  };

  const handlePermissionChange = (key, value) => {
    // - Non-numerical permissions -
    if (!PERMISSIONS_INFO[key]?.numeric) {
      updatePermissionsAndUpdates(key, value);
      return;
    }

    // - Numerical permissions -

    // If using the switch
    if (typeof value === "boolean") {
      const newValue = value ? -1 : 0; // If enabling unlimited (true), set -1 , if disabling unlimited (false), set 0.
      updatePermissionsAndUpdates(key, newValue);
      return;
    }

    // If using the number input
    let intValue;
    try {
      intValue = strToIntIfNeeded(value);
    } catch (error) {
      console.error(error);
      return;
    }

    if (intValue > -1) updatePermissionsAndUpdates(key, intValue);
  };

  const resetPermission = (permissionId) => {
    const category = getPermissionCategory(permissionId);

    setPermissionUpdates((prev) =>
      Object.entries(prev).reduce((acc, [key, value]) => {
        if (key !== permissionId) acc[key] = value;
        return acc;
      }, {})
    );

    const initialValue = baseCategorizedPermissions[category][permissionId];

    setCategorizedPermissions((prev) => ({
      ...prev,
      [category]: {...prev[category], [permissionId]: initialValue},
    }));
  };

  const resetPermissions = () => {
    setPermissionUpdates({});
    setCategorizedPermissions(baseCategorizedPermissions);
  };

  const permissionDetailsHeader = (
    <CRow className="mt-2 mb-3">
      <CCol xs={12} className="m-0 p-0">
        <div className="d-flex align-items-center">
          <div className="d-inline-flex me-3">
            <div className="text-secondary-custom d-flex flex-column gap-2">
              <div>
                The following features are the ones that the user has access to, based on the
                subscribed plan.
                <br />
                Any permission changes made will override the plan permissions.
              </div>
              <div>
                By activating a permission and then deactivating, you may be deactivating a feature
                that was previously active for some of the selected users. If you want to
                individually reset a permission to how it was before your changes, press the
                <img src={undoGray} alt="undo" className={classes.undoIconGray} />
                button.
              </div>
            </div>
          </div>
          <div className="flex-grow-1" />
          <div className="d-inline-flex" style={{whiteSpace: "nowrap"}}>
            <CButton onClick={resetPermissions} color="primary" variant="outline">
              Reset Permissions
            </CButton>
          </div>
        </div>
      </CCol>
    </CRow>
  );

  const permissionsPicker = (
    <CRow>
      {Object.entries(categorizedPermissions)
        .sort((arr1, arr2) => categoryOrder(arr1[0]) - categoryOrder(arr2[0]))
        .map(([category, permissions], index) => {
          const options = Object.entries(permissions).map(([permissionId, value]) => {
            const info = PERMISSIONS_INFO[permissionId];
            const name = info?.name || permissionId;

            const switchIsChecked = [-1, true].includes(value);
            const numericalValue = typeof value === "number" ? value : null;
            const numericalInputVisible = info?.numeric && !switchIsChecked;
            const numericalInputCols = 3;

            const hasChange = permissionUpdates[permissionId] !== undefined;

            const undoChange = () => resetPermission(permissionId);

            const undoButton = (
              <button onClick={undoChange} disabled={!hasChange} className={classes.undoButton}>
                <img src={undoOrange} alt="undo" />
              </button>
            );

            return (
              <CCol sm={12} md={6} className="my-2 align-items-center" key={permissionId}>
                <CRow className="align-items-center">
                  <CCol xs={numericalInputVisible ? 12 - numericalInputCols : 12}>
                    <div className="d-flex align-items-center">
                      <CFormSwitch
                        label={name}
                        style={styles.switch}
                        checked={switchIsChecked}
                        onChange={(e) => handlePermissionChange(permissionId, e.target.checked)}
                      />
                      {numericalInputVisible ? null : undoButton}
                    </div>
                  </CCol>
                  <CCol xs={numericalInputCols} className={numericalInputVisible ? "" : "d-none"}>
                    <div className="d-flex align-items-center">
                      <CFormInput
                        type="number"
                        min={0}
                        disabled={switchIsChecked}
                        id={`input_${permissionId}`}
                        size="sm"
                        value={numericalValue || numericalValue === 0 ? numericalValue : ""}
                        onChange={(e) => handlePermissionChange(permissionId, e.target.value)}
                        className="flex-grow-1"
                      />
                      {undoButton}
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            );
          });

          return (
            <CCol xs={12} key={index}>
              <CRow className="category-bar mb-2 mt-4 py-1 ps-2">{category}</CRow>
              <CRow className="">{options}</CRow>
            </CCol>
          );
        })}
    </CRow>
  );

  const permissionDetails = (
    <BulkEditSection title="Permission Details">
      {permissionDetailsHeader}
      {permissionsPicker}
    </BulkEditSection>
  );

  const hasChanges =
    Object.keys(permissionUpdates).length ||
    Object.values(subscriptionForm).some(({value}) => value !== "");

  const bottomButtons = (
    <div className="d-flex justify-content-end gap-3">
      <CButton onClick={onClose} variant="outline">
        Cancel
      </CButton>
      <CButton onClick={toggleShowReviewPage} disabled={!hasChanges} color="primary">
        Review Changes
      </CButton>
    </div>
  );

  const bulkEditPage = (
    <>
      {chooseFieldsInstructions}
      <div className="d-flex flex-column gap-3">
        {sections}
        {permissionDetails}
        {bottomButtons}
      </div>
    </>
  );

  // Remove active fields with no value (label chosen in the dropdown instead of an actual value)
  const cleanedSubscriptionForm = Object.entries(subscriptionForm).reduce(
    (acc, [key, subscriptionField]) => {
      const {active, value} = subscriptionField;
      acc[key] = {...subscriptionField, active: active && value !== ""};
      return acc;
    },
    {}
  );

  const content = showReviewPage ? (
    <BulkEditReview
      profiles={profiles}
      permissionUpdates={permissionUpdates}
      subscriptionForm={cleanedSubscriptionForm}
      toggleShowReviewPage={toggleShowReviewPage}
      closeBulkEdit={onClose}
    />
  ) : (
    bulkEditPage
  );

  return (
    <div className="d-flex flex-column gap-3">
      {header}
      {content}
    </div>
  );
};

BulkEdit.propTypes = {
  profiles: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BulkEdit;
