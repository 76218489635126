import {startCase} from "lodash/string";
import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SUBSCRIPTION_LABEL_MAPPINGS} from "../constants/subscriptions";
import {setProfiles, startFetchingProfiles} from "../redux/slices/profilesSlice";
import firebase from "../service/firebase";
import {msToDate} from "../utils/date-utils";

/**
 * Adds convenient getters to a profile object.
 * @param profile Profile object to add properties to.
 */
const profileWithGetters = (profile) => {
  const profileWithGetters = {...profile};

  Object.defineProperties(profileWithGetters, {
    lastAccessFormatted: {
      get: () => msToDate(profileWithGetters.lastAccess),
      configurable: true,
    },
    expirationDate: {
      get: () => profileWithGetters.subscription?.expirationDate,
      configurable: true,
    },
    expirationDateFormatted: {
      get: () => msToDate(profileWithGetters.subscription?.expirationDate),
      configurable: true,
    },
    subscriptionName: {
      get: () => {
        const productId = profileWithGetters.subscription?.productId || ".None.";
        const productIdName = productId.split(/[._]/)[1] || "None";
        const mappedSubscriptionName = SUBSCRIPTION_LABEL_MAPPINGS[productIdName] || productIdName;
        return startCase(mappedSubscriptionName);
      },
      configurable: true,
    },
    subscriptionActive: {
      get: () => profileWithGetters.subscription?.isActive,
    },
    subscriptionExpired: {
      get: () => profileWithGetters.subscription?.expirationDate < Date.now(),
    },
    subscriptionIsActiveAndNotExpired: {
      get: () => {
        if (!profileWithGetters.subscription) return false;
        const {isActive, expirationDate} = profileWithGetters.subscription;
        return isActive && expirationDate > Date.now();
      },
      configurable: true,
    },
    numAssignments: {
      get: () => Object.keys(profileWithGetters.assignsList || {}).length || 0,
      configurable: true,
    },
    path: {
      get: () => `/admin/profiles/${profileWithGetters.id}`,
      configurable: true,
    },
    isManager: {
      get: () => {
        const managementProfiles = Object.values(profileWithGetters.managementProfiles || {});
        return !!managementProfiles?.length;
      },
      configurable: true,
    },
  });

  Object.defineProperties(profileWithGetters, {
    subscriptionStatus: {
      get: () => {
        const status = (() => {
          if (!profileWithGetters.subscriptionActive) return "Inactive";
          if (profileWithGetters.subscriptionExpired) return "Expired";
          return "Active";
        })();

        return `${status} Subscription`;
      },
      configurable: true,
    },
    searchString: {
      get: () =>
        [
          profileWithGetters.email,
          profileWithGetters.fullName,
          profileWithGetters.subscriptionName,
          profileWithGetters.lastAccessFormatted,
          profileWithGetters.expirationDateFormatted,
          profileWithGetters.numAssignments,
          profileWithGetters.industryType,
          profileWithGetters.id,
        ]
          .join(" ")
          .toLowerCase(),
      configurable: true,
    },
  });

  return profileWithGetters;
};

const useProfiles = () => {
  const dispatch = useDispatch();
  const profiles = useSelector((state) => state.profiles.profiles);
  const fetchingProfiles = useSelector((state) => state.profiles.fetchingProfiles);

  const fetchProfiles = useCallback(async () => {
    dispatch(startFetchingProfiles());

    const {success, data: profiles, message} = await firebase.getProfiles();

    if (!success) {
      console.error(message);
      return;
    }

    dispatch(setProfiles({profiles}));
  }, [dispatch]);

  useEffect(() => {
    if (!profiles) void fetchProfiles();
  }, [profiles, fetchProfiles]);

  const profilesWithGetters = profiles?.map(profileWithGetters);

  return {fetchProfiles, profiles: profilesWithGetters, fetching: fetchingProfiles};
};

export default useProfiles;
