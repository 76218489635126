import React from "react";
import {Route, Switch} from "react-router-dom";
import {OTHER_ROUTES} from "./views/Admin/routes";

const Routes = ({loggedIn}) => {
  const {
    ADMIN: {path: adminPath, component: adminComponent},
    SIGN_IN: {path: signInPath, component: signInComponent},
  } = OTHER_ROUTES;

  return (
    <Switch>
      {loggedIn ? <Route path={adminPath} component={adminComponent} /> : null}
      <Route path={signInPath} component={signInComponent} />
    </Switch>
  );
};

export default Routes;
