import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import {CTableDataCell} from "@coreui/react";

function CustomTableCell(props) {
  const {row, dataCells} = props;

  const styles = {
    cell: {
      fontSize: "0.85rem",
    },
  };

  const tableCell = (data, idx) => {
    switch (data.type) {
      case "date":
        return (
          <CTableDataCell key={idx} className="text-break" style={styles.cell}>
            {moment.unix(row[data.dataKey] / 1e3).format("MMM DD, YYYY")}
          </CTableDataCell>
        );

      case "datetime":
        return (
          <CTableDataCell key={idx} className="text-break" style={styles.cell}>
            {moment.unix(row[data.dataKey] / 1e3).format("MMM DD, YYYY hh:mm A")}
          </CTableDataCell>
        );

      case "amount":
        const amount = row[data.dataKey] ? Object.keys(row[data.dataKey]).length : 0;
        return (
          <CTableDataCell key={idx} className="text-break" style={styles.cell}>
            {amount}
          </CTableDataCell>
        );

      default:
        return (
          <CTableDataCell key={idx} className="text-break" style={styles.cell}>
            {row[data.dataKey]}
          </CTableDataCell>
        );
    }
  };

  return dataCells.map((data, idx) => {
    return tableCell(data, idx);
  });
}

export default CustomTableCell;

CustomTableCell.propTypes = {
  row: PropTypes.object.isRequired,
  dataCells: PropTypes.array.isRequired,
};
