export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function descendingKeysLengthComparator(a, b, orderBy) {
  const aKeysLength = orderBy in a ? Object.keys(a[orderBy]).length : 0;
  const bKeysLength = orderBy in b ? Object.keys(b[orderBy]).length : 0;

  if (bKeysLength < aKeysLength) {
    return -1;
  }
  if (bKeysLength > aKeysLength) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy, byLength = false) {
  const multiplier = order === "desc" ? 1 : -1;
  const comparator = byLength ? descendingKeysLengthComparator : descendingComparator;
  return (a, b) => multiplier * comparator(a, b, orderBy);
}

export const stableFilterAndSort = (array, comparator, searchString, headCells) => {
  const arraySearched = searchString
    ? array.filter((row) =>
        headCells.some(
          (info) =>
            row[info.id] &&
            (typeof row[info.id] === "string"
              ? row[info.id].toLowerCase().includes(searchString.toLowerCase())
              : row[info.id] === searchString)
        )
      )
    : array;

  const stabilizedThis = arraySearched.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};
