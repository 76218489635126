const env = process.env;

const serverUrl = env.REACT_APP_API_BASE_URL;
const apiIsLocal = serverUrl.includes("localhost");
const apiSuffix = apiIsLocal ? "" : "/api";
const apiUrl = `${serverUrl}${apiSuffix}`;

export const ENV = {
  NODE_ENV: env.NODE_ENV,
  SERVER_URL: serverUrl,
  API_URL: apiUrl,
  WEB_APP_URL: env.REACT_APP_WEB_APP_URL,
  FIREBASE_API_KEY: env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL: env.REACT_APP_FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID: env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID: env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

export const validateEnvVars = () => {
  const missingEnvVars = Object.keys(ENV).filter((key) => !ENV[key]);

  const isValid = !missingEnvVars.length;

  if (!isValid) {
    const text = `Missing environment variables:\n${missingEnvVars.join("\n")}`;
    console.error(text);
    alert(text);
  }

  return isValid;
};
