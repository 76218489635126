import {ENV} from "../../env";
import {getHttpHeaders, httpPostRequest} from "../../utils/api-utils";

export const TOKEN_KEY = "encodedToken";
export const REFRESH_TOKEN_KEY = "refreshToken";
export const FB_TOKEN_KEY = "fbToken";

let refreshPromise;

export async function getLogin(credentials) {
  return await httpPostRequest(getLoginInfo.bind(this, credentials));
}

async function getLoginInfo(credentials) {
  const init = getHttpHeaders("POST");
  init.body = JSON.stringify(credentials);
  return await fetch(`${ENV.API_URL}/customAuth`, init);
}

export async function getNewToken() {
  const init = getHttpHeaders("POST");
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  init.body = JSON.stringify({refreshToken: refreshToken});
  return await fetch(`${ENV.API_URL}/refreshToken`, init);
}

export function renewToken() {
  if (refreshPromise) return refreshPromise;

  refreshPromise = new Promise((resolve, reject) => {
    getNewToken()
      .then(async (response) => {
        if (response.ok) {
          await response.json().then((result) => {
            localStorage.setItem(TOKEN_KEY, result.token);
            localStorage.setItem(REFRESH_TOKEN_KEY, result.refreshToken);
            localStorage.setItem(FB_TOKEN_KEY, result.fbtoken);
            refreshPromise = undefined;
            resolve(result);
          });
        } else {
          refreshPromise = undefined;
          reject(response);
        }
      })
      .catch((err) => {
        refreshPromise = undefined;
        reject(err);
      });
  });
  return refreshPromise;
}
