/**
 * Returns whether the first character of the string is uppercase. If the string is empty, returns false.
 * @param str {string}
 * @returns {boolean}
 */
const startsWithUpperCase = (str) => {
  const [firstChar] = str;
  if (!firstChar) return false;
  return firstChar === firstChar.toUpperCase();
};

/**
 * Returns the initials (maximum 2 letters) of the name. If the name is empty, returns an empty string.
 * @param name {string}
 * @returns {string} Initials
 */
export const getInitials = (name) => {
  const trimmedName = name.trim();

  if (!trimmedName) return "";

  // Split the name into words, by whitespace and punctuation
  const words = trimmedName.split(/[\s\p{P}_]+/u);

  const wordsStartingWithUpperCase = words.filter((word) => startsWithUpperCase(word));

  const filteredWords = wordsStartingWithUpperCase.length ? wordsStartingWithUpperCase : words;

  const numFilteredWords = filteredWords.length;

  if (!numFilteredWords) return "";

  const firstInitial = filteredWords[0][0];
  const lastInitial = numFilteredWords > 1 ? filteredWords[filteredWords.length - 1][0] : "";

  return `${firstInitial}${lastInitial}`.toUpperCase();
};

export const toBooleanIfApplicable = (string) => {
  if (string === "true") return true;
  if (string === "false") return false;
  return string;
}
