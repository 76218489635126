import React from "react";

/**
 * Returns an array of elements with a separator between each element.
 * @param elements {JSX.Element[]} Array of elements
 * @param separator {JSX.Element} Element to use as a separator
 * @returns {JSX.Element[]} Array of elements with separators
 */
export const withSeparator = (elements, separator = <hr />) => {
  return elements.reduce((acc, element, index) => {
    if (index) acc.push(separator);
    acc.push(element);
    return acc;
  }, []);
};
