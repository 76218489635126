import React from "react";
import Page from "../../../components/Page/Page";

const NoAccess = () => {
  return (
    <Page title="No Access">
      <div>You don't have access to this</div>
    </Page>
  );
};

export default NoAccess;
