import {cilDelete, cilSearch} from "@coreui/icons";
import CIcon from "@coreui/icons-react";

import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CCard,
  CCardBody,
  CCardSubtitle,
  CCardTitle,
  CCol,
  CCollapse,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CFormSwitch,
  CInputGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CPlaceholder,
  CRow,
  CSpinner,
  CTable,
} from "@coreui/react";
import _ from "lodash";
import moment from "moment";
import React, {useCallback, useEffect, useRef, useState} from "react";
import Flatpickr from "react-flatpickr";
import {useHistory, useParams} from "react-router-dom";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import ProfileAssignmentCard from "../../../components/AssignmentCard/ProfileAssignmentCard";
import OptionalAvatar from "../../../components/Avatar/OptionalAvatar";
import Page from "../../../components/Page/Page";
import Select from "../../../components/Select/Select";
import ButtonTable from "../../../components/Table/ButtonTable";
import TablePaginationBar from "../../../components/Table/TablePaginationBar";
import {
  CATEGORY_ORDERS,
  getPermissionCategory,
  PERMISSIONS_INFO,
} from "../../../constants/permissions";
import {BOUGHT_ON_OPTIONS, SUBSCRIPTION_OPTIONS} from "../../../constants/subscriptions";
import {useToasterContext} from "../../../context/ToasterContext/ToasterContext";
import {ENV} from "../../../env";
import useIndustryTypeOptions from "../../../hooks/useIndustryTypeOptions";
import useProfiles from "../../../hooks/useProfiles";
import {useUserContext} from "../../../hooks/useUserContext";
import {authEnv, dbEnv} from "../../../index";
import firebase from "../../../service/firebase";
import {getFullAssignmentAddress} from "../../../utils/assignment-utils";
import {isValidPassword} from "../../../utils/auth-utils";
import {conditionalClass, strJoin} from "../../../utils/css-utils";
import {strToIntIfNeeded} from "../../../utils/int-utils";
import {useComponentWillMount} from "../../../utils/utils";
import "./Profile.css";
import classes from "./Profile.module.css";
import AssignManagerModal from "../../../components/AssignManagerModal/AssignManagerModal";
import {FB_TOKEN_KEY} from "../../../Controllers/Login/OAuth";
import {getLastAccessStrings} from "../../../utils/date-utils";

export const PROFILE_STYLES = {
  avatar: {
    height: 100,
    width: 100,
    border: "4px solid #f0e5ff",
    "--cui-secondary-rgb": "250, 250, 250",
  },
  switch: {width: "2.5em", height: "1.5em", marginRight: "14px", marginTop: 0, cursor: "pointer"},
  accordion: {"--cui-accordion-bg": "white"},
};

const IS_ACTIVE_OPTIONS = [
  {value: true, label: "Yes"},
  {value: false, label: "No"},
];

const DEVICE_TABLE_COLS = [
  {key: "os", label: "OS", _props: {scope: "col"}},
  {key: "osVersion", label: "OS Version", _props: {scope: "col"}},
  {key: "version", label: "Version", _props: {scope: "col"}},
  {key: "connection", label: "Connection", _props: {scope: "col"}},
  {key: "date", label: "Date", _props: {scope: "col"}},
];

const MANAGERS_HEAD_CELLS = [
  {id: "email", label: "Email"},
  {id: "fullName", label: "Full Name"},
  {id: "lastAccess", label: "Last Access"},
  {id: "signUpDate", label: "Register Date"},
  {id: "amountAssignments", label: "Nr. of Assignments"},
  {id: "industryType", label: "Industry Type"},
  {id: "id", label: "UID"},
];

const MANAGERS_DATA_CELLS = [
  {dataKey: "email", noPadding: true},
  {dataKey: "fullName"},
  {dataKey: "lastAccess", type: "date"},
  {dataKey: "signUpDate", type: "date"},
  {dataKey: "assignsList", type: "amount"},
  {dataKey: "industryType"},
  {dataKey: "id"},
];

export const DataInfo = ({name, data}) => (
  <div className="d-flex text-break">
    <p className="me-1">{name}:</p>
    <p className="text-secondary-custom">{data}</p>
  </div>
);

const Profile = ({onMobileNavOpen}) => {
  const history = useHistory();
  const {profileId} = useParams();

  const {
    user: {id, isAdmin},
  } = useUserContext();

  const {fetchProfiles} = useProfiles();

  const {addToast} = useToasterContext();

  const [profile, setProfile] = useState({});

  const {fetchingIndustryTypeOptions, mappedIndustryTypeOptions} = useIndustryTypeOptions({
    profileIndustryType: profile?.industryType,
  });

  const [assignments, setAssignments] = useState([]);
  const [assignmentsLoading, setAssignmentsLoading] = useState(true);
  const [assignmentsPerPage, setAssignmentsPerPage] = useState(10);
  const [assignmentsPage, setAssignmentsPage] = useState(0);

  const [assignmentQuery, setAssignmentQuery] = useState("");

  const [subscription, setSubscription] = useState({});
  const [initialSubscription, setInitialSubscription] = useState({});
  const [imgFile, setImgFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [devices, setDevices] = useState([]);

  const [managers, setManagers] = useState([]);
  const [managersLoading, setManagersLoading] = useState(true);

  const [assignManagerModalVisible, setAssignManagerModalVisible] = useState(false);

  const init = async () => {
    setLoader(true);

    Promise.all([firebase.getProfile(profileId), firebase.getSubscriptionInfo(profileId)]).then(
      async (res) => {
        const resProfile = res[0];
        const resSubscription = res[1];

        if (resProfile.success) {
          const profile = resProfile.data;

          if (!isAdmin) {
            const meResult = await firebase.getProfile(id);
            if (
              !meResult.data.managementProfiles ||
              !Object.keys(meResult.data.managementProfiles).includes(profileId)
            ) {
              history.push("/admin/myusers");
            }
          }

          const deviceList = profile.deviceList;
          if (profile.deviceList) {
            const keys = Object.keys(deviceList);
            setDevices(
              keys
                .map((key) => {
                  return {...deviceList[key], id: key};
                })
                .sort((d1, d2) => d1.timestamp < d2.timestamp)
            );
          }

          if (resSubscription.success) {
            setInitialSubscription(resSubscription.data);
            setSubscription(resSubscription.data);
          }
          setProfile(profile);
          setLoader(false);

          fetchAssignmentsPaged(profile);
          fetchManagers();
        }
      }
    );
  };

  const reset = async () => {
    setProfile({});
    setAssignments([]);
    setSubscription({});
    setInitialSubscription({});
    setImgFile(null);
    setLoader(false);
    setUploadLoader(false);
    setDevices([]);
    setManagers([]);
    await init();
  };

  const fetchAssignmentsPaged = useCallback(
    (profile) => {
      // Sorted from most to least recent (Firebase key behavior, reversed)
      const allAssignments = Object.keys(profile.assignsList || {}).reverse();
      const assignmentsList = allAssignments.slice(
        assignmentsPage * assignmentsPerPage,
        assignmentsPage * assignmentsPerPage + assignmentsPerPage
      );

      void fetchSelectAssignments(assignmentsList);
    },
    [assignmentsPage, assignmentsPerPage]
  );

  const fetchSelectAssignments = async (assignmentsList) => {
    setAssignmentsLoading(true);

    if (!assignmentsList) return;

    const {success, data} = await firebase.getAssignments(profileId, assignmentsList);

    if (success) setAssignments(data);

    setAssignmentsLoading(false);
  };

  useEffect(() => {
    if (Object.keys(profile).length) fetchAssignmentsPaged(profile);
  }, [assignmentsPage, assignmentsPerPage, fetchAssignmentsPaged, profile]);

  const fetchManagers = () => {
    const resultManagers = () => firebase.getUserManagers(profileId);
    setManagersLoading(true);
    Promise.all([resultManagers()]).then((res) => {
      const managersRes = res[0];
      if (managersRes.success) {
        setManagers(managersRes.data);
        setManagersLoading(false);
      }
    });
  };

  useComponentWillMount(() => init());

  const [updatingSubscription, setUpdatingSubscription] = useState(false);

  const subscriptionUnmodified = _.isEqual(subscription, initialSubscription);

  const updateSubscription = async () => {
    if (subscriptionUnmodified) return;

    setUpdatingSubscription(true);

    try {
      const {email, ...updates} = subscription;

      try {
        await firebase.updateUserSubscription(profile.id, updates);
      } catch (error) {
        console.error(`Error updating subscription: ${error || "unknown"}`);
        return;
      }

      await resetPermissions();

      await reset();
      await fetchProfiles();
    } catch (error) {
      console.error(error);
    }

    setUpdatingSubscription(false);
  };

  const updateProfile = async () => {
    setUploadLoader(true);

    const {message, success} = await firebase.updateProfile(
      profile,
      ["fullName", "industryType", "avatar"],
      imgFile
    );

    if (success) {
      await reset();
      await fetchProfiles();
    } else alert(message);

    setUploadLoader(false);
  };

  const updateAssignmentVisibility = async (assignmentId, cloudOnly) => {
    const {data: newCloudOnly, success} = await firebase.updateAssignmentVisibility(
      profileId,
      assignmentId,
      cloudOnly
    );

    if (!success) return;

    const newAssignments = [...assignments];
    const alteredAssignment = newAssignments.find(
      (assignment) => assignment.assignmentId === assignmentId
    );

    if (!alteredAssignment) return;

    alteredAssignment.cloudOnly = newCloudOnly;
    setAssignments(newAssignments);
  };

  const clearSubscription = () => {
    setSubscription({...initialSubscription});
  };

  const handleProfileFieldChange = (event) => {
    const {name, value} = event.target;
    setProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubscription = (event) => {
    const {name, value} = event.target;

    if (name !== "isActive" && subscription.isActive === undefined) {
      setSubscription({
        ...subscription,
        [name]: value,
        isActive: false,
      });
    } else {
      // switch to handle CoreUI
      switch (name) {
        case "isActive":
          setSubscription({
            ...subscription, // Hack because CFormSelect changes boolean values to strings
            [name]: value === "true",
          });
          break;
        case "boughtOn":
          // CFormSelect also seems to need "" as an option, this mimics MUI Select behavior
          if (value !== "") setSubscription({...subscription, [name]: value});
          break;
        default:
          setSubscription({...subscription, [name]: value});
      }
    }
  };
  const uploadImage = (event) => {
    const reader = new FileReader();
    const file = event.target.files[0];

    reader.onloadend = () => {
      setImgFile(file);
      setProfile({...profile, avatar: reader.result});
    };

    try {
      reader.readAsDataURL(file);
    } catch (error) {
      console.log("ERROR READING DATA AS URL");
    }
  };

  const isSubscriptionFilled = () => {
    const fields = ["boughtOn", "productId", "expirationDate", "isActive"];
    return fields.every((field) => ![null, undefined].includes(subscription[field]));
  };

  const unassignManager = (manager) => {
    firebase.unassignManagerFromUser(profileId, manager.id).then(() => fetchManagers());
  };

  const getBoughtOnOptions = () => {
    if (BOUGHT_ON_OPTIONS.find(({value}) => value === subscription.boughtOn))
      return BOUGHT_ON_OPTIONS;

    return [
      {
        value: initialSubscription.boughtOn,
        label: initialSubscription.boughtOn || "None",
      },
    ].concat(BOUGHT_ON_OPTIONS);
  };

  const deviceTableFields = DEVICE_TABLE_COLS.map((col) => col.key);

  const spinner = (
    <div className="d-flex justify-content-center align-items-center h-100">
      <CSpinner color="primary" />
    </div>
  );

  const [updatePasswordForm, setUpdatePasswordForm] = useState({});

  const handleUpdatePassword = (event) => {
    setUpdatePasswordForm({
      ...updatePasswordForm,
      [event.target.name]: event.target.value,
    });
  };

  const [updatePasswordFieldErrors, setUpdatePasswordFieldErrors] = useState({});
  const [updatePasswordValid, setUpdatePasswordValid] = useState(false);
  const [updatePasswordModalVisible, setUpdatePasswordModalVisible] = useState(false);

  const validateUpdatePassword = () => {
    const form = updatePasswordForm;
    const errors = {};

    const {password, passwordConfirm} = form;

    if (password && !isValidPassword(password))
      errors.password =
        "Password must be at least 8 characters long, contain at least one uppercase and one lowercase character, a number and a special character.";

    if (passwordConfirm && !form.passwordConfirm.length) {
      errors.passwordConfirm = "Password confirmation is required.";
    } else if (form.password !== form.passwordConfirm) {
      errors.passwordConfirm = "Passwords do not match.";
    }

    setUpdatePasswordFieldErrors(errors);
    const valid = !Object.keys(errors).length;
    setUpdatePasswordValid(valid);
    return valid;
  };

  useEffect(() => {
    validateUpdatePassword();
  }, [updatePasswordForm]);

  const updatePassword = () => {
    fetch(`${ENV.API_URL}/admin/changePassword`, {
      method: "POST",
      headers: {
        token: localStorage.getItem(FB_TOKEN_KEY),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: profile.email,
        password: updatePasswordForm.password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setUpdatePasswordModalVisible(false);

        const toast = makeUpdatePasswordToast(data);
        addToast(toast);

        if (
          !data.success &&
          data.message === "There is no user record corresponding to the provided identifier."
        ) {
          const message = `Profile found in "${dbEnv}" DB, but not in "${authEnv}" Auth.`;
          void firebase.addProfileToNeedsAttention(profileId, message);
        }
      })
      .catch((error) => console.log(error));
  };

  const makeUpdatePasswordToast = ({message: dataMsg, success}) => {
    const message =
      dataMsg.charAt(0).toUpperCase() +
      dataMsg.slice(1) +
      (dataMsg.charAt(dataMsg.length - 1) === "." ? "" : ".");

    const color = success ? "success" : "danger";

    return {message, color};
  };

  const [foundAssignments, setFoundAssignments] = useState([]);
  const [searchingAssignments, setSearchingAssignments] = useState(false);
  const [noFoundAssignments, setNoFoundAssignments] = useState(false);
  // Whether to show only assignments found using the search bar or not
  const [showOnlyFoundAssignments, setShowOnlyFoundAssignments] = useState(false);
  const [foundAssignmentsPage, setFoundAssignmentsPage] = React.useState(0);
  const [foundAssignmentsPerPage, setFoundAssignmentsPerPage] = React.useState(10);

  const handleChangeAssignmentQuery = (event) => setAssignmentQuery(event.target.value);

  const handleSearchAssignmentsSubmit = async (event) => {
    event.preventDefault();

    if (!assignmentQuery.length) {
      setShowOnlyFoundAssignments(false);
      setNoFoundAssignments(false);
      return;
    }

    setSearchingAssignments(true);
    setShowOnlyFoundAssignments(true);

    const url = `${ENV.API_URL}/findAssignmentsByName`;
    const headers = {
      token: localStorage.getItem(FB_TOKEN_KEY),
      "Content-Type": "application/json",
    };
    const body = JSON.stringify({
      profileId,
      search: assignmentQuery,
    });

    try {
      const response = await fetch(url, {method: "POST", headers, body});
      const assignments = await response.json();

      setFoundAssignments(assignments);
      setNoFoundAssignments(!assignments.length);
    } catch (error) {
      console.error(error);
    } finally {
      setSearchingAssignments(false);
    }
  };

  const clearSearch = () => {
    setAssignmentQuery("");
    setShowOnlyFoundAssignments(false);
    setNoFoundAssignments(false);
    setSearchingAssignments(false);
  };

  const assignmentsPerPageOptions = [10, 25, 50, 100, 250, 500, 1000];

  const [permissionsLoading, setPermissionsLoading] = useState(true);
  const [permissionUpdates, setPermissionUpdates] = useState({});
  const numericalInputRefs = useRef(new Map());

  const [categorizedPermissions, setCategorizedPermissions] = useState({});

  const updatePermissionsAndUpdates = (key, value) => {
    setPermissionUpdates((prev) => ({...prev, [key]: value}));
    const category = getPermissionCategory(key);
    setCategorizedPermissions((prev) => ({...prev, [category]: {...prev[category], [key]: value}}));
  };

  const handlePermissionChange = (key, value) => {
    // - Non-numerical permissions -
    if (!PERMISSIONS_INFO[key]?.numeric) {
      updatePermissionsAndUpdates(key, value);
      return;
    }

    // - Numerical permissions -

    // If using the switch
    if (typeof value === "boolean") {
      const newValue = value ? -1 : 0; // If enabling unlimited (true), set -1 , if disabling unlimited (false), set 0.
      updatePermissionsAndUpdates(key, newValue);
      return;
    }

    // If using the number input
    let intValue;
    try {
      intValue = strToIntIfNeeded(value);
    } catch (error) {
      console.error(error);
      return;
    }

    if (intValue > -1) updatePermissionsAndUpdates(key, intValue);
  };

  const savePermissionUpdates = async () => {
    setPermissionsLoading(true);

    await firebase.updateUserOverwritePermissions(profileId, permissionUpdates);
    setPermissionUpdates({});
    await getUserPermissions();
  };

  const resetPermissions = async () => {
    setPermissionsLoading(true);
    await firebase.clearUserOverwritePermissions(profileId);
    await getUserPermissions();
  };

  const getUserPermissions = async () => {
    setPermissionsLoading(true);
    const res = await firebase.getUserPermissions(profileId);
    const json = await res.json();
    const permissions = json.data?.features || {};

    // Categorize the permissions
    setCategorizedPermissions(
      Object.entries(permissions).reduce((categorized, [key, value]) => {
        const category = getPermissionCategory(key);
        if (!categorized[category]) {
          categorized[category] = {};
        }
        categorized[category][key] = value;
        return categorized;
      }, {})
    );

    setPermissionsLoading(false);
  };

  const categoryOrder = (category) =>
    CATEGORY_ORDERS[category] || Object.keys(CATEGORY_ORDERS).length + 1;

  useEffect(() => {
    if (Object.keys(profile).length) void getUserPermissions();
  }, [profile]);

  const handleOpenAssignManagerModal = () => {
    if (!isAdmin) return;
    setAssignManagerModalVisible(true);
  };

  const handleCloseAssignManagerModal = () => setAssignManagerModalVisible(false);

  if (loader) return spinner;

  const assignManagerModalEl = isAdmin && assignManagerModalVisible && (
    <AssignManagerModal
      onClose={handleCloseAssignManagerModal}
      visible={assignManagerModalVisible}
      subordinateId={profileId}
      onSuccess={fetchManagers}
    />
  );

  const {datetime: lastAccessDatetimeString, timezone: lastAccessTimezoneString} =
    getLastAccessStrings(profile.lastAccess);

  const infoRowEl = (
    <CRow>
      <CCol lg={4} md={6} xs={12}>
        <CCard>
          <CCardBody>
            <CContainer className="d-flex align-items-center flex-column overflow-hidden">
              <CContainer className="mb-2 d-flex align-items-center flex-column">
                <OptionalAvatar style={PROFILE_STYLES.avatar} src={profile.avatar} />
              </CContainer>
              <h3 className={strJoin("text-primary-custom", classes.textEllipsis)}>
                {profile.fullName &&
                  profile.fullName.charAt(0).toUpperCase() + profile.fullName.slice(1)}
              </h3>
              <p className={strJoin(classes.textSecondary, classes.textEllipsis)}>
                {profile.email}
              </p>
              <p className="text-secondary-custom vstack gap-1 justify-content-center text-center">
                <span className="fw-bold">Last Access</span>
                <div>
                  <div>{lastAccessDatetimeString}</div>
                  <div>{lastAccessTimezoneString}</div>
                </div>
              </p>
            </CContainer>
          </CCardBody>
          <hr className="p-0 m-0" />
          <CCardBody className="text-center">
            <input
              id="btn-file"
              type="file"
              className="d-none"
              accept=".jpg, .jpeg, .png"
              onChange={uploadImage}
            />
            <label htmlFor="btn-file" style={{margin: "0 auto"}}>
              <CButton color="primary" variant="ghost" component="span" className="text-uppercase">
                Upload picture
              </CButton>
            </label>
          </CCardBody>
        </CCard>

        {isAdmin && (
          <>
            <CAccordion style={PROFILE_STYLES.accordion} className="my-3">
              <CAccordionItem>
                <CAccordionHeader>Update Password</CAccordionHeader>
                <CAccordionBody>
                  <CForm>
                    <CFormInput
                      type="password"
                      id="password"
                      name="password"
                      label="Password"
                      value={updatePasswordForm.password ? updatePasswordForm.password : ""}
                      invalid={!!updatePasswordFieldErrors?.password}
                      feedbackInvalid={updatePasswordFieldErrors.password}
                      onChange={handleUpdatePassword}
                    />
                    <div className="my-1" />
                    <CFormInput
                      type="password"
                      id="passwordConfirm"
                      name="passwordConfirm"
                      label="Confirm Password"
                      value={updatePasswordForm.passwordConfirm || ""}
                      invalid={updatePasswordFieldErrors?.passwordConfirm && true}
                      feedbackInvalid={updatePasswordFieldErrors.passwordConfirm}
                      onChange={handleUpdatePassword}
                    />
                    <div className="my-3" />
                    <div className="d-flex justify-content-end">
                      <CButton
                        disabled={!updatePasswordValid}
                        onClick={() => setUpdatePasswordModalVisible(true)}
                        color="primary"
                      >
                        Update
                      </CButton>
                    </div>
                  </CForm>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
            <CModal
              visible={updatePasswordModalVisible}
              onClose={() => setUpdatePasswordModalVisible(false)}
              alignment="center"
            >
              <CModalHeader>
                <CModalTitle>Are you sure?</CModalTitle>
              </CModalHeader>
              <CModalBody>The user's password will be changed.</CModalBody>
              <CModalFooter>
                <CButton color="secondary" onClick={() => setUpdatePasswordModalVisible(false)}>
                  Cancel
                </CButton>
                <CButton color="primary" onClick={updatePassword}>
                  Update Password
                </CButton>
              </CModalFooter>
            </CModal>
          </>
        )}
      </CCol>
      <CCol lg={8} md={6} xs={12}>
        <CCard>
          <CCardBody>
            <CCardTitle className="h6 text-break">{`Profile (${profile.id}@m.photoidapp.net)`}</CCardTitle>
            <CCardSubtitle style={PROFILE_STYLES.textSecondary}>
              The information can be edited
            </CCardSubtitle>
          </CCardBody>
          <hr className="p-0 m-0" />
          <CCardBody>
            <CRow>
              <CCol lg={6} md={12}>
                <CFormInput
                  label="Name"
                  name="fullName"
                  value={profile.fullName || ""}
                  onChange={handleProfileFieldChange}
                />
              </CCol>
              <CCol lg={6} md={12}>
                <CFormSelect
                  label="Industry Type"
                  name="industryType"
                  value={profile.industryType || ""}
                  onChange={handleProfileFieldChange}
                  disabled={fetchingIndustryTypeOptions}
                  placeholder={fetchingIndustryTypeOptions ? "Loading..." : ""}
                >
                  {mappedIndustryTypeOptions &&
                    mappedIndustryTypeOptions.map(({value, label, invalid}) => (
                      <option
                        key={value}
                        className={conditionalClass(invalid, classes.invalidOption)}
                        value={value}
                      >
                        {label}
                      </option>
                    ))}
                </CFormSelect>
              </CCol>
            </CRow>
          </CCardBody>
          <hr className="p-0 m-0" />
          <CCardBody>
            <CRow>
              <CCol xs={12}>
                <strong>Profile Info:</strong>
              </CCol>
              <CCol lg={6} md={12}>
                <DataInfo
                  name="Register Date"
                  data={moment.unix(profile.signUpDate / 1000).format("MMM DD, YYYY")}
                />
              </CCol>
              <CCol lg={6} md={12}>
                <DataInfo
                  name="Amount of Assignments"
                  data={profile.assignsList ? Object.keys(profile.assignsList).length : "..."}
                />
              </CCol>
              <CCol lg={6} md={12}>
                <DataInfo name="Phone Number" data={profile.phone} />
              </CCol>
            </CRow>
          </CCardBody>
          <hr className="p-0 m-0" />
          <div className="d-flex flex-wrap justify-content-end p-2 gap-2">
            <CButton
              color="primary"
              onClick={updateProfile}
              disabled={uploadLoader}
              style={{minWidth: 110.733}}
            >
              {uploadLoader ? (
                <CSpinner style={{color: "#fff"}} size="sm" />
              ) : (
                <span>Save Details</span>
              )}
            </CButton>
          </div>
        </CCard>
      </CCol>
    </CRow>
  );

  const assignmentsPaginationBar = showOnlyFoundAssignments ? (
    <TablePaginationBar
      rowsPerPageOptions={assignmentsPerPageOptions}
      count={foundAssignments.length}
      rowsPerPage={foundAssignmentsPerPage}
      page={foundAssignmentsPage}
      setPage={setFoundAssignmentsPage}
      setRowsPerPage={setFoundAssignmentsPerPage}
      rowsPerPageLabel="Assignments per page:"
    />
  ) : (
    profile.assignsList && (
      <TablePaginationBar
        rowsPerPageOptions={assignmentsPerPageOptions}
        count={Object.keys(profile.assignsList).length}
        rowsPerPage={assignmentsPerPage}
        page={assignmentsPage}
        setPage={setAssignmentsPage}
        setRowsPerPage={setAssignmentsPerPage}
        rowsPerPageLabel="Assignments per page:"
      />
    )
  );

  const managersAccordionItem = isAdmin ? (
    <CAccordionItem itemKey="managers">
      <CAccordionHeader>
        <CRow>
          <CCol xs={12}>
            <CCardTitle>Managers</CCardTitle>
          </CCol>
          <CCol xs={12}>
            <CCardSubtitle>Total: {managersLoading ? "..." : managers.length}</CCardSubtitle>
          </CCol>
        </CRow>
      </CAccordionHeader>
      <CAccordionBody>
        <CCardBody className="d-flex flex-column gap-2">
          {managersLoading ? (
            <CPlaceholder component={CCardTitle} animation="glow">
              <CPlaceholder xs={12} />
            </CPlaceholder>
          ) : (
            <ButtonTable
              linkLabel="Remove Manager"
              data={managers}
              dataCells={MANAGERS_DATA_CELLS}
              headCells={MANAGERS_HEAD_CELLS}
              buttonIcon={cilDelete}
              rowOnClick={unassignManager}
            />
          )}
          <div className="hstack justify-content-end">
            <CButton onClick={handleOpenAssignManagerModal}>Assign Manager</CButton>
          </div>
        </CCardBody>
      </CAccordionBody>
    </CAccordionItem>
  ) : null;

  const devicesAccordionItem = (
    <CAccordionItem itemKey="devices">
      <CAccordionHeader>
        <CCardTitle>Devices</CCardTitle>
      </CAccordionHeader>
      <CAccordionBody>
        <SimpleBar style={{maxHeight: "369px"}}>
          <CCardBody>
            <CTable
              small
              responsive={false}
              className="text-start"
              columns={DEVICE_TABLE_COLS}
              items={devices.map((device) => {
                if ("timestamp" in device) {
                  device["date"] = moment.unix(device.timestamp / 1000).format("MM/DD/YYYY h:mm a");
                }
                deviceTableFields.forEach((field) => {
                  if (!(field in device)) {
                    device[field] = " ";
                  }
                });
                return device;
              })}
            />
          </CCardBody>
        </SimpleBar>
      </CAccordionBody>
    </CAccordionItem>
  );

  const assignmentsSearchBar = (
    <CForm onSubmit={handleSearchAssignmentsSubmit}>
      <CInputGroup>
        <CFormInput
          type="search"
          placeholder="Search by name"
          value={assignmentQuery}
          onChange={handleChangeAssignmentQuery}
        />
        <CButton
          type="reset"
          color="secondary"
          onClick={clearSearch}
          disabled={!showOnlyFoundAssignments}
        >
          Clear Search
        </CButton>
        <CButton type="submit" color="primary">
          <CIcon icon={cilSearch} className="mx-2" />
        </CButton>
      </CInputGroup>
    </CForm>
  );

  const assignmentsEl = (() => {
    if (assignmentsLoading) return spinner;

    // If loading the query results
    if (showOnlyFoundAssignments && searchingAssignments) return spinner;

    const assignmentsToShow = (() => {
      if (showOnlyFoundAssignments) {
        if (noFoundAssignments) return null;

        return foundAssignments.slice(
          foundAssignmentsPage * foundAssignmentsPerPage,
          foundAssignmentsPage * foundAssignmentsPerPage + foundAssignmentsPerPage
        );
      }

      return assignments;
    })();

    if (!assignmentsToShow) return <div>No assignments found.</div>;

    return assignmentsToShow.filter(Boolean).map((assignment) => {
      const {assignmentId, claimInsuredName, contactDate, creationDate, image, cloudOnly, owner} =
        assignment;

      const isOwner = owner === profileId;

      const address = getFullAssignmentAddress(assignment);

      const toggleVisibility = () => updateAssignmentVisibility(assignmentId, !cloudOnly);

      return (
        <ProfileAssignmentCard
          assignmentId={assignmentId}
          assignmentName={claimInsuredName}
          contactDate={contactDate}
          creationDate={creationDate}
          cloudOnly={cloudOnly}
          address={address}
          image={image}
          isOwner={isOwner}
          key={assignmentId}
          toggleVisibility={toggleVisibility}
        />
      );
    });
  })();

  const assignmentsContentEl = (
    <CContainer className="d-flex flex-column gap-4">
      {assignmentsSearchBar}
      {assignmentsEl}
    </CContainer>
  );

  const assignmentsAccordionItem = (
    <CAccordionItem itemKey="assignments">
      <CAccordionHeader>
        <CRow>
          <CCol xs={12}>
            <CCardTitle>Assignments</CCardTitle>
          </CCol>
          <CCol xs={12}>
            <CCardSubtitle>
              {`Total: ${
                profile.assignsList ? Object.keys(profile.assignsList).length : "..."
              } / Sorted: Most Recent First`}
            </CCardSubtitle>
          </CCol>
        </CRow>
      </CAccordionHeader>
      <CAccordionBody>
        {assignmentsContentEl}
        <div className="p-2 sticky-bottom bg-white">{assignmentsPaginationBar}</div>
      </CAccordionBody>
    </CAccordionItem>
  );

  const subscriptionAccordionItem = isAdmin ? (
    <CAccordionItem itemKey="subscription">
      <CAccordionHeader>
        <CRow>
          <CCol xs={12}>
            <CCardTitle>Subscription</CCardTitle>
          </CCol>
        </CRow>
      </CAccordionHeader>
      <CAccordionBody>
        <CCardBody>
          <CRow>
            <CCol xs={12} className="mb-4">
              <div className="title">Subscription Details</div>
              {isAdmin ? (
                <div className="text-secondary-custom">
                  Be sure to fill in all fields if you want to add/edit subscription.
                </div>
              ) : null}
            </CCol>
            {isAdmin ? (
              <>
                <CCol lg={6} md={12}>
                  <Select
                    options={SUBSCRIPTION_OPTIONS}
                    label="Product"
                    name="productId"
                    value={subscription.productId || "No Subscription"}
                    handleChange={handleSubscription}
                  />
                </CCol>
                <CCol lg={6} md={12}>
                  <Select
                    options={getBoughtOnOptions()}
                    label="Bought on"
                    name="boughtOn"
                    value={subscription.boughtOn || ""}
                    handleChange={handleSubscription}
                  />
                </CCol>
                <CCol lg={6} md={12}>
                  <Select
                    options={IS_ACTIVE_OPTIONS}
                    label="Is Active"
                    name="isActive"
                    value={subscription.isActive?.toString() || "false"}
                    handleChange={handleSubscription}
                  />
                </CCol>
                <CCol lg={6} md={12}>
                  <Flatpickr
                    options={{dateFormat: "m/d/Y"}}
                    value={subscription.expirationDate || null}
                    onChange={(date) =>
                      setSubscription({
                        ...subscription,
                        expirationDate: new Date(date).getTime(),
                      })
                    }
                    render={({value, onChange}, ref) => (
                      <CFormInput label="Expiration Date" name="expirationDate" ref={ref} />
                    )}
                  />
                </CCol>
              </>
            ) : null}
            <CCol xs={12}>
              <CCollapse visible={!Object.keys(subscription).length}>
                <CRow>
                  <CCol xs={12}>
                    <p style={PROFILE_STYLES.textSecondary}>>No subscription</p>
                  </CCol>
                </CRow>
              </CCollapse>
            </CCol>
          </CRow>
        </CCardBody>
        <div className="d-flex flex-wrap justify-content-end p-2 gap-2">
          <CButton color="primary" onClick={clearSubscription} disabled={subscriptionUnmodified}>
            Restore Subscription
          </CButton>
          <CButton
            color="primary"
            onClick={updateSubscription}
            disabled={!subscriptionUnmodified && !isSubscriptionFilled()}
          >
            {updatingSubscription ? (
              <CSpinner style={{color: "#fff"}} size="sm" />
            ) : (
              <span>Save Details</span>
            )}
          </CButton>
        </div>
        <hr className="p-0 m-0 mt-4" />
        {permissionsLoading ? (
          <div
            className="d-flex justify-content-center align-items-center h-100"
            style={{minHeight: "700px"}}
          >
            <CSpinner color="primary" />
          </div>
        ) : (
          <>
            <CContainer>
              <CRow className="mt-2 mb-3">
                <CCol xs={12} className="m-0 p-0">
                  <div className="title">Permissions</div>
                  <div className="d-flex align-items-center">
                    <div className="d-inline-flex me-3">
                      <div className="text-secondary-custom">
                        <div>
                          The following features are the ones that the user has access to, based on
                          the subscribed plan.
                        </div>
                        <div>Any permission changes made will override the plan permissions.</div>
                      </div>
                    </div>
                    <div className="flex-grow-1" />
                    <div className="d-inline-flex" style={{whiteSpace: "nowrap"}}>
                      <CButton onClick={resetPermissions} color="primary" variant="outline">
                        Reset Permissions
                      </CButton>
                    </div>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                {Object.entries(categorizedPermissions)
                  .sort((arr1, arr2) => categoryOrder(arr1[0]) - categoryOrder(arr2[0]))
                  .map(([category, permissions], index) => {
                    const options = Object.entries(permissions).map(([key, value]) => {
                      const info = PERMISSIONS_INFO[key];
                      const name = info?.name || key;

                      const switchIsChecked = [-1, true].includes(value);
                      const numericalValue = typeof value === "number" ? value : null;
                      const numericalInputVisible = info?.numeric && !switchIsChecked;
                      const numericalInputCols = 3;

                      return (
                        <CCol sm={12} md={6} className="my-2 align-items-center" key={key}>
                          <CRow className="align-items-center">
                            <CCol xs={numericalInputVisible ? 12 - numericalInputCols : 12}>
                              <CFormSwitch
                                label={name}
                                style={PROFILE_STYLES.switch}
                                checked={switchIsChecked}
                                onChange={(e) => handlePermissionChange(key, e.target.checked)}
                              />
                            </CCol>
                            <CCol
                              xs={numericalInputCols}
                              className={numericalInputVisible ? "" : "d-none"}
                            >
                              <CFormInput
                                type="number"
                                min={0}
                                disabled={switchIsChecked}
                                id={`input_${key}`}
                                size="sm"
                                value={numericalValue || numericalValue === 0 ? numericalValue : ""}
                                onChange={(e) => handlePermissionChange(key, e.target.value)}
                                ref={(el) => {
                                  if (el) numericalInputRefs.current.set(key, el);
                                }}
                              />
                            </CCol>
                          </CRow>
                        </CCol>
                      );
                    });

                    return (
                      <CCol xs={12} key={index}>
                        <CRow className="category-bar mb-2 mt-4 py-1 ps-2">{category}</CRow>
                        <CRow className="">{options}</CRow>
                      </CCol>
                    );
                  })}
              </CRow>
            </CContainer>
            <CContainer>
              <div className="d-flex flex-wrap justify-content-end mt-2">
                <CButton
                  onClick={savePermissionUpdates}
                  disabled={!Object.keys(permissionUpdates).length}
                >
                  Save Details
                </CButton>
              </div>
            </CContainer>
          </>
        )}
      </CAccordionBody>
    </CAccordionItem>
  ) : null;

  const accordionEl = (
    <CAccordion alwaysOpen activeItemKey="assignments" style={PROFILE_STYLES.accordion}>
      {subscriptionAccordionItem}
      {managersAccordionItem}
      {devicesAccordionItem}
      {assignmentsAccordionItem}
    </CAccordion>
  );

  const accordionRowEl = (
    <CRow>
      <CCol xs={12}>{accordionEl}</CCol>
    </CRow>
  );

  const content = (
    <CContainer className="d-grid gap-3">
      {infoRowEl}
      {accordionRowEl}
    </CContainer>
  );

  return (
    <Page title="Profile" onMobileNavOpen={onMobileNavOpen}>
      {content}
      {assignManagerModalEl}
    </Page>
  );
};

export default Profile;
