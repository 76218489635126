import {CTable, CTableBody, CTableHead, CTableHeaderCell, CTableRow} from "@coreui/react";
import PropTypes from "prop-types";
import React from "react";
import {conditionalClass} from "../../../utils/css-utils";
import classes from "../ProfilesView.module.css";
import SortableHeaderCell from "../SortableHeaderCell/SortableHeaderCell";
import ProfilesTableRow from "./ProfilesTableRow/ProfilesTableRow";

const ProfilesViewList = ({
  actionsButton,
  deselectProfile,
  headerCheckbox,
  headerCheckboxChecked,
  order,
  orderBy,
  paginatedProfiles,
  selectProfile,
  selectedProfiles,
  setOrder,
  setOrderBy,
}) => {
  const numSelectedProfiles = selectedProfiles.size;

  const sortableHeaderCellCommonProps = {setOrderBy, order, orderBy, setOrder};

  const commonCellObjs = [
    {name: "Subscription", sortValue: "subscriptionName"},
    {
      name: "Expires on",
      sortValue: "expirationDate",
    },
    {name: "Last Access", sortValue: "lastAccess"},
    {
      name: "Nr. of Assignments",
      sortValue: "numAssignments",
    },
    {name: "Industry Type", sortValue: "industryType"},
    {name: "UUID", sortValue: "id"},
  ];

  // Cells that are common to the checked and unchecked states
  const commonCells = commonCellObjs.map(({name, sortValue}) => {
    return (
      <SortableHeaderCell
        key={sortValue}
        sortValue={sortValue}
        textIsInvisible={headerCheckboxChecked}
        {...sortableHeaderCellCommonProps}
      >
        {name}
      </SortableHeaderCell>
    );
  });

  const secondHeaderCell = headerCheckboxChecked ? (
    <CTableHeaderCell>{numSelectedProfiles} selected</CTableHeaderCell>
  ) : (
    <SortableHeaderCell sortValue="fullName" {...sortableHeaderCellCommonProps}>
      Name
    </SortableHeaderCell>
  );

  const headerCells = (
    <>
      <CTableHeaderCell className="px-3">{headerCheckbox}</CTableHeaderCell>
      {secondHeaderCell}
      {commonCells}
      <CTableHeaderCell>{actionsButton}</CTableHeaderCell>
    </>
  );

  const headClasses = [
    classes.header,
    conditionalClass(headerCheckboxChecked, classes.headerActive),
  ].join(" ");

  const head = (
    <CTableHead>
      <CTableRow align="middle" className={headClasses}>
        {headerCells}
      </CTableRow>
    </CTableHead>
  );

  const body = (
    <CTableBody>
      {paginatedProfiles.map((profile) => {
        const selected = selectedProfiles.has(profile);

        const select = () => selectProfile(profile);

        const deselect = () => deselectProfile(profile);

        return (
          <ProfilesTableRow
            avatar={profile.avatar}
            deselect={deselect}
            email={profile.email}
            expirationDate={profile.subscription?.expirationDate}
            expirationDateFormatted={profile.expirationDateFormatted}
            id={profile.id}
            industry={profile.industryType}
            key={profile.id}
            lastAccess={profile.lastAccess}
            lastAccessFormatted={profile.lastAccessFormatted}
            name={profile.fullName}
            numAssignments={profile.numAssignments}
            phoneNumber={profile.phone}
            select={select}
            selected={selected}
            subscriptionActive={profile.subscriptionActive}
            subscriptionExpired={profile.subscriptionExpired}
            subscriptionName={profile.subscriptionName}
          />
        );
      })}
    </CTableBody>
  );

  return (
    <CTable className={classes.table}>
      {head}
      {body}
    </CTable>
  );
};

ProfilesViewList.propTypes = {
  actionsButton: PropTypes.element.isRequired,
  deselectProfile: PropTypes.func.isRequired,
  headerCheckbox: PropTypes.element.isRequired,
  headerCheckboxChecked: PropTypes.bool.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  paginatedProfiles: PropTypes.array.isRequired,
  selectProfile: PropTypes.func.isRequired,
  selectedProfiles: PropTypes.object.isRequired,
  setOrder: PropTypes.func.isRequired,
  setOrderBy: PropTypes.func.isRequired,
};

export default ProfilesViewList;
