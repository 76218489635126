import {
  CButton,
  CForm,
  CFormInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CSpinner,
} from "@coreui/react";
import {yupResolver} from "@hookform/resolvers";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import * as Yup from "yup";
import {useToasterContext} from "../../context/ToasterContext/ToasterContext";
import {useUserContext} from "../../hooks/useUserContext";
import {ENV} from "../../env";

const schema = Yup.object().shape({
  email: Yup.string().email("Please enter a valid email address.").required(),
});

const AddUser = ({handleClose, open}) => {
  const {
    user: {id},
  } = useUserContext();

  const {addToast} = useToasterContext();

  const [loader, setLoader] = useState(false);

  const {register, handleSubmit, errors, watch} = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const sendEmail = () => {
    setLoader(true);
    const emailOptions = {
      managerId: id,
      email: watch("email"),
    };
    fetch(`${ENV.API_URL}/sendNewUserManagementEmail`, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(emailOptions),
    })
      .then((res) => res.json())
      .then(({success}) => {
        if (success) {
          addToast({message: "The email was sent successfully!", color: "success"});
          handleClose();
        } else
          addToast({message: "Something went wrong. Please try again later.", color: "danger"});

        setLoader(false);
      });
  };

  return (
    <CModal visible={open} onClose={handleClose} alignment="center">
      <CModalHeader>
        <CModalTitle>Add User</CModalTitle>
      </CModalHeader>
      <CModalBody>Please fill in the email address of the user you want to add.</CModalBody>
      <CModalBody>
        <CForm>
          <CFormInput
            type="email"
            name="email"
            invalid={!!errors.email}
            text={errors.email && errors.email.message}
            label="Email Address"
            ref={register}
          />
        </CForm>
      </CModalBody>
      <CModalFooter>
        <CButton onClick={handleClose} color="primary">
          Cancel
        </CButton>
        <CButton onClick={handleSubmit(sendEmail)} color="primary" disabled={loader}>
          {!loader ? <span>Subscribe</span> : <CSpinner size="sm" />}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default AddUser;
