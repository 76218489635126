export const getAuthToken = () => localStorage.getItem("token");

const decodeAuthToken = (token) => new Buffer(token, "base64").toString("ascii");

export const getAuthTokenDecoded = () => {
  const token = getAuthToken();
  return token ? decodeAuthToken(token) : null;
};

const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;

export const isValidPassword = (password) => PASSWORD_REGEX.test(password);
