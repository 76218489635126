import moment from "moment";

const DATE_FORMAT = "MMM DD, YYYY";
export const msToDate = (date, dateFormat = DATE_FORMAT) =>
  date ? moment.unix(date / 1e3).format(dateFormat) : "";

export const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * @param lastAccess {number | string}
 * @returns {{datetime: string, timezone: string}}
 */
export const getLastAccessStrings = (lastAccess) => {
  if (!lastAccess) return {datetime: "", timezone: ""};

  const isInMs = lastAccess.toString().length > 10;
  const lastAccessUnix = lastAccess / (isInMs ? 1e3 : 1);

  const lastAccessMoment = moment.unix(lastAccessUnix);

  const timezoneOffsetString = lastAccessMoment.format("Z");

  const datetime = lastAccessMoment.format("MMM DD, YYYY hh:mm A");
  const timezone = `${TIME_ZONE}: UTC${timezoneOffsetString}`;

  return {datetime, timezone};
};
