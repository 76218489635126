export const strToIntIfNeeded = (strOrInt) => {
  const error = new Error("Integer input received a non-integer value.");

  if (typeof strOrInt === "number") {
    if (Number.isInteger(strOrInt)) return strOrInt;
    throw error;
  }

  const int = parseInt(strOrInt);
  if (isNaN(int)) throw error;
  return int;
};