import {CTableDataCell, CTableRow} from "@coreui/react";
import React from "react";
import classes from "../../../ProfilesView/ProfilesViewList/ProfilesTableRow/ProfilesTableRow.module.css";
import ProfilesTableRowUser from "../../../ProfilesView/ProfilesViewList/ProfilesTableRow/ProfilesTableRowUser/ProfilesTableRowUser";

const defaultFields = new Set([
  "profileInfo",
  "subscriptionName",
  "expirationDateFormatted",
  "lastAccessFormatted",
  "numAssignments",
  "industry",
]);

const BulkEditReviewProfilesTableRow = ({
  avatar,
  email,
  phoneNumber,
  subscriptionName,
  expirationDateFormatted,
  lastAccessFormatted,
  numAssignments,
  industry,
  id,
  fullName,
  fields = defaultFields,
}) => {
  return (
    <CTableRow align="middle" className={classes.row}>
      {fields.has("profileInfo") && (
        <CTableDataCell>
          <ProfilesTableRowUser
            avatar={avatar}
            name={fullName}
            email={email}
            phoneNumber={phoneNumber}
          />
        </CTableDataCell>
      )}
      {fields.has("subscriptionName") && <CTableDataCell>{subscriptionName}</CTableDataCell>}
      {fields.has("expirationDateFormatted") && (
        <CTableDataCell>{expirationDateFormatted}</CTableDataCell>
      )}
      {fields.has("lastAccessFormatted") && <CTableDataCell>{lastAccessFormatted}</CTableDataCell>}
      {fields.has("numAssignments") && <CTableDataCell>{numAssignments}</CTableDataCell>}
      {fields.has("industry") && <CTableDataCell>{industry}</CTableDataCell>}
      {fields.has("id") && <CTableDataCell>{id}</CTableDataCell>}
    </CTableRow>
  );
};

export default BulkEditReviewProfilesTableRow;
