import React, {useState} from "react";
import PropTypes from "prop-types";
import SearchToolbar from "./Search";
import CustomTableCell from "./TableCell";

import {CButton, CContainer, CTable, CTableBody, CTableDataCell, CTableRow} from "@coreui/react";
import TablePaginationBar from "./TablePaginationBar";
import CIcon from "@coreui/icons-react";
import LinkTableHead from "./LinkTableHead";
import {getComparator, stableFilterAndSort} from "./TableFunctions";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

function ButtonTable(props) {
  const {data, headCells, dataCells, rowOnClick, linkLabel, buttonIcon} = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("signUpDate");
  const [orderByKeysLength, setOrderByKeysLength] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = useState("");
  const dataFiltered = stableFilterAndSort(
    data,
    getComparator(order, orderBy, orderByKeysLength),
    search,
    headCells
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const prop = dataCells.find((prop) => prop.dataKey === property);
    if (prop) setOrderByKeysLength(prop.type === "amount");
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div>
      <div className="border rounded table-custom">
        <SearchToolbar search={search} setSearch={setSearch} headCells={headCells} />
        <SimpleBar>
          <CTable align="middle">
            <LinkTableHead
              linkLabel={linkLabel}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              headCells={headCells}
            />
            <CTableBody>
              {dataFiltered
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, idx) => (
                  <CTableRow key={idx} className="hover-selectable px-5" tabIndex={-1}>
                    <CustomTableCell row={row} dataCells={dataCells} />
                    <CTableDataCell className="py-4">
                      <CContainer className="d-flex justify-content-center">
                        <CButton color="primary" variant="ghost" onClick={() => rowOnClick(row)}>
                          <CIcon icon={buttonIcon} />
                        </CButton>
                      </CContainer>
                    </CTableDataCell>
                  </CTableRow>
                ))}
            </CTableBody>
          </CTable>
        </SimpleBar>
        <TablePaginationBar
          className="mx-2 mb-2"
          rowsPerPageOptions={[10, 25, 50, 100, 250]}
          rowsPerPage={rowsPerPage}
          count={dataFiltered.length}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </div>
    </div>
  );
}

export default ButtonTable;

ButtonTable.propTypes = {
  data: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  dataCells: PropTypes.array.isRequired,
  linkLabel: PropTypes.string,
  rowOnClick: PropTypes.func,
  buttonIcon: PropTypes.any,
};

ButtonTable.defaultProps = {
  rowOnClick: () => {},
};
