import {createSlice} from "@reduxjs/toolkit";

export const profilesSlice = createSlice({
  name: "profiles",
  initialState: {
    profiles: null,
    fetchingProfiles: false,
  },
  reducers: {
    setProfiles: (state, {payload: {profiles}}) => {
      state.profiles = profiles;
      state.fetchingProfiles = false;
    },
    startFetchingProfiles: (state) => {
      state.fetchingProfiles = true;
    },
  },
});

export const {setProfiles, startFetchingProfiles} = profilesSlice.actions;

export default profilesSlice.reducer;
