import React from "react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers";
import {useForm} from "react-hook-form";

const useBulkManagerAssignmentForm = () => {
  const defaultValues = {managerEmail: ""};

  const schema = yup
    .object({
      managerEmail: yup.string().email("Invalid email.").required("Manager email is required."),
    })
    .required();

  const resolver = yupResolver(schema);

  return useForm({defaultValues, resolver});
};

export default useBulkManagerAssignmentForm;
